import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Options from '../UI/Options'
import { useNavigate, useMatch } from 'react-router-dom'
import AuthService from '../../services/AuthService'
import { v4 as uuidv4 } from 'uuid'

const ListOrganisationFootprints = () => {

    const match = useMatch('/viewOrganisation/:organisationId')

    const internationalNumberFormat = new Intl.NumberFormat('en-US')

    const [loading, setLoading] = useState(false)

    const [refreshTrigger, setRefreshTrigger] = useState(false)

    const [organisationFootprints, setOrganisationFootprints] = useState([])

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setLoading(true)

            if (role === 6002) {

                axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_by_special_user')
                .then((res) => {
                    setOrganisationFootprints(res.data.footprints.sort((item1, item2) => item1.organisationFootprintYear < item2.organisationFootprintYear ? -1 : item1.organisationFootprintYear > item2.organisationFootprintYear ? 1 : 0))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

                setRefreshTrigger(false)


            } else {

                axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_by_organisationId/' + match.params.organisationId)
                    .then((res) => {
                        role === 3001 && setOrganisationFootprints(res.data.filter(item => item.organisationFootprintType === "Actual" || item.organisationFootprintType === "Actual (Baseline)").sort((item1, item2) => item1.organisationFootprintYear < item2.organisationFootprintYear ? -1 : item1.organisationFootprintYear > item2.organisationFootprintYear ? 1 : 0))
                        role !== 3001 && setOrganisationFootprints(res.data.sort((item1, item2) => item1.organisationFootprintYear < item2.organisationFootprintYear ? -1 : item1.organisationFootprintYear > item2.organisationFootprintYear ? 1 : 0))

                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

                    setRefreshTrigger(false)

            }

        }

    }, [refreshTrigger, company, role, userPropertiesLoading])

    const navigate = useNavigate()

    const deleteOrganisationFootprint = (id) => {

        if (window.confirm('Are you sure you want to delete it?')) {

            if (organisationFootprints.find(item => item.id === id).organisationFootprintItems.length > 0) {

                window.alert("This footprint contains one or more footprint item(s).  Please remove footprint items before deleting!")

            } else {

                axios.delete(process.env.REACT_APP_API_URL + '/organisations_footprints/' + id)
                setOrganisationFootprints(organisationFootprints.filter((organisationFootprint) => organisationFootprint.id !== id))

            }

        }

    }

    const cloneOrganisationFootprint = (id) => {

        if (window.confirm('Are you sure you want to clone this footprint?')) {

            const footprintToClone = organisationFootprints.find(item => item.id === id)
            const newFootprint = { ...footprintToClone, organisationFootprintYear: (Number(footprintToClone.organisationFootprintYear)+1).toString(), organisationFootprintRef: "Clone", organisationFootprintType: "Actual", organisationFootprintCertification: "", organisationFootprintDate: "", organisationFootprintCommentThread: [] }

            //Loop through all footprintItems and re-generate ids

            var item
            for (item = 0; item < newFootprint.organisationFootprintItems.length; item++) {

                newFootprint.organisationFootprintItems[item].id = uuidv4()
                newFootprint.organisationFootprintItems[item].verified = ""
                newFootprint.organisationFootprintItems[item].submitted = ""
                newFootprint.organisationFootprintItems[item].filesUploaded = 0
                newFootprint.organisationFootprintItems[item].submissionComment = ""
                newFootprint.organisationFootprintItems[item].verificationComment = ""
                newFootprint.organisationFootprintItems[item].carbonEmissions = 0
                newFootprint.organisationFootprintItems[item].consumption = 0
                newFootprint.organisationFootprintItems[item].numberOfMonths = 12
                newFootprint.organisationFootprintItems[item].emissionFactor = 0
                newFootprint.organisationFootprintItems[item].additionalEstimatedEmissions = 0
                newFootprint.organisationFootprintItems[item].commentThread = []
                newFootprint.organisationFootprintItems[item].dataReviewProgress = ""
                newFootprint.organisationFootprintItems[item].calculationProgress = ""
                newFootprint.organisationFootprintItems[item].dataReviewProgress = ""
                newFootprint.organisationFootprintItems[item].QAProgress = ""
                newFootprint.organisationFootprintItems[item].itemCommentForReport = ""
                newFootprint.organisationFootprintItems[item].itemConsultantComment = ""


                // newFootprint.organisationFootprintItems[item].footprintSubItems = []
            }

            addForm(newFootprint)

            setRefreshTrigger(true)

        }

    }

    const editOrganisationFootprint = (id) => {
        navigate(`/addOrUpdateOrganisationFootprint/${match.params.organisationId}/${id}`);
    }

    const viewOrganisationFootprint = (id) => {
        navigate(`/viewOrganisationFootprint/${match.params.organisationId}/${id}`);
    }

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/organisations_footprints', form)
    }

    if (organisationFootprints.length > 0 && !loading) {

        return (
            <div className="bg-slate-100">
                <h1 className='pt-4 px-3 pb-2 mx-auto header-8A'>Organisation Footprints</h1>
                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto">
                        <div className="py-2 text-eight-text align-middle inline-block min-w-full px-3">
                            <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl">
                                <table className="objects-8A min-w-full divide-y divide-gray-200">
 
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            {/* <th> Organisation Id</th> */}
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Organisation Footprint Ref</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Organisation Footprint Year</th>
                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Organisation Footprint Date</th> */}
                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Organisation Footprint Type</th> */}
                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Organisation Footprint Location</th> */}
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Employees</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO2e</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO2e per Employee</th>
                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Uncertainty Rating</th> */}
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Certification</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {organisationFootprints.filter((organisationFootprint) => organisationFootprint.organisationId === match.params.organisationId).filter(item => item.organisationFootprintType === "Actual" || item.organisationFootprintType === "Actual (Baseline)").map((organisationFootprint) => (
                                            <tr key={organisationFootprint.id}>
                                                {/* <td> {organisationFootprint.organisationId} </td> */}
                                                <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprint.organisationFootprintRef} </td>
                                                <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprint.organisationFootprintYear}</td>
                                                {/* <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprint.organisationFootprintDate}</td> */}
                                                {/* <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprint.organisationFootprintType}</td> */}
                                                {/* <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprint.organisationFootprintLocation}</td> */}
                                                <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {organisationFootprint.organisationNumberOfEmployees}</td>
                                                <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {`${internationalNumberFormat.format((((organisationFootprint.organisationFootprintItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)))/1000).toFixed(0))}`}</td>
                                                <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {`${internationalNumberFormat.format((((organisationFootprint.organisationFootprintItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / organisationFootprint.organisationNumberOfEmployees)/1000).toFixed(0))}`}</td>
                                                {/* <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer"> <svg className="inline-block fill-current text-lime" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z"/></svg><div className="inline-block pr-1">Very Good</div></td> */}
                                                <td onClick={() => viewOrganisationFootprint(organisationFootprint.id)} className="px-4 md:px-6 py-2 hover:cursor-pointer text-xs ">
                                                    {organisationFootprint.organisationFootprintCertification === "Carbon Measured" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/CM-organisation.png"}></img></div><div>Carbon Measured</div></div> : <></>}
                                                    {organisationFootprint.organisationFootprintCertification === "Carbon Neutral" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/CN-organisation.png"}></img></div><div>Carbon Neutral</div></div> : <></>}
                                                    {organisationFootprint.organisationFootprintCertification === "Lower Carbon" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/LC-organisation.png"}></img></div><div>Lower Carbon</div></div> : <></>}
                                                    {organisationFootprint.organisationFootprintCertification === "Net Zero Carbon Committed" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/NZCC-organisation.png"}></img></div><div>Net Zero Carbon Committed</div></div> : <></>}
                                                    {organisationFootprint.organisationFootprintCertification === "Net Zero Carbon" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/NZC-organisation.png"}></img></div><div>Net Zero Carbon</div></div> : <></>}
                                                    {organisationFootprint.organisationFootprintCertification === "Climate Positive" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/CP-organisation.png"}></img></div><div>Climate Positive</div></div> : <></>}</td>

                                                <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-5">
                                                    { role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 ? 
                                                    <Options edit={`/addOrUpdateOrganisationFootprint/${match.params.organisationId}/${organisationFootprint.id}`} deleteFunction={deleteOrganisationFootprint} deleteObject={organisationFootprint.id} cloneFunction={cloneOrganisationFootprint} cloneObject={organisationFootprint.id}></Options>
                                                     : <Options edit={`/addOrUpdateOrganisationFootprint/${match.params.organisationId}/${organisationFootprint.id}`} ></Options>
                                                    }
                                                    </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <br></br>
                {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 ? <Button color="bondi-blue" text="Add New Organisation Footprint" onClick={() => navigate(`/addOrUpdateOrganisationFootprint/${match.params.organisationId}/_add`)} /> : <></>}
            </div>
        )

    } else {

        return (
            <div>
                {/* <Header title="Organisation Footprints" section="" showLogo={false} /> */}
                <br></br>
                {role && role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 ? <Button color="bondi-blue" text="Add New Organisation Footprint" onClick={() => navigate(`/addOrUpdateOrganisationFootprint/${match.params.organisationId}/_add`)} /> : <></>}
            </div>)

    }

}

export default ListOrganisationFootprints
