import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Sidebar from '../UI/Sidebar'
import ReactSelect from 'react-select'
import { ExportToCsv } from 'export-to-csv'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import { useNavigate } from 'react-router-dom'
import { MultiSelect } from "react-multi-select-component";
import Moment from 'react-moment'

const ProjectsTrackerInvoicing = () => {

    const moment = require('moment');

    const [billingCompany, setBillingCompany] = useState("All")

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [projectIdToSendToModal, setProjectIdToSendToModal] = useState('')

    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')

    const [invoices, setInvoices] = useState([])
    const [invoicesFiltered, setInvoicesFiltered] = useState([])

    const [users, setUsers] = useState([])
    const [clients, setClients] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [clientsLoading, setClientsLoading] = useState(true)

    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)

    const [selectedServiceManager, setSelectedServiceManager] = useState([])
    // const [selectedInvoicePaid, setSelectedInvoicePaid] = useState('All')
    const [selectedInvoiceDraft, setSelectedInvoiceDraft] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [selectedInvoiceMonth, setSelectedInvoiceMonth] = useState('All')
    const [selectedProjectName, setSelectedProjectName] = useState([])
    const [selectedDealName, setSelectedDealName] = useState([])
    const [selectedProjectNumber, setSelectedProjectNumber] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState([])
    const [selectedInvoiceLineStatus, setSelectedInvoiceLineStatus] = useState([])
    const [selectedInvoiceLineComments, setSelectedInvoiceLineComments] = useState([])
    const [selectedOkToInvoice, setSelectedOkToInvoice] = useState([])
    const [fromInvoiceDate, setFromInvoiceDate] = useState('')
    const [toInvoiceDate, setToInvoiceDate] = useState('')
    const [selectedInvoiceIssued, setSelectedInvoiceIssued] = useState([])
    const [selectedProjectManager, setSelectedProjectManager] = useState([])
    const [selectedWorkStage, setSelectedWorkStage] = useState([])
    const [selectedServiceStatus, setSelectedServiceStatus] = useState([])
    const [selectedInvoiceCustPo, setSelectedInvoiceCustPo] = useState([])
    const [selectedProjectBillingPlanAndFeesChecked, setSelectedProjectBillingPlanAndFeesChecked] = useState([])
    const [selectedProjectInvoicingChecked, setSelectedProjectInvoicingChecked] = useState([])
    const [selectedInvoiceClientCheck, setSelectedInvoiceClientCheck] = useState([])
    const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState([])
    const [selectedInvoicingNotes, setSelectedInvoicingNotes] = useState([])
    const [selectedInvoicePaid, setSelectedInvoicePaid] = useState([])


    const [selectedProjectClientId, setSelectedProjectClientId] = useState([])
    const [selectedServiceInvoiceClientId, setSelectedServiceInvoiceClientId] = useState([])
    const [selectedInvoiceClientId, setSelectedInvoiceClientId] = useState([])

    const [totalsToggle, setTotalsToggle] = useState('GBP')

    const [sortedField, setSortedField] = useState(null);
    const [sortedOrder, setSortedOrder] = useState(null);

    // const internationalNumberFormat = new Intl.NumberFormat('en-US')
    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [conversionRateEURToGBP, setConversionRateEURToGBP] = useState(1.17);

    //TOTAL
    const totalGBP = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEUR = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBP = totalEUR / conversionRateEURToGBP
    const totalConvertedIntoGBP = totalGBP + totalEURConvertedIntoGBP

    const totalGBPInvoiced = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURInvoiced = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPInvoiced = totalEURInvoiced / conversionRateEURToGBP
    const totalConvertedIntoGBPInvoiced = totalGBPInvoiced + totalEURConvertedIntoGBPInvoiced
    
    const totalPercentageInvoiced = isNaN((totalConvertedIntoGBPInvoiced / totalConvertedIntoGBP)) ? "0.00" : ((totalConvertedIntoGBPInvoiced / totalConvertedIntoGBP) * 100).toFixed(2)
    
    //TOTAL GREEN
    const totalGBPGreen = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURGreen = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPGreen = totalEURGreen / conversionRateEURToGBP
    const totalConvertedIntoGBPGreen = totalGBPGreen + totalEURConvertedIntoGBPGreen

    const totalGBPInvoicedGreen = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Green" && item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURInvoicedGreen = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Green" && item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPInvoicedGreen = totalEURInvoicedGreen / conversionRateEURToGBP
    const totalConvertedIntoGBPInvoicedGreen = totalGBPInvoicedGreen + totalEURConvertedIntoGBPInvoicedGreen
    
    const totalPercentageInvoicedGreen = isNaN((totalConvertedIntoGBPInvoicedGreen / totalConvertedIntoGBPGreen)) ? "0.00" : ((totalConvertedIntoGBPInvoicedGreen / totalConvertedIntoGBPGreen) * 100).toFixed(2)
    
    //TOTAL AMBER

    const totalGBPAmber = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Amber" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURAmber = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Amber" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPAmber = totalEURAmber / conversionRateEURToGBP
    const totalConvertedIntoGBPAmber = totalGBPAmber + totalEURConvertedIntoGBPAmber

    //TOTAL RED

    const totalGBPRed = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Red" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURRed = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Red" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPRed = totalEURRed / conversionRateEURToGBP
    const totalConvertedIntoGBPRed = totalGBPRed + totalEURConvertedIntoGBPRed

    //TOTAL THIRD PARTY

    const totalGBPThirdParty = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURThirdParty = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPThirdParty = totalEURThirdParty / conversionRateEURToGBP
    const totalConvertedIntoGBPThirdParty = totalGBPThirdParty + totalEURConvertedIntoGBPThirdParty

    const totalGBPInvoicedThirdParty = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Third Party Fee" && item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURInvoicedThirdParty = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Third Party Fee" && item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPInvoicedThirdParty = totalEURInvoicedThirdParty / conversionRateEURToGBP
    const totalConvertedIntoGBPInvoicedThirdParty = totalGBPInvoicedThirdParty + totalEURConvertedIntoGBPInvoicedThirdParty
    
    const totalPercentageInvoicedThirdParty = isNaN((totalConvertedIntoGBPInvoicedThirdParty / totalConvertedIntoGBPThirdParty)) ? "0.00" : ((totalConvertedIntoGBPInvoicedThirdParty / totalConvertedIntoGBPThirdParty) * 100).toFixed(2)
    

    //TOTAL SUB CONTRACTOR

    const totalGBPSubContractor = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURSubContractor = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPSubContractor = totalEURSubContractor / conversionRateEURToGBP
    const totalConvertedIntoGBPSubContractor = totalGBPSubContractor + totalEURConvertedIntoGBPSubContractor

    const totalGBPInvoicedSubContractor = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Sub-contractor Fee" && item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURInvoicedSubContractor = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Sub-contractor Fee" && item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPInvoicedSubContractor = totalEURInvoicedSubContractor / conversionRateEURToGBP
    const totalConvertedIntoGBPInvoicedSubContractor = totalGBPInvoicedSubContractor + totalEURConvertedIntoGBPInvoicedSubContractor
    
    const totalPercentageInvoicedSubContractor = isNaN((totalConvertedIntoGBPInvoicedSubContractor / totalConvertedIntoGBPSubContractor)) ? "0.00" : ((totalConvertedIntoGBPInvoicedSubContractor / totalConvertedIntoGBPSubContractor) * 100).toFixed(2)
    

    //TOTAL PROJECT EXPENSE

    const totalGBPProjectExpenses = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURProjectExpenses = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPProjectExpenses = totalEURProjectExpenses / conversionRateEURToGBP
    const totalConvertedIntoGBPProjectExpenses = totalGBPProjectExpenses + totalEURConvertedIntoGBPProjectExpenses

    const totalGBPInvoicedProjectExpenses = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Project Expenses" && item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURInvoicedProjectExpenses = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Project Expenses" && item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalEURConvertedIntoGBPInvoicedProjectExpenses = totalEURInvoicedProjectExpenses / conversionRateEURToGBP
    const totalConvertedIntoGBPInvoicedProjectExpenses = totalGBPInvoicedProjectExpenses + totalEURConvertedIntoGBPInvoicedProjectExpenses
    
    const totalPercentageInvoicedProjectExpenses = isNaN((totalConvertedIntoGBPInvoicedProjectExpenses / totalConvertedIntoGBPProjectExpenses)) ? "0.00" : ((totalConvertedIntoGBPInvoicedProjectExpenses / totalConvertedIntoGBPProjectExpenses) * 100).toFixed(2)
    




    //TOTALNCS
    const totalNCSGBP = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEUR = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBP = totalNCSEUR / conversionRateEURToGBP
    const totalNCSConvertedIntoGBP = totalNCSGBP + totalNCSEURConvertedIntoGBP

    const totalNCSGBPInvoiced = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURInvoiced = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPInvoiced = totalNCSEURInvoiced / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPInvoiced = totalNCSGBPInvoiced + totalNCSEURConvertedIntoGBPInvoiced

    const totalNCSPercentageInvoiced = isNaN((totalNCSConvertedIntoGBPInvoiced / totalNCSConvertedIntoGBP)) ? "0.00" : ((totalNCSConvertedIntoGBPInvoiced / totalNCSConvertedIntoGBP) * 100).toFixed(2)

    //totalNCS GREEN
    const totalNCSGBPGreen = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURGreen = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPGreen = totalNCSEURGreen / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPGreen = totalNCSGBPGreen + totalNCSEURConvertedIntoGBPGreen

    const totalNCSGBPInvoicedGreen = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Green" && item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURInvoicedGreen = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Green" && item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPInvoicedGreen = totalNCSEURInvoicedGreen / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPInvoicedGreen = totalNCSGBPInvoicedGreen + totalNCSEURConvertedIntoGBPInvoicedGreen

    const totalNCSPercentageInvoicedGreen = isNaN((totalNCSConvertedIntoGBPInvoicedGreen / totalNCSConvertedIntoGBPGreen)) ? "0.00" : ((totalNCSConvertedIntoGBPInvoiced / totalNCSConvertedIntoGBP) * 100).toFixed(2)

    //totalNCS AMBER

    const totalNCSGBPAmber = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Amber" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURAmber = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Amber" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPAmber = totalNCSEURAmber / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPAmber = totalNCSGBPAmber + totalNCSEURConvertedIntoGBPAmber

    //totalNCS RED

    const totalNCSGBPRed = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Red" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURRed = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Red" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPRed = totalNCSEURRed / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPRed = totalNCSGBPRed + totalNCSEURConvertedIntoGBPRed

    //totalNCS THIRD PARTY

    const totalNCSGBPThirdParty = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURThirdParty = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPThirdParty = totalNCSEURThirdParty / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPThirdParty = totalNCSGBPThirdParty + totalNCSEURConvertedIntoGBPThirdParty

    const totalNCSGBPInvoicedThirdParty = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Third Party Fee" && item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURInvoicedThirdParty = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Third Party Fee" && item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPInvoicedThirdParty = totalNCSEURInvoicedThirdParty / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPInvoicedThirdParty = totalNCSGBPInvoicedThirdParty + totalNCSEURConvertedIntoGBPInvoicedThirdParty

    const totalNCSPercentageInvoicedThirdParty = isNaN((totalNCSConvertedIntoGBPInvoicedThirdParty / totalNCSConvertedIntoGBPThirdParty)) ? "0.00" : ((totalNCSConvertedIntoGBPInvoicedThirdParty / totalNCSConvertedIntoGBPThirdParty) * 100).toFixed(2)


    //totalNCS SUB CONTRACTOR

    const totalNCSGBPSubContractor = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURSubContractor = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPSubContractor = totalNCSEURSubContractor / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPSubContractor = totalNCSGBPSubContractor + totalNCSEURConvertedIntoGBPSubContractor

    const totalNCSGBPInvoicedSubContractor = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Sub-contractor Fee" && item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURInvoicedSubContractor = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Sub-contractor Fee" && item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPInvoicedSubContractor = totalNCSEURInvoicedSubContractor / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPInvoicedSubContractor = totalNCSGBPInvoicedSubContractor + totalNCSEURConvertedIntoGBPInvoicedSubContractor

    const totalNCSPercentageInvoicedSubContractor = isNaN((totalNCSConvertedIntoGBPInvoicedSubContractor / totalNCSConvertedIntoGBPSubContractor)) ? "0.00" : ((totalNCSConvertedIntoGBPInvoicedSubContractor / totalNCSConvertedIntoGBPSubContractor) * 100).toFixed(2)


    //totalNCS PROJECT EXPENSE

    const totalNCSGBPProjectExpenses = invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURProjectExpenses = invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPProjectExpenses = totalNCSEURProjectExpenses / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPProjectExpenses = totalNCSGBPProjectExpenses + totalNCSEURConvertedIntoGBPProjectExpenses

    const totalNCSGBPInvoicedProjectExpenses = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Project Expenses" && item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURInvoicedProjectExpenses = invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.invoiceLineStatus === "Project Expenses" && item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)
    const totalNCSEURConvertedIntoGBPInvoicedProjectExpenses = totalNCSEURInvoicedProjectExpenses / conversionRateEURToGBP
    const totalNCSConvertedIntoGBPInvoicedProjectExpenses = totalNCSGBPInvoicedProjectExpenses + totalNCSEURConvertedIntoGBPInvoicedProjectExpenses

    const totalNCSPercentageInvoicedProjectExpenses = isNaN((totalNCSConvertedIntoGBPInvoicedProjectExpenses / totalNCSConvertedIntoGBPProjectExpenses)) ? "0.00" : ((totalNCSConvertedIntoGBPInvoicedProjectExpenses / totalNCSConvertedIntoGBPProjectExpenses) * 100).toFixed(2)


    const navigate = useNavigate()

    const currentMonth = date => {

        const dateConverted = new Date(date)

        const yyyy = dateConverted.getFullYear();
        let mm = dateConverted.getMonth() + 1; // Months start at 0!

        if (mm < 10) mm = '0' + mm;

        const formattedMonth = yyyy + '-' + mm

        return formattedMonth
    }

    const onMenuOpen = () => {
        setTimeout(() => {
            const selectedEl = document.getElementsByClassName("MyDropdown__option--is-selected")[0];
            if (selectedEl) {
                selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }, 5);
    };
    
    const onClickOfInvoiceMonthSelector = () => {

        if (selectedInvoiceMonth === "All") {
            setSelectedInvoiceMonth({ value: currentMonth(Date()), label: currentMonth(Date()) })
        }

    };

    const prev100Records = () => {
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }

    }

    const next100Records = () => {

        if (lastRecord <= invoicesFiltered.length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

    }


    const viewProject = (id) => {
        window.open(`/viewClientProject/${id}`);
    }

    function stringToDate(_date, _format, _delimiter) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
        return formatedDate;
    }

    const arrayToCsv = (data) => {

        var dataToExport = []
        var j
        var invoiceClientName, projectClientName, serviceInvoiceClientName, projectManagerFirstName, projectManagerLastName, serviceManagerFirstName, serviceManagerLastName

        for (j = 0; j < data.length; j++) {

            invoiceClientName = data[j].invoiceClientId ? clients.find(item => item.id === data[j].invoiceClientId).clientName : ""
            projectClientName = data[j].projectClientId ? clients.find(item => item.id === data[j].projectClientId).clientName : ""
            serviceInvoiceClientName = data[j].serviceInvoiceClientId ? clients.find(item => item.id === data[j].serviceInvoiceClientId).clientName : ""
            projectManagerFirstName = users.find(item => item.id === data[j].projectManagerId).firstName
            projectManagerLastName = users.find(item => item.id === data[j].projectManagerId).lastName
            serviceManagerFirstName = users.find(item => item.id === data[j].serviceManager).firstName
            serviceManagerLastName = users.find(item => item.id === data[j].serviceManager).lastName

            dataToExport.push({
                ...data[j],
                "Invoice Client" : invoiceClientName,
                "Project Client" : projectClientName,
                "Service Invoice Client" : serviceInvoiceClientName,
                "Project Manager First Name" : projectManagerFirstName,
                "Project Manager Last Name" : projectManagerLastName,
                "Service Manager First Name" : serviceManagerFirstName,
                "Service Manager Last Name" : serviceManagerLastName
            })
            
        }


        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Billing Plan Tracker',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        // csvExporter.generateCsv(data)
        csvExporter.generateCsv(dataToExport)

    }

    const arrayToCsvForXero = (data) => {

        var dataToExport = []
        var j
        var projectClient, clientPO, contactName, invoiceNumber, invoiceDate, invoiceLineDescription, invoiceLineValue, accountCode, TaxType, dueDate, reference, currency, invoiceLineDescriptionTidy, projectInvoicingNotes
        var monthOfYear

        for (j = 0; j < data.length; j++) {

            contactName = clients.find(item => item.id === data[j].invoiceClientId).clientName
            invoiceNumber = data[j].invoiceNumber
            // invoiceLineDescription = "PO No: " + data[j].invoiceCustPo + "\r" + data[j].projectNumber + " - " + data[j].projectName + "\r" + data[j].invoiceLineDescription
            
            //Check here to see if data[j].invoiceLineDescription starts with the project number twice

            if (data[j].invoiceLineDescription.slice(0,4) === data[j].projectNumber && data[j].invoiceLineDescription.slice(7,11) === data[j].projectNumber) {
                invoiceLineDescriptionTidy = data[j].invoiceLineDescription.slice(7, data[j].invoiceLineDescription.length)
            } else if (data[j].invoiceLineDescription.slice(0,4) === data[j].projectNumber && data[j].invoiceLineDescription.slice(8,12) === data[j].projectNumber) {
                invoiceLineDescriptionTidy = data[j].invoiceLineDescription.slice(8, data[j].invoiceLineDescription.length)
            } else if (data[j].invoiceLineDescription.slice(0,4) !== data[j].projectNumber && data[j].invoiceLineDescription.slice(0,5) !== data[j].projectNumber && data[j].invoiceLineDescription.slice(0,6) !== data[j].projectNumber) {
                invoiceLineDescriptionTidy = data[j].projectNumber + " - " + data[j].invoiceLineDescription
            } else {
                invoiceLineDescriptionTidy = data[j].invoiceLineDescription
            }

            if (data[j].invoiceCustPo !== "") {
            
                invoiceLineDescription = "PO No: " + data[j].invoiceCustPo + "\r" + invoiceLineDescriptionTidy

            } else {

                invoiceLineDescription = invoiceLineDescriptionTidy

            }

            invoiceLineValue = data[j].invoiceLineValue
            invoiceDate = data[j].invoiceDate

            monthOfYear = (stringToDate(invoiceDate,"YYYY-MM-DD","-").getMonth()+1).toString().length === 2 ? stringToDate(invoiceDate,"YYYY-MM-DD","-").getMonth()+1 : "0" + (stringToDate(invoiceDate,"YYYY-MM-DD","-").getMonth()+1) 

            //Code needed here to handle 15th / EOM invoiceDateAllocation
            if (stringToDate(invoiceDate,"YYYY-MM-DD","-").getDate() <= 15) {
                invoiceDate = stringToDate(invoiceDate,"YYYY-MM-DD","-").getFullYear() + "-" + monthOfYear + "-15"
            } else {
                if (monthOfYear === "01" || monthOfYear === "03" || monthOfYear === "05" || monthOfYear === "07" || monthOfYear === "08" || monthOfYear === "10" || monthOfYear === "12") {
                    invoiceDate = stringToDate(invoiceDate,"YYYY-MM-DD","-").getFullYear() + "-" + monthOfYear + "-31"
                }
                if (monthOfYear === "04" || monthOfYear === "06" || monthOfYear === "09" || monthOfYear === "11") {
                    invoiceDate = stringToDate(invoiceDate,"YYYY-MM-DD","-").getFullYear() + "-" + monthOfYear + "-30"
                }
                if (monthOfYear === "02") {
                    invoiceDate = stringToDate(invoiceDate,"YYYY-MM-DD","-").getFullYear() + "-" + monthOfYear + "-28"
                }
            }

            reference = data[j].projectNumber
            projectClient = clients.find(item => item.id === data[j].projectClientId).clientName
            clientPO = data[j].invoiceCustPo
            currency = data[j].projectCurrency
            projectInvoicingNotes = data[j].projectInvoicingNotes
            accountCode = ""
    

            if (data[j].invoiceLineStatus !== "Third Party Fee") {
            
                if (data[j].department === "01. Environmental Assessment") {
                    accountCode = "20010"
                }

                // if (data[j].department === "02. Environmental Assessment North") {
                //     accountCode = "20010"
                // }

                if (data[j].department === "03. Building Performance") {
                    accountCode = "20011"
                }

                if (data[j].department === "04. Building LCA") {
                    accountCode = "20019"
                }

                if (data[j].department === "05. Health and Wellbeing") {
                    accountCode = "20015"
                }

                if (data[j].department === "06. Ecology") {
                    accountCode = "20012"
                }

                if (data[j].department === "07. ESG") {
                    accountCode = "20013"
                }

                if (data[j].department === "08. Footprinting") {
                    accountCode = "20020"
                }

                if (data[j].department === "09. Carbon Offsetting") {
                    accountCode = "20021"
                }

                if (data[j].department === "10. Operations" && data[j].service === "Coworking Desk Hire") {
                    accountCode = "20001"
                }

                if (data[j].department === "10. Operations" && data[j].service !== "Coworking Desk Hire") {
                    accountCode = "20000"
                }

                if (data[j].department === "20. NCS Verification") {
                    accountCode = "21520"
                }

            } else {

                if (data[j].department === "01. Environmental Assessment") {
                    accountCode = "21510"
                }

                // if (data[j].department === "02. Environmental Assessment North") {
                //     accountCode = "21510"
                // }

                if (data[j].department === "03. Building Performance") {
                    accountCode = "21511"
                }

                if (data[j].department === "04. Building LCA") {
                    accountCode = "21519"
                }

                if (data[j].department === "05. Health and Wellbeing") {
                    accountCode = "21515"
                }

                if (data[j].department === "06. Ecology") {
                    accountCode = "21512"
                }

                if (data[j].department === "07. ESG") {
                    accountCode = "21513"
                }

                // if (data[j].department === "08. Footprinting" && data[j].service === "3rd Party Fee") {
                //     accountCode = "21524"
                // }

                // if (data[j].department === "08. Footprinting" && !data[j].service !== "3rd Party Fee") {
                //     accountCode = "21520"
                // }

                if (data[j].department === "08. Footprinting") {
                    accountCode = "21520"
                }

                if (data[j].department === "09. Carbon Offsetting") {
                    accountCode = "21521"
                }

                if (data[j].department === "10. Operations" && data[j].service === "Coworking Desk Hire") {
                    accountCode = "20001"
                }

                if (data[j].department === "10. Operations" && data[j].service !== "Coworking Desk Hire") {
                    accountCode = "20000"
                }

                if (data[j].department === "20. NCS Verification") {
                    accountCode = "21520"
                }

            }
            
            if (data[j].projectCurrency === "£") {
                TaxType = "20% (VAT on Income)"
            } else {
                TaxType = "Reverse Charge Income - Overseas (20%)"
            }


            dueDate = moment(invoiceDate).add(30, 'days').locale('en').format("YYYY-MM-DD")

            dataToExport.push({
                "TO DELETE - Project Client": projectClient,
                "TO DELETE - Client PO" : clientPO,
                "TO DELETE - Invoicing Notes" : projectInvoicingNotes,
                "*ContactName" : contactName,
                "EmailAddress" : "",
                "POAddressLine1" : "",
                "POAddressLine2" : "",
                "POAddressLine3" : "",
                "POAddressLine4" : "",
                "POCity" : "",
                "PORegion" : "",
                "POPostalCode" : "",
                "POCountry" : "",
                "*InvoiceNumber" : invoiceNumber,
                "Reference" : reference,
                "*InvoiceDate" : invoiceDate,
                "*DueDate": dueDate,
                "Total" : "",
                "InventoryItemCode" : "",
                "*Description" : invoiceLineDescription,
                "*Quantity" : 1,
                "*UnitAmount" : invoiceLineValue,
                "Discount" : "",
                "*AccountCode" : accountCode,
                "*TaxType" : TaxType,
                "TaxAmount" : "",
                "TrackingName1" : "",
                "TrackingOption1" : "",
                "TrackingName2" : "",
                "TrackingOption2" : "",
                "Currency" : currency,
                "BrandingTheme" : ""

            })
            
        }


        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(dataToExport)

    }


    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            //width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            //width: 160,
            fontSize: 13
        }),
    }

    const clearFilters = () => {

        // setSelectedInvoicePaid('All')
        setSelectedInvoiceMonth('All')
        setFromInvoiceDate('')
        setToInvoiceDate('')
        setSortedField('projectNumber')
        setSortedOrder('asc')

        setSelectedProjectName(aggregateProjectNameArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.projectName,
                value: item.projectName
            }
        }))
        setSelectedDealName(aggregateDealNameArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.dealName,
                value: item.dealName
            }
        }))
        setSelectedDepartment(aggregateDepartmentsArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.department,
                value: item.department
            }
        }))
        setSelectedService(aggregateServicesArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.serviceDescription,
                value: item.serviceDescription
            }
        }))
        setSelectedWorkStage(aggregateWorkStagesArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.workStage,
                value: item.workStage
            }
        }))
        setSelectedProjectNumber(aggregateProjectNumberArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.projectNumber,
                value: item.projectNumber
            }
        }))
        setSelectedInvoiceLineStatus(aggregateInvoiceLineStatusArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.invoiceLineStatus,
                value: item.invoiceLineStatus
            }
        }))
        setSelectedInvoiceLineComments(aggregateInvoiceLineCommentsArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.invoiceLineComments,
                value: item.invoiceLineComments
            }
        }))
        setSelectedServiceStatus(aggregateServiceStatusArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.serviceStatus,
                value: item.serviceStatus
            }
        }).filter(item => item.value !== "On-hold"))
        setSelectedInvoiceCustPo(aggregateInvoiceCustPoArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.invoiceCustPo,
                value: item.invoiceCustPo
            }
        }))
        setSelectedProjectBillingPlanAndFeesChecked(aggregateBillingPlanAndFeesCheckedArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.projectBillingPlanAndFeesChecked === "NO" ? "NO" : users.find(user => user.id === item.projectBillingPlanAndFeesChecked).firstName + " " + users.find(user => user.id === item.projectBillingPlanAndFeesChecked).lastName,
                value: item.projectBillingPlanAndFeesChecked
            }
        }))
        setSelectedProjectInvoicingChecked(aggregateInvoicingCheckedArray(invoices.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
            return {
                label: item.projectInvoicingChecked === "NO" ? "NO" : users.find(user => user.id === item.projectInvoicingChecked).firstName + " " + users.find(user => user.id === item.projectInvoicingChecked).lastName,
                value: item.projectInvoicingChecked
            }
        }))
        //The label on the page was changed to "Confirmed Invoice" instead of "Draft Invoice", as such the dropdown labels have been reversed
        setSelectedInvoiceDraft([{ value: 'YES', label: 'NO' }, { value: 'NO', label: 'YES' }])
        setSelectedOkToInvoice([{ value: 'YES', label: 'YES' }, { value: 'NO', label: 'NO' }])
        setSelectedInvoiceIssued([{ value: 'YES', label: 'YES' }, { value: 'NO', label: 'NO' }])
        setSelectedInvoicePaid([{ value: 'YES', label: 'YES' }, { value: 'NO', label: 'NO' }, { value: 'CREDIT', label: 'CREDIT' }])
        setSelectedInvoiceClientCheck([{ value: false, label: 'FALSE' }, { value: true, label: 'TRUE' }])

        setSelectedProjectClientId(aggregateProjectClientArray(invoices).map((item) => {
            return {
                label: item.projectClientId === "" ? "" : clients.find(client => client.id === item.projectClientId).clientName,
                value: item.projectClientId
            }
        }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

        setSelectedInvoiceClientId(aggregateInvoiceClientArray(invoices).map((item) => {
            return {
                label: item.invoiceClientId === "" ? "" : clients.find(client => client.id === item.invoiceClientId).clientName,
                value: item.invoiceClientId
            }
        }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

        setSelectedInvoiceNumber(aggregateInvoiceNumbersArray(invoices).map((item) => {
            return {
                label: item.invoiceNumber,
                value: item.invoiceNumber
            }
        }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

        setSelectedInvoicingNotes(aggregateInvoicingNotesArray(invoices).map((item) => {
            return {
                label: item.projectInvoicingNotes,
                value: item.projectInvoicingNotes
            }
        }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

        setSelectedServiceManager(aggregateServiceManagerArray(invoices).map((item) => {
            return {
                label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                value: item.serviceManager
            }
        }))
        setSelectedProjectManager(aggregateProjectManagerArray(invoices).map((item) => {
            return {
                label: item.projectManagerId === "" ? "" : users.find(user => user.id === item.projectManagerId).firstName + " " + users.find(user => user.id === item.projectManagerId).lastName,
                value: item.projectManagerId
            }
        }))
        setSelectedServiceInvoiceClientId(aggregateClientToInvoiceArray(invoices).map((item) => {
            return {
                label: item.serviceInvoiceClientId === "" ? "" : clients.find(client => client.id === item.serviceInvoiceClientId).clientName,
                value: item.serviceInvoiceClientId
            }
        }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0))

    }

    const aggregateInvoiceClientCheckArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.invoiceClientCheck === val.invoiceClientCheck);
            if (index === -1) {
                acc.push({
                    invoiceClientCheck: val.invoiceClientCheck,
                });
            };
            return acc;
        }, []);
    };

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceDescription === val.serviceDescription);
            if (index === -1) {
                acc.push({
                    serviceDescription: val.serviceDescription,
                });
            };
            return acc;
        }, []);
    };

    const aggregateInvoiceMonthArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.invoiceMonth === val.invoiceMonth);
            if (index === -1) {
                acc.push({
                    invoiceMonth: val.invoiceMonth,
                });
            };
            return acc.sort((item1, item2) => item1.invoiceMonth > item2.invoiceMonth ? -1 : 0)
        }, []);
    };

    const aggregateProjectNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectName === val.projectName);
            if (index === -1) {
                acc.push({
                    projectName: val.projectName,
                });
            };
            return acc.sort((item1, item2) => item1.projectName < item2.projectName ? -1 : 0)
        }, []);
    };

    const aggregateDealNameArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.dealName === val.dealName);
            if (index === -1) {
                acc.push({
                    dealName: val.dealName,
                });
            };
            return acc.sort((item1, item2) => item1.dealName < item2.dealName ? -1 : 0)
        }, []);
    };

    const aggregateProjectNumberArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectNumber === val.projectNumber);
            if (index === -1) {
                acc.push({
                    projectNumber: val.projectNumber,
                });
            };
            return acc.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0)
        }, []);
    };

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department,
                });
            };
            return acc.sort((item1, item2) => item1.department < item2.department ? -1 : 0)
        }, []);
    };

    const aggregateInvoicingNotesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectInvoicingNotes === val.projectInvoicingNotes);
            if (index === -1) {
                acc.push({
                    projectInvoicingNotes: val.projectInvoicingNotes,
                });
            };
            return acc.sort((item1, item2) => item1.projectInvoicingNotes < item2.projectInvoicingNotes ? -1 : 0)
        }, []);
    };

    const aggregateInvoiceLineStatusArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.invoiceLineStatus === val.invoiceLineStatus);
            if (index === -1) {
                acc.push({
                    invoiceLineStatus: val.invoiceLineStatus,
                });
            };
            return acc;
        }, []);
    };

    const aggregateInvoiceLineCommentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.invoiceLineComments === val.invoiceLineComments);
            if (index === -1) {
                acc.push({
                    invoiceLineComments: val.invoiceLineComments,
                });
            };
            return acc;
        }, []);
    };

    const aggregateWorkStagesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.workStage === val.workStage);
            if (index === -1) {
                if (val.workStage.length > 0) {
                    acc.push({
                        workStage: val.workStage
                    });
                }
            };
            return acc;
        }, []);
    };

    const aggregateServiceStatusArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceStatus === val.serviceStatus);
            if (index === -1) {
                if (val.serviceStatus.length > 0) {
                    acc.push({
                        serviceStatus: val.serviceStatus
                    });
                }
            };
            return acc;
        }, []);
    };

    const aggregateInvoiceCustPoArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.invoiceCustPo === val.invoiceCustPo);
            if (index === -1) {
                // if (val.invoiceCustPo.length > 0) {
                    acc.push({
                        invoiceCustPo: val.invoiceCustPo
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateBillingPlanAndFeesCheckedArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectBillingPlanAndFeesChecked === val.projectBillingPlanAndFeesChecked);
            if (index === -1) {
                if (val.projectBillingPlanAndFeesChecked.length > 0) {
                    acc.push({
                        projectBillingPlanAndFeesChecked: val.projectBillingPlanAndFeesChecked
                    });
                }
            };
            return acc;
        }, []);
    };

    const aggregateInvoicingCheckedArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectInvoicingChecked === val.projectInvoicingChecked);
            if (index === -1) {
                if (val.projectInvoicingChecked.length > 0) {
                    acc.push({
                        projectInvoicingChecked: val.projectInvoicingChecked
                    });
                }
            };
            return acc;
        }, []);
    };

    const aggregateClientToInvoiceArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceInvoiceClientId === val.serviceInvoiceClientId);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        serviceInvoiceClientId: val.serviceInvoiceClientId
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateInvoiceClientArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.invoiceClientId === val.invoiceClientId);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        invoiceClientId: val.invoiceClientId
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateInvoiceNumbersArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.invoiceNumber === val.invoiceNumber);
            if (index === -1) {
                acc.push({
                    invoiceNumber: val.invoiceNumber
                });
            };
            return acc.sort((item1, item2) => item1.invoiceNumber < item2.invoiceNumber ? -1 : 0);
        }, []);
    };

    const aggregateProjectClientArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectClientId === val.projectClientId);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        projectClientId: val.projectClientId
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateProjectManagerArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.projectManagerId === val.projectManagerId);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        projectManagerId: val.projectManagerId
                    });
                // }
            };
            return acc;
        }, []);
    };

    const aggregateServiceManagerArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceManager === val.serviceManager);
            if (index === -1) {
                // if (val.serviceInvoiceClientId.length > 0) {
                    acc.push({
                        serviceManager: val.serviceManager
                    });
                // }
            };
            return acc;
        }, []);
    };

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            axios.get(process.env.REACT_APP_API_URL + '/clients')
                .then((res) => {
                    setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0))

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setClientsLoading(false);
                });

            axios.get(process.env.REACT_APP_API_URL + '/users')
                .then((res) => {
                    setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUsersLoading(false);
                });

                axios.get(process.env.REACT_APP_API_URL + '/performance_tracker_exchange_rates')
                    .then((response) => {
                        setConversionRateEURToGBP(response.data.find((item) => item.financialYear.slice(0,4)=== currentMonth(Date()).slice(0,4) && item.currencyPair==="EUR/GBP").rate)
                    })
                    .catch((err) => {
                        console.log(err);
                    })

            setSelectedInvoiceMonth({ value: currentMonth(Date()), label: currentMonth(Date()) })

        }

    }, [role, company, userPropertiesLoading])

    useEffect(() => {

        if (company && role && !userPropertiesLoading && users.length > 0 && !usersLoading && clients.length > 0 && !clientsLoading) {

            axios.get(process.env.REACT_APP_API_URL + '/invoices_report')
                .then((res) => {
                    setInvoices(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
                    //setInvoicesFiltered(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO").sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
                    setSelectedProjectName(aggregateProjectNameArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.projectName,
                            value: item.projectName
                        }
                    }))
                    setSelectedDealName(aggregateDealNameArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.dealName,
                            value: item.dealName
                        }
                    }))
                    setSelectedDepartment(aggregateDepartmentsArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.department,
                            value: item.department
                        }
                    }))
                    setSelectedService(aggregateServicesArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.serviceDescription,
                            value: item.serviceDescription
                        }
                    }))
                    setSelectedWorkStage(aggregateWorkStagesArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.workStage,
                            value: item.workStage
                        }
                    }))
                    setSelectedProjectNumber(aggregateProjectNumberArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.projectNumber,
                            value: item.projectNumber
                        }
                    }))
                    setSelectedInvoiceLineStatus(aggregateInvoiceLineStatusArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.invoiceLineStatus,
                            value: item.invoiceLineStatus
                        }
                    }))
                    setSelectedInvoiceLineComments(aggregateInvoiceLineCommentsArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.invoiceLineComments,
                            value: item.invoiceLineComments
                        }
                    }))
                    setSelectedServiceStatus(aggregateServiceStatusArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.serviceStatus,
                            value: item.serviceStatus
                        }
                    }).filter(item => item.value !== "On-hold"))
                    setSelectedInvoiceCustPo(aggregateInvoiceCustPoArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.invoiceCustPo,
                            value: item.invoiceCustPo
                        }
                    }))
                    setSelectedInvoiceNumber(aggregateInvoiceNumbersArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.invoiceNumber,
                            value: item.invoiceNumber
                        }
                    }))
                    setSelectedInvoicingNotes(aggregateInvoicingNotesArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.projectInvoicingNotes,
                            value: item.projectInvoicingNotes
                        }
                    }))
                    setSelectedProjectBillingPlanAndFeesChecked(aggregateBillingPlanAndFeesCheckedArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.projectBillingPlanAndFeesChecked === "NO" ? "NO" : users.find(user => user.id === item.projectBillingPlanAndFeesChecked).firstName + " " + users.find(user => user.id === item.projectBillingPlanAndFeesChecked).lastName,
                            // label: item.projectBillingPlanAndFeesChecked,
                            value: item.projectBillingPlanAndFeesChecked
                        }
                    }))
                    setSelectedProjectInvoicingChecked(aggregateInvoicingCheckedArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.projectInvoicingChecked === "NO" ? "NO" : users.find(user => user.id === item.projectInvoicingChecked).firstName + " " + users.find(user => user.id === item.projectInvoicingChecked).lastName,
                            // label: item.projectInvoicingChecked,
                            value: item.projectInvoicingChecked
                        }
                    }))
                    setSelectedServiceInvoiceClientId(aggregateClientToInvoiceArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.serviceInvoiceClientId === "" ? "" : clients.find(client => client.id === item.serviceInvoiceClientId).clientName,
                            value: item.serviceInvoiceClientId
                        }
                    }))
                    setSelectedInvoiceClientId(aggregateInvoiceClientArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.invoiceClientId === "" ? "" : clients.find(client => client.id === item.invoiceClientId).clientName,
                            value: item.invoiceClientId
                        }
                    }))
                    setSelectedProjectClientId(aggregateProjectClientArray(res.data.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO")).map((item) => {
                        return {
                            label: item.projectClientId === "" ? "" : clients.find(client => client.id === item.projectClientId).clientName,
                            value: item.projectClientId
                        }
                    }))
                    setSelectedServiceManager(aggregateServiceManagerArray(res.data).map((item) => {
                        return {
                            label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                            value: item.serviceManager
                        }
                    }))
                    setSelectedProjectManager(aggregateProjectManagerArray(res.data).map((item) => {
                        return {
                            label: item.projectManagerId === "" ? "" : users.find(user => user.id === item.projectManagerId).firstName + " " + users.find(user => user.id === item.projectManagerId).lastName,
                            value: item.projectManagerId
                        }
                    }))
                    //The label on the page was changed to "Confirmed Invoice" instead of "Draft Invoice", as such the dropdown labels have been reversed
                    setSelectedInvoiceDraft([{ value: 'YES', label: 'NO' }, { value: 'NO', label: 'YES' }])
                    setSelectedOkToInvoice([{ value: 'YES', label: 'YES' }, { value: 'NO', label: 'NO' }])
                    setSelectedInvoiceIssued([{ value: 'YES', label: 'YES' }, { value: 'NO', label: 'NO' }])
                    setSelectedInvoicePaid([{ value: 'YES', label: 'YES' }, { value: 'NO', label: 'NO' }, { value: 'CREDIT', label: 'CREDIT' }])
                    setSelectedInvoiceClientCheck([{ value: false, label: 'FALSE' }, { value: true, label: 'TRUE' }])
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }

    }, [role, company, userPropertiesLoading, users, clients])

    useEffect(() => {

        setInvoicesFiltered(invoices)

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedServiceManager.find((itemB) => {
                return itemA.serviceManager === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoiceDraft.find((itemB) => {
                return itemA.draftInvoice === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedService.find((itemB) => {
                return itemA.serviceDescription === itemB.value;
            })
        }))

        if (selectedInvoiceMonth !== "All") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => item.invoiceMonth === selectedInvoiceMonth.value))
        }

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedProjectName.find((itemB) => {
                return itemA.projectName === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedDealName.find((itemB) => {
                return itemA.dealName === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedProjectNumber.find((itemB) => {
                return itemA.projectNumber === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedDepartment.find((itemB) => {
                return itemA.department === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoiceLineStatus.find((itemB) => {
                return itemA.invoiceLineStatus === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoiceLineComments.find((itemB) => {
                return itemA.invoiceLineComments === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedOkToInvoice.find((itemB) => {
                return itemA.okToInvoice === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoiceIssued.find((itemB) => {
                return itemA.invoiceIssued === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoicePaid.find((itemB) => {
                return itemA.invoicePaid === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoiceNumber.find((itemB) => {
                return itemA.invoiceNumber === itemB.value;
            })
        }))

        if (fromInvoiceDate !== "") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => stringToDate(item.invoiceDate, "YYYY-MM-DD", "-") >= stringToDate(fromInvoiceDate, "YYYY-MM-DD", "-")))
        }

        if (toInvoiceDate !== "") {
            setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((item) => stringToDate(item.invoiceDate, "YYYY-MM-DD", "-") <= stringToDate(toInvoiceDate, "YYYY-MM-DD", "-")))
        }

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedProjectManager.find((itemB) => {
                return itemA.projectManagerId === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedWorkStage.find((itemB) => {
                return itemA.workStage === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedServiceStatus.find((itemB) => {
                return itemA.serviceStatus === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoiceCustPo.find((itemB) => {
                return itemA.invoiceCustPo === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedProjectClientId.find((itemB) => {
                return itemA.projectClientId === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedServiceInvoiceClientId.find((itemB) => {
                return itemA.serviceInvoiceClientId === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoiceClientId.find((itemB) => {
                return itemA.invoiceClientId === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedProjectInvoicingChecked.find((itemB) => {
                return itemA.projectInvoicingChecked === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedProjectBillingPlanAndFeesChecked.find((itemB) => {
                return itemA.projectBillingPlanAndFeesChecked === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoiceClientCheck.find((itemB) => {
                return itemA.invoiceClientCheck === itemB.value;
            })
        }))

        setInvoicesFiltered(invoicesFiltered => invoicesFiltered.filter((itemA) => {
            return selectedInvoicingNotes.find((itemB) => {
                return itemA.projectInvoicingNotes === itemB.value;
            })
        }))

        setSortedField('projectNumber')
        setSortedOrder('asc')

        setFirstRecord(0)
        setLastRecord(100)

    }, [selectedServiceManager, selectedService, selectedInvoiceMonth, selectedInvoiceDraft, selectedProjectName, selectedProjectNumber, selectedDepartment, selectedInvoiceLineStatus, selectedInvoiceLineComments, fromInvoiceDate, toInvoiceDate, selectedOkToInvoice, selectedInvoiceIssued, selectedProjectManager, invoices, selectedWorkStage, selectedServiceStatus, selectedInvoiceCustPo, selectedProjectClientId, selectedServiceInvoiceClientId, selectedInvoiceClientId, selectedProjectBillingPlanAndFeesChecked, selectedProjectInvoicingChecked, selectedInvoiceClientCheck, selectedInvoiceNumber, selectedInvoicePaid, selectedInvoicingNotes, selectedDealName])

    useEffect(() => {

        let sortedInvoices = [...invoicesFiltered];

        if (sortedField === "invoiceValue" && sortedOrder === "asc") {
            setInvoicesFiltered(sortedInvoices.sort((item1, item2) => Number(item1.invoiceLineValue) < Number(item2.invoiceLineValue) ? -1 : Number(item1.invoiceLineValue) > Number(item2.invoiceLineValue) ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "invoiceValue" && sortedOrder === "desc") {
            setInvoicesFiltered(sortedInvoices.sort((item1, item2) => Number(item1.invoiceLineValue) < Number(item2.invoiceLineValue) ? 1 : Number(item1.invoiceLineValue) > Number(item2.invoiceLineValue) ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "invoiceLineStatus" && sortedOrder === "asc") {
            setInvoicesFiltered(sortedInvoices.sort((item1, item2) => item1.invoiceLineStatus < item2.invoiceLineStatus ? -1 : item1.invoiceLineStatus > item2.invoiceLineStatus ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "invoiceLineStatus" && sortedOrder === "desc") {
            setInvoicesFiltered(sortedInvoices.sort((item1, item2) => item1.invoiceLineStatus < item2.invoiceLineStatus ? 1 : item1.invoiceLineStatus > item2.invoiceLineStatus ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "projectNumber" && sortedOrder === "asc") {
            setInvoicesFiltered(sortedInvoices.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "projectNumber" && sortedOrder === "desc") {
            setInvoicesFiltered(sortedInvoices.sort((item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? 1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? -1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "invoiceDate" && sortedOrder === "asc") {
            setInvoicesFiltered(sortedInvoices.sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        } else if (sortedField === "invoiceDate" && sortedOrder === "desc") {
            setInvoicesFiltered(sortedInvoices.sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? 1 : item1.invoiceDate > item2.invoiceDate ? -1 : (item1, item2) => Number(item1.projectNumber) < Number(item2.projectNumber) ? -1 : Number(item1.projectNumber) > Number(item2.projectNumber) ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
        }

    }, [sortedField, sortedOrder])

    useEffect(() => {

        if (selectedInvoiceMonth !== "All") {

            setSelectedServiceManager(aggregateServiceManagerArray(invoices.filter(item => item.invoiceMonth === selectedInvoiceMonth.value)).map((item) => {
                return {
                    label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                    value: item.serviceManager
                }
            }))

            setSelectedProjectManager(aggregateProjectManagerArray(invoices.filter(item => item.invoiceMonth === selectedInvoiceMonth.value)).map((item) => {
                return {
                    label: item.projectManagerId === "" ? "" : users.find(user => user.id === item.projectManagerId).firstName + " " + users.find(user => user.id === item.projectManagerId).lastName,
                    value: item.projectManagerId
                }
            }))

        } else {

            setSelectedServiceManager(aggregateServiceManagerArray(invoices).map((item) => {
                return {
                    label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                    value: item.serviceManager
                }
            }))

            setSelectedProjectManager(aggregateProjectManagerArray(invoices).map((item) => {
                return {
                    label: item.projectManagerId === "" ? "" : users.find(user => user.id === item.projectManagerId).firstName + " " + users.find(user => user.id === item.projectManagerId).lastName,
                    value: item.projectManagerId
                }
            }))
        }

    }, [selectedInvoiceMonth, invoices, users])

    if (invoices.length > 0 && users.length > 0 && !loading && !usersLoading && role && company && !userPropertiesLoading && !clientsLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Projects" section="Billing Plan Tracker" showUserMenu='true' />

                            <hr></hr>
                            <br></br>


                            <div className="flex items-center">
                                <div className="w-full mx-auto m-5 px-3">
                                    <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8">
                                        
                                        <div className="pb-1" onClick={() => onClickOfInvoiceMonthSelector()}>
                                            <p className="text-xs">Invoice Month</p>
                                            <ReactSelect
                                                className={"MyDropdown"}
                                                classNamePrefix={"MyDropdown"}
                                                styles={styles}
                                                value={selectedInvoiceMonth}
                                                onMenuOpen={onMenuOpen}
                                                onChange={setSelectedInvoiceMonth}
                                                options={aggregateInvoiceMonthArray(invoices).map((item) => {
                                                    return {
                                                        label: item.invoiceMonth,
                                                        value: item.invoiceMonth
                                                    }
                                                })}
                                                 />
                                        </div>

                                        <div>
                                        <p className="text-xs">Department</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateDepartmentsArray(invoices).map((item) => {
                                                return {
                                                    label: item.department,
                                                    value: item.department
                                                }
                                            })}
                                            value={selectedDepartment}
                                            onChange={setSelectedDepartment}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Service Manager</p>
                                        {selectedInvoiceMonth !== "All" &&
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateServiceManagerArray(invoices.filter(item => item.invoiceMonth === selectedInvoiceMonth.value)).map((item) => {
                                                return {
                                                    label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                                                    value: item.serviceManager
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedServiceManager}
                                            onChange={setSelectedServiceManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                        }
                                        {selectedInvoiceMonth === "All" &&
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateServiceManagerArray(invoices).map((item) => {
                                                return {
                                                    label: item.serviceManager === "" ? "" : users.find(user => user.id === item.serviceManager).firstName + " " + users.find(user => user.id === item.serviceManager).lastName,
                                                    value: item.serviceManager
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedServiceManager}
                                            onChange={setSelectedServiceManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                        }
                                    </div>

                                        <div>
                                        <p className="text-xs">Service</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateServicesArray(invoices).map((item) => {
                                                return {
                                                    label: item.serviceDescription,
                                                    value: item.serviceDescription
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedService}
                                            onChange={setSelectedService}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Work Stage</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateWorkStagesArray(invoices).map((item) => {
                                                return {
                                                    label: item.workStage,
                                                    value: item.workStage
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedWorkStage}
                                            onChange={setSelectedWorkStage}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Confirmed Invoice</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={[
                                                //The label on the page was changed to "Confirmed Invoice" instead of "Draft Invoice", as such the dropdown labels have been reversed
                                                { value: 'NO', label: 'YES' },
                                                { value: 'YES', label: 'NO' }                                                
                                            ]}
                                            value={selectedInvoiceDraft}
                                            onChange={setSelectedInvoiceDraft}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">SM Approved</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={[
                                                { value: 'YES', label: 'YES' },
                                                { value: 'NO', label: 'NO' }
                                            ]}
                                            value={selectedOkToInvoice}
                                            onChange={setSelectedOkToInvoice}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Invoice Line Status</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateInvoiceLineStatusArray(invoices).map((item) => {
                                                return {
                                                    label: item.invoiceLineStatus,
                                                    value: item.invoiceLineStatus
                                                }
                                            })}
                                            value={selectedInvoiceLineStatus}
                                            onChange={setSelectedInvoiceLineStatus}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Manager</p>
                                        {selectedInvoiceMonth !== "All" &&
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectManagerArray(invoices.filter(item => item.invoiceMonth === selectedInvoiceMonth.value)).map((item) => {
                                                return {
                                                    label: item.projectManagerId === "" ? "" : users.find(user => user.id === item.projectManagerId).firstName + " " + users.find(user => user.id === item.projectManagerId).lastName,
                                                    value: item.projectManagerId
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedProjectManager}
                                            onChange={setSelectedProjectManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                        }

                                        {selectedInvoiceMonth === "All" &&
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectManagerArray(invoices).map((item) => {
                                                return {
                                                    label: item.projectManagerId === "" ? "" : users.find(user => user.id === item.projectManagerId).firstName + " " + users.find(user => user.id === item.projectManagerId).lastName,
                                                    value: item.projectManagerId
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedProjectManager}
                                            onChange={setSelectedProjectManager}
                                            labelledBy="Select"
                                        />
                                        </div>
                                        }

                                    </div>

                                        <div>
                                        <p className="text-xs">Project Name</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectNameArray(invoices).map((item) => {
                                                return {
                                                    label: item.projectName,
                                                    value: item.projectName
                                                }
                                            })}
                                            value={selectedProjectName}
                                            onChange={setSelectedProjectName}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Number</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectNumberArray(invoices).map((item) => {
                                                return {
                                                    label: item.projectNumber,
                                                    value: item.projectNumber
                                                }
                                            })}
                                            value={selectedProjectNumber}
                                            onChange={setSelectedProjectNumber}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                                                            
                                    <div>
                                        <p className="text-xs">Invoice Line Comments</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateInvoiceLineCommentsArray(invoices).map((item) => {
                                                return {
                                                    label: item.invoiceLineComments,
                                                    value: item.invoiceLineComments
                                                }
                                            })}
                                            value={selectedInvoiceLineComments}
                                            onChange={setSelectedInvoiceLineComments}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Invoice Issued</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={[
                                                { value: 'YES', label: 'YES' },
                                                { value: 'NO', label: 'NO' }
                                            ]}
                                            value={selectedInvoiceIssued}
                                            onChange={setSelectedInvoiceIssued}
                                            
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Service Status</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateServiceStatusArray(invoices).map((item) => {
                                                return {
                                                    label: item.serviceStatus,
                                                    value: item.serviceStatus
                                                }
                                            })}
                                            value={selectedServiceStatus}
                                            onChange={setSelectedServiceStatus}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Client PO</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateInvoiceCustPoArray(invoices).map((item) => {
                                                return {
                                                    label: item.invoiceCustPo,
                                                    value: item.invoiceCustPo
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedInvoiceCustPo}
                                            onChange={setSelectedInvoiceCustPo}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Project Client</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateProjectClientArray(invoices).map((item) => {
                                                return {
                                                    label: item.projectClientId === "" ? "" : clients.find(client => client.id === item.projectClientId).clientName,
                                                    value: item.projectClientId
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedProjectClientId}
                                            onChange={setSelectedProjectClientId}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Client to Invoice</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateClientToInvoiceArray(invoices).map((item) => {
                                                return {
                                                    label: item.serviceInvoiceClientId === "" ? "" : clients.find(client => client.id === item.serviceInvoiceClientId).clientName,
                                                    value: item.serviceInvoiceClientId
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedServiceInvoiceClientId}
                                            onChange={setSelectedServiceInvoiceClientId}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">Invoice Client</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateInvoiceClientArray(invoices).map((item) => {
                                                return {
                                                    label: item.invoiceClientId === "" ? "" : clients.find(client => client.id === item.invoiceClientId).clientName,
                                                    value: item.invoiceClientId
                                                }
                                            }).sort((item1, item2) => item1.label + item1.label < item2.label + item2.label ? -1 : 0)}
                                            value={selectedInvoiceClientId}
                                            onChange={setSelectedInvoiceClientId}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                            <p className="text-xs">From Invoice Date</p>
                                            <input className="text-xs border-2 p-2 w-full" type="date" placeholder="Enter Invoice Date Here" value={fromInvoiceDate} onChange={(e) => setFromInvoiceDate(e.target.value)} />
                                        </div>
                                        <div>
                                            <p className="text-xs">To Invoice Date</p>
                                            <input className="text-xs border-2 p-2 w-full" type="date" placeholder="Enter Invoice Date Here" value={toInvoiceDate} onChange={(e) => setToInvoiceDate(e.target.value)} />
                                        </div>

                                        <div>
                                        <p className="text-xs">QA Checked - Fees</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateBillingPlanAndFeesCheckedArray(invoices).map((item) => {
                                                return {
                                                    label: item.projectBillingPlanAndFeesChecked === "NO" ? "NO" : users.find(user => user.id === item.projectBillingPlanAndFeesChecked).firstName + " " + users.find(user => user.id === item.projectBillingPlanAndFeesChecked).lastName,
                                                    //label: item.projectBillingPlanAndFeesChecked,
                                                    value: item.projectBillingPlanAndFeesChecked
                                                }
                                            })}
                                            value={selectedProjectBillingPlanAndFeesChecked}
                                            onChange={setSelectedProjectBillingPlanAndFeesChecked}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div>
                                        <p className="text-xs">QA Checked - Appoint.</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateInvoicingCheckedArray(invoices).map((item) => {
                                                return {
                                                    label: item.projectInvoicingChecked === "NO" ? "NO" : users.find(user => user.id === item.projectInvoicingChecked).firstName + " " + users.find(user => user.id === item.projectInvoicingChecked).lastName,
                                                    //label: item.projectInvoicingChecked,
                                                    value: item.projectInvoicingChecked
                                                }
                                            })}
                                            value={selectedProjectInvoicingChecked}
                                            onChange={setSelectedProjectInvoicingChecked}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Invoice Client Check</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateInvoiceClientCheckArray(invoices).map((item) => {
                                                return {
                                                    label: item.invoiceClientCheck ? "TRUE" : "FALSE",
                                                    value: item.invoiceClientCheck
                                                }
                                            })}
                                            value={selectedInvoiceClientCheck}
                                            onChange={setSelectedInvoiceClientCheck}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Invoice Number</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateInvoiceNumbersArray(invoices).map((item) => {
                                                return {
                                                    label: item.invoiceNumber,
                                                    value: item.invoiceNumber
                                                }
                                            })}
                                            value={selectedInvoiceNumber}
                                            onChange={setSelectedInvoiceNumber}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Invoicing Notes</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateInvoicingNotesArray(invoices).map((item) => {
                                                return {
                                                    label: item.projectInvoicingNotes,
                                                    value: item.projectInvoicingNotes
                                                }
                                            })}
                                            value={selectedInvoicingNotes}
                                            onChange={setSelectedInvoicingNotes}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Invoice Paid</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={[
                                                { value: 'YES', label: 'YES' },
                                                { value: 'NO', label: 'NO' },
                                                { value: 'CREDIT', label: 'CREDIT' }
                                            ]}
                                            value={selectedInvoicePaid}
                                            onChange={setSelectedInvoicePaid}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-xs">Deal Name</p>
                                        <div className = "text-xs">
                                        <MultiSelect
                                            options={aggregateDealNameArray(invoices).map((item) => {
                                                return {
                                                    label: item.dealName,
                                                    value: item.dealName
                                                }
                                            })}
                                            value={selectedDealName}
                                            onChange={setSelectedDealName}
                                            labelledBy="Select"
                                        />
                                        </div>
                                    </div>

                                        <div className="pl-3">
                                            <p className="text-xs font-bold">Filters</p>
                                            <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br></br>

                            <hr></hr>

                            <br></br>

                            <div className="flex flex-col py-2">
                                <div className="overflow-x-auto items-holder-8A">
                                    <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                        <div className="shadow-md shadow-slate-300">

                                            <table className="objects-8A min-w-full divide-y divide-gray-200 table-fixed" style={{ width: '5340px' }}>

                                                <thead className="bg-pacific-blue-50 sticky top-0">
                                                    <tr>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'100px'}}> Department</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'120px'}}> <button className="uppercase" type="button" onClick={() => {setSortedField('projectNumber'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='projectNumber' && sortedOrder==='asc' ? 'Project Number ↑' : sortedField==='projectNumber' && sortedOrder==='desc' ? 'Project Number ↓' : 'Project Number ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'500px'}}> Project Name</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'300px'}}> Service</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'200px'}}> Work Stage</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'250px'}}> Service Manager</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'500px'}}> Invoice Line Description</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'150px'}}> <button className="uppercase" type="button" onClick={() => {setSortedField('invoiceValue'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='invoiceValue' && sortedOrder==='asc' ? 'Invoice Value ↑' : sortedField==='invoiceValue' && sortedOrder==='desc' ? 'Invoice Value ↓' : 'Invoice Value ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'200px'}}> <button className="uppercase" type="button" onClick={() => {setSortedField('invoiceLineStatus'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='invoiceLineStatus' && sortedOrder==='asc' ? 'Invoice Line Status ↑' : sortedField==='invoiceLineStatus' && sortedOrder==='desc' ? 'Invoice Line Status ↓' : 'Invoice Line Status ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'550px'}}> Invoice Line Comments</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'150px'}}> Service Status</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'250px'}}> Project Manager</th>
                                                        <th hidden scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'200px'}}> Service Management Fee</th>
                                                        <th hidden scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'200px'}}> Project Management Fee</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'120px'}}> <button className="uppercase" type="button" onClick={() => {setSortedField('invoiceDate'); sortedOrder==='asc' ? setSortedOrder('desc') : setSortedOrder('asc')}}>{sortedField ==='invoiceDate' && sortedOrder==='asc' ? 'Invoice Date ↑' : sortedField==='invoiceDate' && sortedOrder==='desc' ? 'Invoice Date ↓' : 'Invoice Date ⇅'}</button></th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'100px'}}> SM Approved</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'100px'}}> Confirmed Invoice</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'100px'}}> Invoice Issued</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'100px'}}> Invoice Number</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'100px'}}> Invoice Paid</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'100px'}}> Invoice Month</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'200px'}}> Client PO</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'300px'}}> Project Client</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'300px'}}> Client to Invoice</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'300px'}}> Invoice Client</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'300px'}}> Invoicing Notes</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'120px'}}> QA Checked - Fees</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'120px'}}> QA Checked - Appointment</th>
                                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{width:'120px'}}> Deal</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {invoicesFiltered.slice(firstRecord, lastRecord).map((invoice) => (
                                                        <tr key={invoice.uniqueKey}>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.department.slice(0, 2) === "01" ? "01. EAS" : invoice.department.slice(0, 2) === "02" ? "02. EAN" : invoice.department.slice(0, 2) === "03" ? "03. BP" : invoice.department.slice(0, 2) === "04" ? "04. LCA" : invoice.department.slice(0, 2) === "05" ? "05. WELL" : invoice.department.slice(0, 2) === "06" ? "06. ECO" : invoice.department.slice(0, 2) === "07" ? "07. ESG" : invoice.department.slice(0, 2) === "08" ? "08. FOOT" : invoice.department.slice(0, 2) === "09" ? "09. OFF" : invoice.department.slice(0, 2) === "13" ? "13. SDE" : invoice.department.slice(0, 2) === "20" ? "20. NCS" : invoice.department.slice(0, 5) === "Other" ? "Other" : "Unlisted"}</td>
                                                            <td onClick={() => viewProject(invoice.projectId)} className={invoices.filter(item => item.invoiceConsolidationCheck === invoice.invoiceConsolidationCheck && item.invoiceNumber !== invoice.invoiceNumber).length > 0 ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-yellow-100" : invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer" : "px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer bg-emerald-100"}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                                                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                                            </svg> {invoice.projectNumber}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 max-w-xs" : "px-4 md:px-6 py-2 max-w-xs bg-emerald-100"}>{invoice.projectName}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 max-w-xs" : "px-4 md:px-6 py-2 max-w-xs bg-emerald-100"}>{invoice.serviceDescription}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.workStage}</td>
                                                            {role.toString().slice(0,1) !== "2" && <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{users.find(user => user.id === invoice.serviceManager).firstName + ' ' + users.find(user => user.id === invoice.serviceManager).lastName}</td>}
                                                            {role.toString().slice(0,1) === "2" && <td onClick={() => { setShowModal(true); setValueToSendToModal(invoice.serviceManager); setFieldToSendToModal("invoiceLineServiceManager"); setObjectIdToSendToModal(invoice.invoiceId); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLineItems"); setCollectionArrayIdToSendToModal(invoice.invoiceLineItemId); setProjectIdToSendToModal(invoice.projectId) }} className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer whitespace-nowrap" : "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer whitespace-nowrap bg-emerald-100"}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg>{users.find(user => user.id === invoice.serviceManager).firstName + ' ' + users.find(user => user.id === invoice.serviceManager).lastName}</td>}
                                                            {/* <td className="px-4 md:px-6 py-2 w-300px whitespace-normal">{invoice.invoiceLineDescription}</td> */}
                                                            {invoice.okToInvoice === "NO" && role.toString().slice(0,1) === "2" ? <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer" : "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer bg-emerald-100"} onClick={() => { setShowModal(true); setValueToSendToModal(invoice.invoiceLineDescription); setFieldToSendToModal("invoiceLineDescription"); setObjectIdToSendToModal(invoice.invoiceId); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLineItems"); setCollectionArrayIdToSendToModal(invoice.invoiceLineItemId); setProjectIdToSendToModal(invoice.projectId) }}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg>{invoice.invoiceLineDescription}</td> :
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2" : "px-4 md:px-6 py-2 bg-emerald-100"} >{invoice.invoiceLineDescription}</td>
                                                            }
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.projectCurrency + " " + internationalNumberFormat.format(invoice.invoiceLineValue)}</td>
                                                            {invoice.invoiceIssued === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(invoice.invoiceLineStatus); setFieldToSendToModal("invoiceLineStatus"); setObjectIdToSendToModal(invoice.invoiceId); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLineItems"); setCollectionArrayIdToSendToModal(invoice.invoiceLineItemId); setProjectIdToSendToModal(invoice.projectId) }} className={invoice.draftInvoice === "YES" ? "p-2 whitespace-nowrap hover:cursor-pointer" : "p-2 whitespace-nowrap hover:cursor-pointer bg-emerald-100"}> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg><svg className={invoice.invoiceLineStatus === "Green" ? "inline-block fill-current text-lime" : invoice.invoiceLineStatus === "Red" ? "inline-block fill-current text-red-500" : invoice.invoiceLineStatus === "Amber" ? "inline-block fill-current text-amber-300" : "inline-block fill-current text-gray-500"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg><div className={invoice.draftInvoice === "YES" ? "inline-block pr-1" : "inline-block pr-1 bg-emerald-100"}>{invoice.invoiceLineStatus}</div></td> : <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 max-w-xs py-2" : "px-4 md:px-6 max-w-xs py-2 bg-emerald-100"}><svg className={invoice.invoiceLineStatus === "Green" ? "inline-block fill-current text-lime" : invoice.invoiceLineStatus === "Red" ? "inline-block fill-current text-red-500" : invoice.invoiceLineStatus === "Amber" ? "inline-block fill-current text-amber-300" : "inline-block fill-current text-gray-500"} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg><div className="inline-block pr-1">{invoice.invoiceLineStatus}</div></td>}
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer" : "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer bg-emerald-100"} onClick={() => { setShowModal(true); setValueToSendToModal(invoice.invoiceLineComments); setFieldToSendToModal("invoiceLineCommentsFromTracker"); setObjectIdToSendToModal(invoice.invoiceId); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLineItems"); setCollectionArrayIdToSendToModal(invoice.invoiceLineItemId); setProjectIdToSendToModal(invoice.projectId) }}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg><p className="max-h-[100px] text-ellipsis overflow-hidden">{invoice.invoiceLineComments}</p></td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 max-w-xs" : "px-4 md:px-6 py-2 max-w-xs bg-emerald-100"}>{invoice.serviceStatus}</td>
                                                            {role.toString().slice(0,1) !== "2" && <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}> {users.find(user => user.id === invoice.projectManagerId).firstName + ' ' + users.find(user => user.id === invoice.projectManagerId).lastName}</td>}
                                                            {role.toString().slice(0,1) === "2" && <td onClick={() => { setShowModal(true); setValueToSendToModal(invoice.projectManagerId); setFieldToSendToModal("invoiceLineProjectManager"); setObjectIdToSendToModal(invoice.invoiceId); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLineItems"); setCollectionArrayIdToSendToModal(invoice.invoiceLineItemId); setProjectIdToSendToModal(invoice.projectId) }} className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer whitespace-nowrap" : "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer whitespace-nowrap bg-emerald-100"}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg>{users.find(user => user.id === invoice.projectManagerId).firstName + ' ' + users.find(user => user.id === invoice.projectManagerId).lastName}</td>}
                                                            <td hidden className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.invoiceLineValue * 0.9).toFixed(2))}</td>
                                                            <td hidden className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.projectCurrency + " " + internationalNumberFormat.format((invoice.invoiceLineValue * 0.1).toFixed(2))}</td>
                                                            {invoice.invoiceIssued === "NO" ? <td onClick={() => { setShowModal(true); setValueToSendToModal(invoice.invoiceDate); setFieldToSendToModal("invoiceDate"); setObjectIdToSendToModal(invoice.invoiceId); setCollectionToSendToModal("invoices"); setCollectionArrayToSendToModal("invoiceLineItems"); setCollectionArrayIdToSendToModal(invoice.invoiceLineItemId); setProjectIdToSendToModal(invoice.projectId) }} className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer whitespace-nowrap" : "px-4 md:px-6 max-w-xs py-2 hover:cursor-pointer whitespace-nowrap bg-emerald-100"}><svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                </svg>{invoice.invoiceDate}</td> : <td className={invoice.draftInvoice === "YES" ? " max-w-xs py-2 text-right whitespace-nowrap" : "max-w-xs py-2 text-right whitespace-nowrap bg-emerald-100"}>{invoice.invoiceDate}</td>}
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.okToInvoice}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.draftInvoice==="YES" ? "NO" : "YES"}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.invoiceIssued}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.invoiceNumber}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.invoicePaid}</td>
                                                            <td className={invoices.filter(item => item.invoiceConsolidationCheck === invoice.invoiceConsolidationCheck && item.invoiceNumber !== invoice.invoiceNumber).length > 0 ? "px-4 md:px-6 py-2 whitespace-nowrap bg-yellow-100" : invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 whitespace-nowrap" : "px-4 md:px-6 py-2 whitespace-nowrap bg-emerald-100"}>{invoice.invoiceMonth}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 max-w-xs" : "px-4 md:px-6 py-2 max-w-xs bg-emerald-100"}>{invoice.invoiceCustPo}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 max-w-xs" : "px-4 md:px-6 py-2 max-w-xs bg-emerald-100"}>{invoice.projectClientId ? clients.find(client => client.id === invoice.projectClientId).clientName : ""}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 max-w-xs" : "px-4 md:px-6 py-2 max-w-xs bg-emerald-100"}>{invoice.serviceInvoiceClientId ? clients.find(client => client.id === invoice.serviceInvoiceClientId).clientName : ""}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 max-w-xs" : "px-4 md:px-6 py-2 max-w-xs bg-emerald-100"}>{invoice.clientName}</td>
                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 max-w-xs text-xs" : "px-4 md:px-6 py-2 max-w-xs text-xs bg-emerald-100"} >{invoice.projectInvoicingNotes}</td>
                                                            {invoice.projectBillingPlanAndFeesChecked !== "NO" ? <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2" : "px-4 md:px-6 py-2 bg-emerald-100"}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>{users.find(item => item.id === invoice.projectBillingPlanAndFeesChecked).firstName + ' ' + users.find(item => item.id === invoice.projectBillingPlanAndFeesChecked).lastName}</td> : <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6  py-2" : "px-4 md:px-6  py-2 bg-emerald-100"}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                            </svg>{invoice.projectBillingPlanAndFeesChecked}</td>}
                                                           
                                                            {invoice.projectInvoicingChecked !== "NO" ? <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2" : "px-4 md:px-6 py-2 bg-emerald-100"}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>{users.find(item => item.id === invoice.projectInvoicingChecked).firstName + ' ' + users.find(item => item.id === invoice.projectInvoicingChecked).lastName}</td> : <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6  py-2" : "px-4 md:px-6  py-2 bg-emerald-100"}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                            </svg>{invoice.projectInvoicingChecked}</td>}

                                                            <td className={invoice.draftInvoice === "YES" ? "px-4 md:px-6 py-2 max-w-xs text-xs" : "px-4 md:px-6 py-2 max-w-xs bg-emerald-100 text-xs"}>{invoice.dealName}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>

                                            <ModalUpdateValue role={role} objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={invoicesFiltered} invoiceProjectId={projectIdToSendToModal} onClose={() => setShowModal(false)} show={showModal} title="Update Invoice Line Item" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="grid gap-7 sm:grid-cols-3 ">
                                <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                                <div className="text-sm text-gray-500 text-center pt-2 ">{firstRecord + " to " + lastRecord + " of " + invoicesFiltered.length}</div>
                                <Button text="Next 100 >>" onClick={() => next100Records()}></Button>
                            </div>
                            {totalsToggle === "GBP" &&
                            <div><div className="flex items-center bg-slate-100">
                                <div className="container px-5 mx-auto my-5">
                                    <div className="grid gap-3 sm:grid-cols-2 ">


                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300" onDoubleClick={() => setTotalsToggle("Native")}>
                                            <div>
                                                <div className="text-sm text-gray-500 whitespace-nowrap">Eight Versa - Total on Billing Plan £</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="font-medium text-indigo-500 ">
                                                       
                                                        <p className="my-1 pb-5 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalConvertedIntoGBP) + " (" + totalPercentageInvoiced +"% invoiced)"}</p>
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalConvertedIntoGBPGreen) + " (" + totalPercentageInvoicedGreen +"% invoiced)"}</p>
                                                        <p className="my-1 text-[#d69e2e] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalConvertedIntoGBPAmber)}</p>
                                                        <p className="my-1 text-red-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalConvertedIntoGBPRed)}</p>
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalConvertedIntoGBPThirdParty) + " Third Party (" + totalPercentageInvoicedThirdParty +"% invoiced)"}</p>
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalConvertedIntoGBPSubContractor) + " Sub-contractor (" + totalPercentageInvoicedSubContractor +"% invoiced)"}</p>
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalConvertedIntoGBPProjectExpenses) + " Project Expenses (" + totalPercentageInvoicedProjectExpenses +"% invoiced)"}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300" onDoubleClick={() => setTotalsToggle("Native")}>
                                            <div>
                                                <div className="text-sm text-gray-500 whitespace-nowrap">NCS - Total on Billing Plan £</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="font-medium text-indigo-500 ">
                                                    <p className="my-1 pb-5 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalNCSConvertedIntoGBP) + " (" + totalNCSPercentageInvoiced +"% invoiced)"}</p>
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalNCSConvertedIntoGBPGreen) + " (" + totalNCSPercentageInvoicedGreen +"% invoiced)"}</p>
                                                        <p className="my-1 text-[#d69e2e] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalNCSConvertedIntoGBPAmber)}</p>
                                                        <p className="my-1 text-red-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalNCSConvertedIntoGBPRed)}</p>
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalNCSConvertedIntoGBPThirdParty) + " Third Party (" + totalNCSPercentageInvoicedThirdParty +"% invoiced)"}</p>
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalNCSConvertedIntoGBPSubContractor) + " Sub-contractor (" + totalNCSPercentageInvoicedSubContractor +"% invoiced)"}</p>
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(totalNCSConvertedIntoGBPProjectExpenses) + " Project Expenses (" + totalNCSPercentageInvoicedProjectExpenses +"% invoiced)"}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            </div>
                            }

                            {totalsToggle === "Native" &&
                            <div><div className="flex items-center bg-slate-100">
                                <div className="container px-5 mx-auto my-5">
                                    <div className="grid gap-3 sm:grid-cols-2 ">


                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300" onDoubleClick={() => setTotalsToggle("GBP")}>
                                            <div>
                                                <div className="text-sm text-gray-500 whitespace-nowrap">Eight Versa - Total on Billing Plan £ (€ to £ Rate:<input className="w-8" type="text" placeholder="Enter EUR/GBP Exchange Rate Here" value={conversionRateEURToGBP} onChange={(e) => setConversionRateEURToGBP(e.target.value)} />)</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="font-medium text-indigo-500 ">
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 pb-5 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 pb-5 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (0.00% invoiced)"}</p>
                                                        }
                                                        <p className="my-1 text-[#d69e2e] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Amber" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p>
                                                        <p className="my-1 text-red-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Red" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p>
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Third Party (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Third Party (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Sub-contractor (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Sub-contractor (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Project Expenses (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Project Expenses (0.00% invoiced)"}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-sm text-gray-500 whitespace-nowrap">Eight Versa - Total on Billing Plan €</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="font-medium text-indigo-500 ">
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 pb-5 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 pb-5 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (0.00% invoiced)"}</p>
                                                        }
                                                        <p className="my-1 text-[#d69e2e] whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Amber" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p>
                                                        <p className="my-1 text-red-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Red" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p>
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Third Party (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Third Party (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Sub-contractor (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Sub-contractor (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Project Expenses (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) !== "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Project Expenses (0.00% invoiced)"}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300" onDoubleClick={() => setTotalsToggle("GBP")}>
                                            <div>
                                                <div className="text-sm text-gray-500 whitespace-nowrap">NCS - Total on Billing Plan £ (€ to £ Rate:<input className="w-8" type="text" placeholder="Enter EUR/GBP Exchange Rate Here" value={conversionRateEURToGBP} onChange={(e) => setConversionRateEURToGBP(e.target.value)} />)</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="font-medium text-indigo-500 ">
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 pb-5 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 pb-5 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (0.00% invoiced)"}</p>
                                                        }
                                                        <p className="my-1 text-[#d69e2e] whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Amber" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p>
                                                        <p className="my-1 text-red-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Red" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p>
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Third Party (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Third Party (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Sub-contractor (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Sub-contractor (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Project Expenses (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">£ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "£" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Project Expenses (0.00% invoiced)"}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-sm text-gray-500 whitespace-nowrap">NCS - Total on Billing Plan €</div>
                                                <div className="flex items-center pt-1">
                                                    <div className="font-medium text-indigo-500 ">
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 pb-5 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 pb-5 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-[#38a169] whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Green" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " (0.00% invoiced)"}</p>
                                                        }
                                                        <p className="my-1 text-[#d69e2e] whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Amber" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p>
                                                        <p className="my-1 text-red-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Red" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p>
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Third Party (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Third Party Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Third Party (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Sub-contractor (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Sub-contractor Fee" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Sub-contractor (0.00% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) > 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Project Expenses (" + ((invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) / invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2) +"% invoiced)"}</p>
                                                        }
                                                        {invoicesFiltered.filter(item => item.draftInvoice === "NO" && item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0) === 0 &&
                                                        <p className="my-1 text-gray-500 whitespace-nowrap text-sm">€ {internationalNumberFormat.format(invoicesFiltered.filter(item => item.projectCurrency === "€" && item.invoiceLineStatus === "Project Expenses" && item.department.slice(0,2) === "20").map(item => item.invoiceLineValue).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) + " Project Expenses (0.00% invoiced)"}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            </div>
                            }  
                            <br></br>
                            <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(invoicesFiltered)} />
                            <Button color="bg-bondi-blue" text="Export Data to CSV for Xero" onClick={() => arrayToCsvForXero(invoicesFiltered)} />
                            <br></br>
                        </div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Projects" section="Billing Plan Tracker" showUserMenu='true' />
                        <br></br>
                        <hr></hr>
                        <br></br>
                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7">

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >

                                    </div>
                                </div>
                            </div>
                        </div>

                        <br></br>
                        <hr></hr>
                        <br></br>

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div role="status" className=" bg-white w-full p-4 space-y-4 border border-slate-200 divide-y divide-slate-200 rounded-2xl shadow-slate-300 shadow-md animate-pulse ">

                                        <div className="flex items-center justify-between">
                                            <div>
                                                <div className="h-5 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-5 bg-slate-300 rounded-full w-48"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )

    }

}

export default ProjectsTrackerInvoicing
