import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import Button from '../UI/Button'
import Modal from '../UI/Modal'
import { useNavigate, useMatch } from 'react-router-dom'
import FileUploadAndDownload from '../FileUploadAndDownload'
import { v4 as uuidv4 } from 'uuid'
import AuthService from '../../services/AuthService'


const AddOrUpdateOrganisationFootprintItem = () => {

    const match = useMatch('/addOrUpdateOrganisationFootprintItem/:organisationId/:footprintId/:footprintItemId')

    const navigate = useNavigate()

    const [showModal, setShowModal] = useState(false)

    const [carbonFactorLoading, setCarbonFactorsLoading] = useState(false)
    const [footprintsLoading, setFootprintsLoading] = useState(false)
    const [usersLoading, setUsersLoading] = useState(false)
    const [userId, setUserId] = useState(false)

    const [saveClicked, setSaveClicked] = useState(false)

    const [carbonEmissionFactors, setCarbonEmissionFactors] = useState([])

    const [footprint, setFootprint] = useState([])

    const [users, setUsers] = useState([])

    // const [organisationId, setOrganisationId] = useState('')
    // const [organisationFootprintRef, setOrganisationFootprintRef] = useState('')
    const [organisationFootprintYear, setOrganisationFootprintYear] = useState('')
    // const [organisationFootprintDate, setOrganisationFootprintDate] = useState('')
    // const [organisationFootprintItems, setOrganisationFootprintItems] = useState([])
    // const [organisationFootprintRecordConsolidation, setOrganisationFootprintRecordConsolidation] = useState('')
    const [organisationFootprintType, setOrganisationFootprintType] = useState('')
    //const [organisationFootprintNumberOfAttendees, setOrganisationFootprintNumberOfAttendees] = useState('')
    //const [organisationFootprintLocation, setOrganisationFootprintLocation] = useState('')
    // const [organisationFootprintNotes, setOrganisationFootprintNotes] = useState('')
    // const [organisationFootprintSummaryComments, setOrganisationFootprintSummaryComments] = useState('')
    // const [organisationFootprintScope1Comments, setOrganisationFootprintScope1Comments] = useState('')
    // const [organisationFootprintScope2Comments, setOrganisationFootprintScope2Comments] = useState('')
    // const [organisationFootprintScope3Comments, setOrganisationFootprintScope3Comments] = useState('')
    // const [organisationFootprintCertification, setOrganisationFootprintCertification] = useState('')
    // const [organisationFootprintConsultant, setOrganisationFootprintConsultant] = useState('')
    // const [organisationFootprintClientContact, setOrganisationFootprintClientContact] = useState('')
    // const [organisationFootprintVerifier, setOrganisationFootprintVerifier] = useState('')
    // const [organisationFootprintTargetYear, setOrganisationFootprintTargetYear] = useState('')
    // const [organisationFootprintOverallReductionTargets, setOrganisationFootprintOverallReductionTargets] = useState({})
    // const [organisationTurnoverGBP, setOrganisationTurnoverGBP] = useState(0)
    // const [organisationFootprintObjectives, setOrganisationFootprintObjectives] = useState('')
    // const [organisationFootprintScopeBoundaries, setOrganisationFootprintScopeBoundaries] = useState('')
    // const [organisationFootprintLevelOfAssurance, setOrganisationFootprintLevelOfAssurance] = useState('')
    // const [organisationFootprintMateriality, setOrganisationFootprintMateriality] = useState({"scope1And2:": "", "scope3AtLeast95Percent": "", "scope3CategoryExclusionAllowed": ""})
    // const [organisationFootprintVerificationActivitiesAndTechniques, setOrganisationFootprintVerificationActivitiesAndTechniques] = useState({"observation": "", "inquiry": "", "analyticalTesting": "", "confirmation": "", "recalculation": "", "examination": "", "retracing": "", "tracing": "", "controlTesting": "", "sampling": "", "estimateTesting": "", "crossChecking": "", "reconciliation": ""})

    const [id, setOrganisationFootprintItemId] = useState()
    const [itemDescription, setOrganisationFootprintItemDescription] = useState('')
    const [consumption, setOrganisationFootprintItemConsumption] = useState(0)
    const [unit, setOrganisationFootprintItemUnit] = useState('')
    const [emissionFactor, setOrganisationFootprintItEmissionFactor] = useState(0)
    const [carbonEmissions, setOrganisationFootprintItemCarbonEmissions] = useState(0)
    const [itemCategory, setOrganisationFootprintItemCategory] = useState('')
    const [itemSubCategory, setOrganisationFootprintItemSubCategory] = useState('')
    const [itemConsultantComment, setOrganisationFootprintItemConsultantComment] = useState('')
    const [itemConsultantCommentForDataCollection, setItemConsultantCommentForDataCollection] = useState('')
    const [itemCommentForReport, setItemCommentForReport] = useState('')
    const [itemTag, setOrganisationFootprintItemTag] = useState('')
    const [dataReviewConsultant, setDataReviewConsultant] = useState('')
    const [dataReviewProgress, setDataReviewProgress] = useState('')
    const [calculationConsultant, setCalculationConsultant] = useState('')
    const [calculationProgress, setCalculationProgress] = useState('')
    const [QAConsultant, setQAConsultant] = useState('')
    const [QAProgress, setQAProgress] = useState('')
    const [dataType, setDataType] = useState('Primary Data')
    const [basicUncertainty, setBasicUncertainty] = useState('')
    const [basicUncertaintyFactor, setBasicUncertaintyFactor] = useState('')

    const [basicUncertaintyFactors, setBasicUncertaintyFactors] = useState([])

    const [dataQualityTechnology, setDataQualityTechnology] = useState('')
    const [dataQualityTechnologyClassification, setDataQualityTechnologyClassification] = useState('')
    const [dataQualityTechnologyFactor, setDataQualityTechnologyFactor] = useState('')

    const [dataQualityTime, setDataQualityTime] = useState('')
    const [dataQualityTimeClassification, setDataQualityTimeClassification] = useState('')
    const [dataQualityTimeFactor, setDataQualityTimeFactor] = useState('')

    const [dataQualityGeography, setDataQualityGeography] = useState('')
    const [dataQualityGeographyClassification, setDataQualityGeographyClassification] = useState('')
    const [dataQualityGeographyFactor, setDataQualityGeographyFactor] = useState('')

    const [dataQualityCompleteness, setDataQualityCompleteness] = useState('')
    const [dataQualityCompletenessClassification, setDataQualityCompletenessClassification] = useState('')
    const [dataQualityCompletenessFactor, setDataQualityCompletenessFactor] = useState('')

    const [dataQualityReliability, setDataQualityReliability] = useState('')
    const [dataQualityReliabilityClassification, setDataQualityReliabilityClassification] = useState('')
    const [dataQualityReliabilityFactor, setDataQualityReliabilityFactor] = useState('')

    const [emissionsFactorSource, setEmissionsFactorSource] = useState('')

    const [emissionsFactorTechnology, setEmissionsFactorTechnology] = useState('')
    const [emissionsFactorTechnologyClassification, setEmissionsFactorTechnologyClassification] = useState('')
    const [emissionsFactorTechnologyFactor, setEmissionsFactorTechnologyFactor] = useState('')

    const [emissionsFactorTime, setEmissionsFactorTime] = useState('')
    const [emissionsFactorTimeClassification, setEmissionsFactorTimeClassification] = useState('')
    const [emissionsFactorTimeFactor, setEmissionsFactorTimeFactor] = useState('')

    const [emissionsFactorGeography, setEmissionsFactorGeography] = useState('')
    const [emissionsFactorGeographyClassification, setEmissionsFactorGeographyClassification] = useState('')
    const [emissionsFactorGeographyFactor, setEmissionsFactorGeographyFactor] = useState('')

    const [emissionsFactorCompleteness, setEmissionsFactorCompleteness] = useState('')
    const [emissionsFactorCompletenessClassification, setEmissionsFactorCompletenessClassification] = useState('')
    const [emissionsFactorCompletenessFactor, setEmissionsFactorCompletenessFactor] = useState('')

    const [emissionsFactorReliability, setEmissionsFactorReliability] = useState('')
    const [emissionsFactorReliabilityClassification, setEmissionsFactorReliabilityClassification] = useState('')
    const [emissionsFactorReliabilityFactor, setEmissionsFactorReliabilityFactor] = useState('')

    const [reductionControlCategory, setReductionControlCategory] = useState('')
    const [reductionCommentsOnControl, setReductionCommentsOnControl] = useState('')
    const [reductionTarget, setReductionTarget] = useState('')
    const [reductionTargetYear, setReductionTargetYear] = useState('')
    const [reductionMeasures, setReductionMeasures] = useState('')

    const [numberOfMonths, setNumberOfMonths] = useState(12)
    const [additionalEstimatedEmissions, setAdditionalEstimatedEmissions] = useState(0)

    const [filesUploaded, setFilesUploaded] = useState(0)

    const [submitted, setSubmitted] = useState('')
    const [verified, setVerified] = useState('')
    const [submissionComment, setSubmissionComment] = useState('')
    const [verificationComment, setVerificationComment] = useState('')

    const [newSubmissionComment, setNewSubmissionComment] = useState('')
    const [newVerificationComment, setNewVerificationComment] = useState('')
    const [newVerificationStatus, setNewVerificationStatus] = useState('')

    const [commentThread, setCommentThread] = useState([{id: "", commentDate: "", commentType: "", commentUser:"", comment: ""}])

    const [reductionProjection, setReductionProjection] = useState('0')
    const [reductionProjectionYear, setReductionProjectionYear] = useState('')

    const [showOnTracker, setShowOnTracker] = useState('')

    const [reductionTargetPercentageOverride, setReductionTargetPercentageOverride] = useState(0)

    const [footprintSubItems, setFootprintSubItems] = useState([])

    const [uncertaintyCategories, setUncertaintyCategories] = useState([])
    const [uncertaintyCategoriesLoading, setUncertaintyCategoriesLoading] = useState(false)

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const deleteCommentFromThread = (e) => {

        setCommentThread(commentThread.filter(item => item.id !== e))

    }

    const trimInput = (e) => {
        setOrganisationFootprintItemDescription(e.trim())
      }

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const removeCommas = (num) => num.toString().replace(/,/g, "");

    const handleChangeForConsumption = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setOrganisationFootprintItemConsumption(formatted);
            } else {
                setOrganisationFootprintItemConsumption(value);
            }
        } else {
            setOrganisationFootprintItemConsumption(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedConsumption = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setOrganisationFootprintItemConsumption(formatted);

        } else {
            setOrganisationFootprintItemConsumption("");
        }

    };

    const handleChangeForEmissions = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setOrganisationFootprintItemCarbonEmissions(formatted);
            } else {
                setOrganisationFootprintItemCarbonEmissions(value);
            }
        } else {
            setOrganisationFootprintItemCarbonEmissions(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedEmissions = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setOrganisationFootprintItemCarbonEmissions(formatted);

        } else {
            setOrganisationFootprintItemCarbonEmissions("");
        }

    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const submitItem = () => {
        const newDate = new Date()
        setSubmitted(formatDate(newDate))
    };

    const setSelectedFactor = (e, datasource) => {

        setOrganisationFootprintItEmissionFactor(Number(e))
        setEmissionsFactorSource(datasource)

    }

    const setUpdatedNumberOfFilesUploaded = async (e) => {

        setFilesUploaded(Number(e) + 1)

        var currentFootprint

        await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
        .then((res) => {
            currentFootprint = res.data
        })
        .catch((err) => {
            console.log(err);
        })

        currentFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).filesUploaded = Number(e) + 1

        await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, { ...currentFootprint })

    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setSaveClicked(true)

        if (showOnTracker !== "" && itemSubCategory !== "") {

            if (!isNaN(Number(removeCommas(carbonEmissions))) && !isNaN(Number(removeCommas(consumption)))) {
                
                if (newSubmissionComment !== "") {
                    commentThread.push({id: uuidv4(), commentDate: Date(), commentType: "Submission", commentUser: userId, comment: newSubmissionComment})
                }
            
                if (newVerificationComment !== "") {
                    commentThread.push({id: uuidv4(), commentDate: Date(), commentType: "Verification", commentUser: userId, comment: newVerificationComment})
                }

                var currentFootprint

                await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
                .then((res) => {
                    currentFootprint = res.data
                })
                .catch((err) => {
                    console.log(err);
                })

                updateForm({ ...currentFootprint }).then(res => { navigate(-1) })
        
            } else {

                window.alert("Please check that numeric values are valid")

            }

        } else {
            
            window.alert("Please check that all mandatory fields have been completed")
            setSaveClicked(false)

        }

    }



    const openHelperTool = async () => {

        var currentFootprint
        await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
        .then((res) => {
            currentFootprint = res.data
        })
        .catch((err) => {
            console.log(err);
        })

        updateForm({ ...currentFootprint }).then(res => { navigate(`/ViewOrganisationFootprintItemHelperTool/${match.params.organisationId}/${match.params.footprintId}/${id}`) })
    }

    const updateForm = async (form) => {
        if (match.params.footprintItemId !== "_add") {
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).id = id
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription = itemDescription
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = Number(removeCommas(consumption))
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit = unit
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor = emissionFactor
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = Number(removeCommas(carbonEmissions))
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemCategory = itemCategory
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory = itemSubCategory
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemConsultantComment = itemConsultantComment
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemConsultantCommentForDataCollection = itemConsultantCommentForDataCollection
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemCommentForReport = itemCommentForReport
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemTag = itemTag
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataReviewConsultant = dataReviewConsultant
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataReviewProgress = dataReviewProgress
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).calculationConsultant = calculationConsultant
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).calculationProgress = calculationProgress
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).QAConsultant = QAConsultant
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).QAProgress = QAProgress
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataType = dataType
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).basicUncertainty = basicUncertainty
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).basicUncertaintyFactor = basicUncertaintyFactor
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnology = dataQualityTechnology
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnologyClassification = dataQualityTechnologyClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnologyFactor = dataQualityTechnologyFactor

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTime = dataQualityTime
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTimeClassification = dataQualityTimeClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTimeFactor = dataQualityTimeFactor

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeography = dataQualityGeography
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeographyClassification = dataQualityGeographyClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeographyFactor = dataQualityGeographyFactor

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompleteness = dataQualityCompleteness
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompletenessClassification = dataQualityCompletenessClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompletenessFactor = dataQualityCompletenessFactor

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliability = dataQualityReliability
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliabilityClassification = dataQualityReliabilityClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliabilityFactor = dataQualityReliabilityFactor


            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorSource = emissionsFactorSource

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnology = emissionsFactorTechnology
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnologyClassification = emissionsFactorTechnologyClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnologyFactor = emissionsFactorTechnologyFactor

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTime = emissionsFactorTime
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTimeClassification = emissionsFactorTimeClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTimeFactor = emissionsFactorTimeFactor

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeography = emissionsFactorGeography
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeographyClassification = emissionsFactorGeographyClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeographyFactor = emissionsFactorGeographyFactor

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompleteness = emissionsFactorCompleteness
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompletenessClassification = emissionsFactorCompletenessClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompletenessFactor = emissionsFactorCompletenessFactor

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliability = emissionsFactorReliability
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliabilityClassification = emissionsFactorReliabilityClassification
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliabilityFactor = emissionsFactorReliabilityFactor


            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionControlCategory = reductionControlCategory
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionCommentsOnControl = reductionCommentsOnControl
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTarget = reductionTarget
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTargetYear = reductionTargetYear
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionMeasures = reductionMeasures

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).filesUploaded = filesUploaded

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted = submitted
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified = verified
            if (newSubmissionComment === "") {
                form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submissionComment = submissionComment
            } else {
                form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submissionComment = newSubmissionComment 
            }
            if (newVerificationComment === "") {
                form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verificationComment = verificationComment
            } else {
                form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verificationComment = newVerificationComment
            }
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionProjection = reductionProjection
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionProjectionYear = reductionProjectionYear
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).numberOfMonths = numberOfMonths
            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = additionalEstimatedEmissions

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTargetPercentageOverride = reductionTargetPercentageOverride

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).commentThread = commentThread

            form.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).showOnTracker = showOnTracker

        }

        //if _add then add new item object to organisationFootprintItems array
        if (match.params.footprintItemId === "_add") {
            form.organisationFootprintItems.push({ id, itemDescription, consumption: Number(removeCommas(consumption)), unit, emissionFactor, carbonEmissions: Number(removeCommas(carbonEmissions)), itemCategory, itemSubCategory, itemConsultantComment, itemConsultantCommentForDataCollection, itemCommentForReport, itemTag, dataReviewConsultant, dataReviewProgress, calculationConsultant, calculationProgress, QAConsultant, QAProgress, dataType, basicUncertainty, basicUncertaintyFactor, dataQualityTechnology, dataQualityTechnologyClassification, dataQualityTechnologyFactor, dataQualityTime, dataQualityTimeClassification, dataQualityTimeFactor, dataQualityGeography, dataQualityGeographyClassification, dataQualityGeographyFactor, dataQualityCompleteness, dataQualityCompletenessClassification, dataQualityCompletenessFactor, dataQualityReliability, dataQualityReliabilityClassification, dataQualityReliabilityFactor, emissionsFactorSource, emissionsFactorTechnology, emissionsFactorTechnologyClassification, emissionsFactorTechnologyFactor, emissionsFactorTime, emissionsFactorTimeClassification, emissionsFactorTimeFactor, emissionsFactorGeography, emissionsFactorGeographyClassification, emissionsFactorGeographyFactor, emissionsFactorCompleteness, emissionsFactorCompletenessClassification, emissionsFactorCompletenessFactor, emissionsFactorReliability, emissionsFactorReliabilityClassification, emissionsFactorReliabilityFactor, reductionControlCategory, reductionCommentsOnControl, reductionTarget, reductionTargetYear, reductionMeasures, filesUploaded, submitted, verified, submissionComment: newSubmissionComment, verificationComment: newVerificationComment, reductionProjection, reductionProjectionYear, footprintSubItems: [], numberOfMonths, additionalEstimatedEmissions, reductionTargetPercentageOverride, commentThread, showOnTracker })
        }
        
        await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, form)

        //get all subItems

        var newSubItems
        var subItemIndex

        await axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems/' + match.params.footprintItemId)
            .then((res) => {
                newSubItems = res.data

            })
            .catch((err) => {
                console.log(err);
            })

        if (newSubItems.length > 0) {

            for (subItemIndex = 0; subItemIndex < newSubItems.length; subItemIndex++) {
                newSubItems[subItemIndex].subCategory = itemSubCategory
            }

            await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems', newSubItems)
        }

    }

    const viewCarbonFactors = () => {
        setShowModal(true)
    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (showOnTracker === "NO") {

            setOrganisationFootprintItemTag("Validated")

        }

        if (showOnTracker === "YES" && match.params.footprintItemId === "_add") {

            setOrganisationFootprintItemTag("") 
        }

    }, [showOnTracker])


    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setCarbonFactorsLoading(true)
            setFootprintsLoading(true)
            setUncertaintyCategoriesLoading(true)
            setUsersLoading(true)

            if (role !== 3001 && role !== 6001 && role !== 6002) {

                axios.get(process.env.REACT_APP_API_URL + '/users')
                .then((res) => {
                    setUsers(res.data)
                    setUserId(res.data.find(user => user.emailId === AuthService.getCurrentUserName()).id)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUsersLoading(false);
                });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/users_internal_only')
                .then((res) => {
                    setUsers(res.data)
                    setUserId(res.data.find(user => user.emailId === AuthService.getCurrentUserName()).id)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUsersLoading(false);
                });

            }

            if (role !== 3001 && role !== 6001 && role !== 6002) {
                axios.get(process.env.REACT_APP_API_URL + '/carbon_factors')
                    .then((res) => {
                        setCarbonEmissionFactors(res.data)
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setCarbonFactorsLoading(false);
                    });
            } else {

                setCarbonFactorsLoading(false)
                setCarbonEmissionFactors(["EMPTY"])

            }

            axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_subItems/' + match.params.footprintItemId)
                .then((res) => {
                    setFootprintSubItems(res.data)
                })
                .catch((err) => {
                    console.log(err);
                })

            axios.get(process.env.REACT_APP_API_URL + '/select_options_footprints_uncertainty_categories')
                .then((res) => {
                    setUncertaintyCategories(res.data)

                    if (match.params.footprintItemId === "_add") {
                        setDataQualityTechnology(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityTechnology").ratingDescription)
                        setDataQualityTechnologyClassification("Very Good")
                        setDataQualityTechnologyFactor(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityTechnology").ratingValue)

                        setDataQualityTime(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityTime").ratingDescription)
                        setDataQualityTimeClassification("Very Good")
                        setDataQualityTimeFactor(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityTime").ratingValue)

                        setDataQualityGeography(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityGeography").ratingDescription)
                        setDataQualityGeographyClassification("Very Good")
                        setDataQualityGeographyFactor(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityGeography").ratingValue)

                        setDataQualityCompleteness(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityCompleteness").ratingDescription)
                        setDataQualityCompletenessClassification("Very Good")
                        setDataQualityCompletenessFactor(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityCompleteness").ratingValue)

                        setDataQualityReliability(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityReliability").ratingDescription)
                        setDataQualityReliabilityClassification("Very Good")
                        setDataQualityReliabilityFactor(res.data.find(item => item.classification === "Very Good" && item.field === "dataQualityReliability").ratingValue)

                        setEmissionsFactorTechnology(res.data.find(item => item.classification === "Very Good" && item.field === "emissionsFactorTechnology").ratingDescription)
                        setEmissionsFactorTechnologyClassification("Very Good")
                        setEmissionsFactorTechnologyFactor(res.data.find(item => item.classification === "Very Good" && item.field === "emissionsFactorTechnology").ratingValue)

                        setEmissionsFactorTime(res.data.find(item => item.classification === "Very Good" && item.field === "emissionsFactorTime").ratingDescription)
                        setEmissionsFactorTimeClassification("Very Good")
                        setEmissionsFactorTimeFactor(res.data.find(item => item.classification === "Very Good" && item.field === "emissionsFactorTime").ratingValue)

                        setEmissionsFactorGeography(res.data.find(item => item.classification === "Very Good" && item.field === "emissionsFactorGeography").ratingDescription)
                        setEmissionsFactorGeographyClassification("Very Good")
                        setEmissionsFactorGeographyFactor(res.data.find(item => item.classification === "Very Good" && item.field === "emissionsFactorGeography").ratingValue)

                        setEmissionsFactorCompleteness(res.data.find(item => item.classification === "Very Good" && item.field === "emissionsFactorCompleteness").ratingDescription)
                        setEmissionsFactorCompletenessClassification("Very Good")
                        setEmissionsFactorCompletenessFactor(res.data.find(item => item.classification === "Very Good" && item.field === "emissionsFactorCompleteness").ratingValue)

                        setEmissionsFactorReliability(res.data.find(item => item.classification === "Good" && item.field === "emissionsFactorReliability").ratingDescription)
                        setEmissionsFactorReliabilityClassification("Good")
                        setEmissionsFactorReliabilityFactor(res.data.find(item => item.classification === "Good" && item.field === "emissionsFactorReliability").ratingValue)

                        setBasicUncertainty("N/A")
                        setBasicUncertaintyFactor("1")
                    }

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUncertaintyCategoriesLoading(false);
                });

            setBasicUncertaintyFactors([{
                "activityOrEmissionCategory": "Thermal energy",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Electricity",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Semi-finished products",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Raw materials",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Transport services",
                "basicUncertaintyFactor": "2"
            }, {
                "activityOrEmissionCategory": "Waste treatment services",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Infrastructure",
                "basicUncertaintyFactor": "3"
            }, {
                "activityOrEmissionCategory": "CO2 emissions",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Methane emissions from combustion",
                "basicUncertaintyFactor": "1.5"
            }, {
                "activityOrEmissionCategory": "Methane emissions from agriculture",
                "basicUncertaintyFactor": "1.2"
            }, {
                "activityOrEmissionCategory": "N2O emissions from combustion",
                "basicUncertaintyFactor": "1.5"
            }, {
                "activityOrEmissionCategory": "N2O emissions from agriculture",
                "basicUncertaintyFactor": "1.4"
            }, {
                "activityOrEmissionCategory": "N/A",
                "basicUncertaintyFactor": "1"
            }]
            )

            axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId)
                .then((res) => {
                    setFootprint(res.data)
                    // setOrganisationId(res.data.organisationId)
                    // setOrganisationFootprintRef(res.data.organisationFootprintRef)
                    setOrganisationFootprintYear(res.data.organisationFootprintYear)
                    // setOrganisationFootprintDate(res.data.organisationFootprintDate)
                    // setOrganisationFootprintItems(res.data.organisationFootprintItems)
                    // setOrganisationFootprintRecordConsolidation(res.data.organisationFootprintRecordConsolidation)
                    setOrganisationFootprintType(res.data.organisationFootprintType)
                    //setOrganisationFootprintNumberOfAttendees(res.data.organisationFootprintNumberOfAttendees)
                    //setOrganisationFootprintLocation(res.data.organisationFootprintLocation)
                    // setOrganisationFootprintNotes(res.data.organisationFootprintNotes)
                    // setOrganisationFootprintSummaryComments(res.data.organisationFootprintSummaryComments)
                    // setOrganisationFootprintScope1Comments(res.data.organisationFootprintScope1Comments)
                    // setOrganisationFootprintScope2Comments(res.data.organisationFootprintScope2Comments)
                    // setOrganisationFootprintScope3Comments(res.data.organisationFootprintScope3Comments)
                    // setOrganisationFootprintCertification(res.data.organisationFootprintCertification)
                    // setOrganisationFootprintConsultant(res.data.organisationFootprintConsultant)
                    // setOrganisationFootprintClientContact(res.data.organisationFootprintClientContact)
                    // setOrganisationFootprintVerifier(res.data.organisationFootprintVerifier)
                    // setOrganisationFootprintTargetYear(res.data.organisationFootprintTargetYear)
                    // setOrganisationFootprintOverallReductionTargets(res.data.organisationFootprintOverallReductionTargets)
                    // setOrganisationTurnoverGBP(res.data.organisationTurnoverGBP)
                    // setOrganisationFootprintObjectives(res.data.organisationFootprintObjectives)
                    // setOrganisationFootprintScopeBoundaries(res.data.organisationFootprintScopeBoundaries)
                    // setOrganisationFootprintLevelOfAssurance(res.data.organisationFootprintLevelOfAssurance)
                    // setOrganisationFootprintMateriality(res.data.organisationFootprintMateriality)
                    // setOrganisationFootprintVerificationActivitiesAndTechniques(res.data.organisationFootprintVerificationActivitiesAndTechniques)

                    //if a scope item is being edited then set state for ItemID, Description etc for that scope item...
                    if (match.params.footprintItemId !== "_add") {
                        setOrganisationFootprintItemId(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).id)
                        setOrganisationFootprintItemDescription(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription)
                        // setOrganisationFootprintItemConsumption(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption)
                        setFormattedConsumption(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption)
                        setOrganisationFootprintItemUnit(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit)
                        setOrganisationFootprintItEmissionFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor)
                        setFormattedEmissions(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions)
                        setOrganisationFootprintItemCategory(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemCategory)
                        setOrganisationFootprintItemSubCategory(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory)
                        setOrganisationFootprintItemConsultantComment(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemConsultantComment)
                        setItemConsultantCommentForDataCollection(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemConsultantCommentForDataCollection)
                        setItemCommentForReport(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemCommentForReport)
                        setOrganisationFootprintItemTag(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemTag)
                        setDataReviewConsultant(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataReviewConsultant)
                        setDataReviewProgress(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataReviewProgress)
                        setCalculationConsultant(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).calculationConsultant)
                        setCalculationProgress(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).calculationProgress)
                        setQAConsultant(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).QAConsultant)
                        setQAProgress(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).QAProgress)
                        setDataType(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataType)
                        setBasicUncertainty(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).basicUncertainty)
                        setBasicUncertaintyFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).basicUncertaintyFactor)

                        setDataQualityTechnology(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnology)
                        setDataQualityTechnologyClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnologyClassification)
                        setDataQualityTechnologyFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnologyFactor)

                        setDataQualityTime(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTime)
                        setDataQualityTimeClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTimeClassification)
                        setDataQualityTimeFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTimeFactor)

                        setDataQualityGeography(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeography)
                        setDataQualityGeographyClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeographyClassification)
                        setDataQualityGeographyFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeographyFactor)

                        setDataQualityCompleteness(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompleteness)
                        setDataQualityCompletenessClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompletenessClassification)
                        setDataQualityCompletenessFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompletenessFactor)

                        setDataQualityReliability(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliability)
                        setDataQualityReliabilityClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliabilityClassification)
                        setDataQualityReliabilityFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliabilityFactor)

                        setEmissionsFactorSource(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorSource)


                        setEmissionsFactorTechnology(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnology)
                        setEmissionsFactorTechnologyClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnologyClassification)
                        setEmissionsFactorTechnologyFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnologyFactor)

                        setEmissionsFactorTime(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTime)
                        setEmissionsFactorTimeClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTimeClassification)
                        setEmissionsFactorTimeFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTimeFactor)

                        setEmissionsFactorGeography(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeography)
                        setEmissionsFactorGeographyClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeographyClassification)
                        setEmissionsFactorGeographyFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeographyFactor)

                        setEmissionsFactorCompleteness(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompleteness)
                        setEmissionsFactorCompletenessClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompletenessClassification)
                        setEmissionsFactorCompletenessFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompletenessFactor)

                        setEmissionsFactorReliability(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliability)
                        setEmissionsFactorReliabilityClassification(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliabilityClassification)
                        setEmissionsFactorReliabilityFactor(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliabilityFactor)


                        setReductionControlCategory(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionControlCategory)
                        setReductionCommentsOnControl(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionCommentsOnControl)
                        setReductionTarget(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTarget)
                        setReductionTargetYear(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTargetYear)
                        setReductionMeasures(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionMeasures)

                        setSubmitted(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted)
                        setVerified(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified)
                        setSubmissionComment(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submissionComment)
                        setVerificationComment(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verificationComment)
                        setFilesUploaded(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).filesUploaded)
                        setReductionProjection(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionProjection)
                        setReductionProjectionYear(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionProjectionYear)
                        setNumberOfMonths(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).numberOfMonths)
                        setAdditionalEstimatedEmissions(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions)
                        // setFootprintSubItems(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems)
                        setReductionTargetPercentageOverride(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTargetPercentageOverride)
                        setCommentThread(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).commentThread)
                        setShowOnTracker(res.data.organisationFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).showOnTracker)
                    } else {

                        setOrganisationFootprintItemId(uuidv4())
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setFootprintsLoading(false);
                });

        }

    }, [match.params.footprintId, match.params.footprintItemId, company, role, userPropertiesLoading])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            var oneMonthsActual = 0

            if (numberOfMonths < 12) {

                oneMonthsActual = (Number(removeCommas(consumption)) * Number(emissionFactor)) / numberOfMonths
                setAdditionalEstimatedEmissions(oneMonthsActual * (12 - numberOfMonths))

            } else {
                setAdditionalEstimatedEmissions(0)
            }

            // setOrganisationFootprintItemCarbonEmissions(Number(removeCommas(consumption)) * Number(emissionFactor))

            setFormattedEmissions(Number(removeCommas(consumption)) * Number(emissionFactor))

        }

    }, [consumption, emissionFactor, numberOfMonths, company, role, userPropertiesLoading])

    useEffect(() => {

        if (dataType === "Primary Data") {

            setEmissionsFactorTechnology("Data generated using the same technology")
            setEmissionsFactorTechnologyClassification("Very Good")
            setEmissionsFactorTechnologyFactor("1")

            setEmissionsFactorTime("Data with less than 3 years of difference")
            setEmissionsFactorTimeClassification("Very Good")
            setEmissionsFactorTimeFactor("1")

        }

        if (dataType === "Secondary Data") {

            setEmissionsFactorTechnology("Data generated using a similar but different technology")
            setEmissionsFactorTechnologyClassification("Good")
            setEmissionsFactorTechnologyFactor("1.2")

            setEmissionsFactorTime("Data with less than 10 years of difference")
            setEmissionsFactorTimeClassification("Fair")
            setEmissionsFactorTimeFactor("1.2")
            
        }

        if (dataType === "Financial Data") {

            setEmissionsFactorTechnology("Data generated using a different technology")
            setEmissionsFactorTechnologyClassification("Fair")
            setEmissionsFactorTechnologyFactor("1.5")

            setEmissionsFactorTime("Data with less than 6 years of difference")
            setEmissionsFactorTimeClassification("Good")
            setEmissionsFactorTimeFactor("1.1")
            
        }

    }, [dataType])

    if (carbonEmissionFactors.length > 0 && !userPropertiesLoading && role && company && !carbonFactorLoading && !footprintsLoading && !usersLoading && !uncertaintyCategoriesLoading && uncertaintyCategories.length > 0) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Organisations - Add / Edit Footprint Item" section="Organisation Footprint Item Details" url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>

                    <br></br>
                    {match.params.footprintItemId !== "_add" &&
                    <div className="flex items-center justify-between p-5 bg-white rounded-2xl shadow-md shadow-slate-200 border border-slate-200">
                        <div>
                            <div className="text-sm text-gray-500 ">Uploaded Documentary Evidence</div>
                            <div className="flex items-center pt-1">
                                <div className="text-xs font-medium text-indigo-500 w-11/12"><FileUploadAndDownload id={match.params.footprintItemId} onChange={setUpdatedNumberOfFilesUploaded} allowUploads={role === 4001 || role === 2004 || role === 6001 || role === 2001 ? true : false}/></div>
                            </div>
                        </div>
                        <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                            </svg>
                        </div>

                        {/* <input type="number" value={filesUploaded} onChange={(e) => setFilesUploaded(Number(e.target.value))} /> */}

                    </div>
                    }

                    {match.params.footprintItemId !== "_add" &&

                    <div className="flex items-center justify-between p-5 bg-white rounded-2xl shadow-md shadow-slate-200 border border-slate-200 mt-2">
                        <div>
                            <div className="text-sm text-gray-500 ">Internal Documents</div>
                            <div className="flex items-center pt-1">
                                <div className="text-xs font-medium text-indigo-500 w-11/12"><FileUploadAndDownload id={match.params.footprintItemId} allowUploads={role === 4001 || role === 2004 || role === 6001 || role === 2001 ? true : false} subFolder="internalDocs" allowDeletes={true}/></div>
                            </div>
                        </div>
                        <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                            </svg>
                        </div>

                        {/* <input type="number" value={filesUploaded} onChange={(e) => setFilesUploaded(Number(e.target.value))} /> */}

                    </div>
                    }

                    <div className="form-control-8A">
                        <label>Show on Data Collection Tracker</label>
                        <select disabled = {role === 5001 || role === 6002 || role === 2003 ? true : false} value={showOnTracker} onChange={(e) => setShowOnTracker(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Scope</label>
                            <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemCategory} onChange={(e) => {setOrganisationFootprintItemCategory(e.target.value); setOrganisationFootprintItemSubCategory("")}}>
                                <option value="">Please Select...</option>
                                <option value="1">Scope 1</option>
                                <option value="2">Scope 2</option>
                                <option value="3">Scope 3</option>
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Scope Sub Category</label>
                            {
                                {
                                    "": <div>
                                        <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemSubCategory} onChange={(e) => {setOrganisationFootprintItemSubCategory(e.target.value); setOrganisationFootprintItemDescription(e.target.value)}}>
                                            <option value="">Please Select...</option>
                                        </select>
                                    </div>,
                                    "1": <div>
                                        <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemSubCategory} onChange={(e) => {setOrganisationFootprintItemSubCategory(e.target.value); setOrganisationFootprintItemDescription(e.target.value)}}>
                                            <option value="">Please Select...</option>
                                            <option value="1.1 Stationary Combustion">1.1 Stationary Combustion</option>
                                            <option value="1.2 Mobile Combustion">1.2 Mobile Combustion</option>
                                            <option value="1.3 Refrigerants">1.3 Refrigerants</option>
                                        </select>
                                    </div>,
                                    "2": <div>
                                        <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemSubCategory} onChange={(e) => {setOrganisationFootprintItemSubCategory(e.target.value); setOrganisationFootprintItemDescription(e.target.value)}}>
                                            <option value="">Please Select...</option>
                                            <option value="2.1 Purchased Electricity">2.1 Purchased Electricity</option>
                                        </select>
                                    </div>,
                                    "3": <div>
                                        <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemSubCategory} onChange={(e) => {setOrganisationFootprintItemSubCategory(e.target.value); setOrganisationFootprintItemDescription(e.target.value)}}>
                                            <option value="" >Please Select...</option>
                                            <option value="3.1 Purchased Goods & Services">3.1 Purchased Goods & Services</option>
                                            <option value="3.2 Capital Goods">3.2 Capital Goods</option>
                                            <option value="3.3 Fuel & Energy">3.3 Fuel & Energy</option>
                                            <option value="3.4 Upstream Transportation & Distribution">3.4 Upstream Transportation & Distribution</option>
                                            <option value="3.5 Waste">3.5 Waste</option>
                                            <option value="3.6 Business Travel">3.6 Business Travel</option>
                                            <option value="3.7 Employee Commuting">3.7 Employee Commuting</option>
                                            <option value="3.8 Upstream Leased Assets">3.8 Upstream Leased Assets</option>
                                            <option value="3.9 Downstream Transportation & Distribution">3.9 Downstream Transportation & Distribution</option>
                                            <option value="3.10 Processing of Sold Products">3.10 Processing of Sold Products</option>
                                            <option value="3.11 Use of Sold Products">3.11 Use of Sold Products</option>
                                            <option value="3.12 End-of-Life Treatment of Sold Products">3.12 End-of-Life Treatment of Sold Products</option>
                                            <option value="3.13 Downstream Leased Assets">3.13 Downstream Leased Assets</option>
                                            <option value="3.14 Franchises">3.14 Franchises</option>
                                            <option value="3.15 Investments">3.15 Investments</option>
                                        </select>
                                    </div>
                                }[itemCategory]
                            }
                        </div>
                        <div className="form-control-8A">
                            <label>Footprint Item Description</label>
                            <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Footprint Item Description Here" value={itemDescription} onChange={(e) => setOrganisationFootprintItemDescription(e.target.value)} onBlur = {(e) => trimInput(e.target.value)} />
                        </div>
                        {footprintSubItems.length === 0 &&
                            <div>
                                <div className="form-control-8A">
                                    <label>Consumption</label>
                                    <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Consumption Here" value={consumption} onChange={handleChangeForConsumption} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Unit</label>
                                    {/* <input type="text" placeholder="Enter Unit Here" value={unit} onChange={(e) => setOrganisationFootprintItemUnit(e.target.value)} /> */}
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={unit} onChange={(e) => setOrganisationFootprintItemUnit(e.target.value)}>
                                    <option value="">Please Select...</option>
                                        <option value="kg">kg</option>
                                        <option value="tonnes">tonnes</option>
                                        <option value="kWh">kWh</option>
                                        <option value="miles">miles</option>
                                        <option value="km">km</option>
                                        <option value="passenger.km">passenger.km</option>
                                        <option value="tonne.km">tonne.km</option>
                                        <option value="litres">litres</option>
                                        <option value="cubic metres">cubic metres</option>
                                        <option value="square metres">square metres</option>
                                        <option value="GJ">GJ</option>
                                        <option value="GBP">GBP</option>
                                        <option value="EUR">EUR</option>
                                        <option value="USD">USD</option>
                                        <option value="unit">unit</option>
                                        <option value="other">other</option>
                                        <option value="various">various</option>
					                    <option value="kgCO2e">kgCO2e</option>
                                    </select>
                                </div>
                                {role !== 6001 && role !== 6002 && role !== 3001 &&
                                <Button color="black" text="Collect Carbon Factors from Database" onClick={() => viewCarbonFactors()} />
                                }
                                <div className="form-control-8A">
                                    <label>Emission Factor</label>
                                    <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="number" placeholder="Enter Emission Factor Here" value={emissionFactor} onChange={(e) => setOrganisationFootprintItEmissionFactor(Number(e.target.value))} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Emissions Factor Source</label>
                                    <textarea className="w-full border-slate-300 border-2" readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Emission Factor Source Here" value={emissionsFactorSource} onChange={(e) => setEmissionsFactorSource(e.target.value)} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Carbon Emissions (kgCO2e)</label>
                                    <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Carbon Emissions Here" value={carbonEmissions} onChange={handleChangeForEmissions} />
                                </div>
                                </div>
                        }
                        {footprintSubItems.length > 0 &&
                            <div>
                                {/* <div className="form-control-8A">
                                    <label>Consumption</label>
                                    <input className="bg-slate-200" readOnly={true} type="number" placeholder="Enter Consumption Here" value={consumption} onChange={(e) => setOrganisationFootprintItemConsumption(Number(e.target.value))} />
                                </div> */}
                                <div className="form-control-8A">
                                    <label>Carbon Emissions (kgCO2e)</label>
                                    <input className="bg-slate-200" readOnly={true} type="text" placeholder="Enter Carbon Emissions Here" value={carbonEmissions} onChange={handleChangeForEmissions} />
                                </div>
                                {match.params.footprintItemId !== "_add" &&
                                    <Button color="black" text="Helper Tool for Sub Items" onClick={() => openHelperTool()} />
                                }
                            </div>
                        }
                        {organisationFootprintType === "Actual" &&
                            <div>
                                <div className="form-control-8A">
                                    <label>Number of Months</label>
                                    {/* <input type="number" placeholder="Enter Carbon Emissions Here" value={numberOfMonths} onChange={(e) => setNumberOfMonths(Number(e.target.value))} /> */}
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={numberOfMonths} onChange={(e) => setNumberOfMonths(Number(e.target.value))}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                                <div className="form-control-8A">
                                    <label>Additional Estimated Emissions</label>
                                    <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="number" placeholder="Enter Carbon Emissions Here" value={additionalEstimatedEmissions} onChange={(e) => setAdditionalEstimatedEmissions(Number(e.target.value))} />
                                </div>
                            </div>
                        }
                        <div className="form-control-8A">
                            <label>Consultant Comment</label>
                            <textarea className="w-full border-slate-300 border-2" readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Consultant Comment Here" value={itemConsultantComment} onChange={(e) => setOrganisationFootprintItemConsultantComment(e.target.value)} />
                        </div>
                        {/* <div className="form-control-8A">
                        <label>Item Tag</label>
                        <input type="text" placeholder="Enter Tag Here" value={itemTag} onChange={(e) => setOrganisationFootprintItemTag(e.target.value)} />
                    </div> */}
                        {organisationFootprintType === "Actual (Baseline)" && (
                            <div>
                                <p className="font-bold text-xl">Reduction Plan:</p>
                                <div className="form-control-8A">
                                    <label>Control Category</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={reductionControlCategory} onChange={(e) => setReductionControlCategory(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="Full Control">Full Control</option>
                                        <option value="Partial Control">Partial Control</option>
                                        <option value="No Control">No Control</option>
                                        <option value="N/A">N/A</option>
                                    </select>
                                </div>
                                <div className="form-control-8A">
                                    <label>Reduction - Comments on Control</label>
                                    <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Comments on Control Here" value={reductionCommentsOnControl} onChange={(e) => setReductionCommentsOnControl(e.target.value)} />
                                </div>

                                {/* <div className="form-control-8A">
                            <label>Reduction - Target %</label>
                            <input type="text" placeholder="Enter Reduction Target % Here" value={reductionTarget} onChange={(e) => setReductionTarget(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Reduction - Target Year</label>
                            <input type="text" placeholder="Enter Reduction Target Year Here" value={reductionTargetYear} onChange={(e) => setReductionTargetYear(e.target.value)} />
                        </div> */}

                                <div className="form-control-8A">
                                    <label>Reduction Target Override % (use for intensity based target setting only)</label>
                                    <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="number" placeholder="Reduction Target Override" value={reductionTargetPercentageOverride} onChange={(e) => setReductionTargetPercentageOverride(Number(e.target.value))} />
                                </div>

                                <div className="form-control-8A">
                                    <label>Reduction - Forecast %</label>
                                    <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Reduction Forecast % Here" value={reductionProjection} onChange={(e) => setReductionProjection(e.target.value)} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Reduction - Forecast Year</label>
                                    <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Reduction Forecast Year Here" value={reductionProjectionYear} onChange={(e) => setReductionProjectionYear(e.target.value)} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Reduction - Measures</label>
                                    <input readOnly = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Reduction Measures Here" value={reductionMeasures} onChange={(e) => setReductionMeasures(e.target.value)} />
                                </div>
                            </div>
                        )}

                        {(organisationFootprintType === "Actual (Baseline)" || organisationFootprintType === "Actual") && (
                            <div>

                                <p className="font-bold text-xl">Uncertainty:</p>
                                <div className="form-control-8A">
                                    <label>Data Type</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataType} onChange={(e) => setDataType(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="Primary Data">Primary Data</option>
                                        <option value="Secondary Data">Secondary Data</option>
                                        <option value="Financial Data">Financial Data</option>
                                    </select>
                                </div>
                                {/* <div className="form-control-8A">
                                    <label>Basic Uncertainty</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={basicUncertainty} onChange={(e) => { setBasicUncertainty(e.target.value); setBasicUncertaintyFactor(basicUncertaintyFactors.find(item => item.activityOrEmissionCategory === e.target.value).basicUncertaintyFactor) }}>
                                        {basicUncertaintyFactors.map((item) => (
                                            <option key={item.activityOrEmissionCategory} value={item.activityOrEmissionCategory}>{item.activityOrEmissionCategory}</option>
                                        ))}
                                    </select>
                                </div> */}

                                {/* <div className="form-control-8A">
                                    <label>Data Quality - Technology</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityTechnology} onChange={(e) => { setDataQualityTechnology(e.target.value); setDataQualityTechnologyClassification(uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").find(item => item.ratingDescription === e.target.value).classification); setDataQualityTechnologyFactor(uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Data Quality - Time</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityTime} onChange={(e) => { setDataQualityTime(e.target.value); setDataQualityTimeClassification(uncertaintyCategories.filter(item => item.field === "dataQualityTime").find(item => item.ratingDescription === e.target.value).classification); setDataQualityTimeFactor(uncertaintyCategories.filter(item => item.field === "dataQualityTime").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityTime").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Data Quality - Geography</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityGeography} onChange={(e) => { setDataQualityGeography(e.target.value); setDataQualityGeographyClassification(uncertaintyCategories.filter(item => item.field === "dataQualityGeography").find(item => item.ratingDescription === e.target.value).classification); setDataQualityGeographyFactor(uncertaintyCategories.filter(item => item.field === "dataQualityGeography").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityGeography").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Data Quality - Completeness</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityCompleteness} onChange={(e) => { setDataQualityCompleteness(e.target.value); setDataQualityCompletenessClassification(uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").find(item => item.ratingDescription === e.target.value).classification); setDataQualityCompletenessFactor(uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Data Quality - Reliability</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityReliability} onChange={(e) => { setDataQualityReliability(e.target.value); setDataQualityReliabilityClassification(uncertaintyCategories.filter(item => item.field === "dataQualityReliability").find(item => item.ratingDescription === e.target.value).classification); setDataQualityReliabilityFactor(uncertaintyCategories.filter(item => item.field === "dataQualityReliability").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityReliability").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Emissions Factor - Technology</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorTechnology} onChange={(e) => { setEmissionsFactorTechnology(e.target.value); setEmissionsFactorTechnologyClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorTechnologyFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Emissions Factor - Time</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorTime} onChange={(e) => { setEmissionsFactorTime(e.target.value); setEmissionsFactorTimeClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorTimeFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div> */}

                                <div className="form-control-8A">
                                    <label>Geography</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorGeography} onChange={(e) => { setEmissionsFactorGeography(e.target.value); setEmissionsFactorGeographyClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorGeographyFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Completeness</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorCompleteness} onChange={(e) => { setEmissionsFactorCompleteness(e.target.value); setEmissionsFactorCompletenessClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorCompletenessFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                {/* <div className="form-control-8A">
                                    <label>Emissions Factor - Reliability</label>
                                    <select disabled = {submitted !== "" || verified === "Compliant" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorReliability} onChange={(e) => { setEmissionsFactorReliability(e.target.value); setEmissionsFactorReliabilityClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorReliabilityFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div> */}

                                <p className="font-bold text-xl">Verification:</p>

                                {submitted ==="" && role !== 5001 && role !== 6002 && role !== 2003 &&
                                <div className="pt-4">
                                    <Button text="Submit" onClick={() => submitItem()} />
                                </div>
                                }

                                {submitted !=="" &&

                                    <div className="form-control-8A">
                                        <div className="form-control-8A">
                                            <label>Submitted</label>
                                            <input readOnly = {role === 5001 || role === 6002 || role === 2003 ? true : false} type="date" placeholder="Enter Date Here" value={submitted} onChange={(e) => setSubmitted(e.target.value)} />
                                        </div>

                                    </div>

                                }

                                {/* {verified ==="" && submitted !=="" &&
                                <div className="pt-4">
                                    <Button text="Verify" onClick={() => verifyItem()} />
                                </div>
                                } */}

                                {/* {verified !=="" && submitted !=="" && */}
                                {/* <div className="form-control-8A">
                                    <div className="form-control-8A">
                                        <label>Verified</label>
                                        <input type="date" placeholder="Enter Date Here" value={verified} onChange={(e) => setVerified(e.target.value)} />
                                    </div>

                                </div> */}

                                <div className="form-control-8A">
                                    <label>Verification Status</label>
                                    <select disabled = {role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={verified} onChange={(e) => setVerified(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Response Required">Response Required</option>
                                        <option value="Recommendation">Recommendation</option>
                                        <option value="Non Compliant">Non Compliant</option>
                                    </select>
                                </div>
                                {/* } */}

                                {/* <div className="form-control-8A">
                                    <label>Last Submission Comment</label>
                                    <textarea className="bg-slate-100 w-full border-2" readOnly={true} type="text" placeholder="" value={submissionComment} onChange={(e) => setSubmissionComment(e.target.value)} />
                                </div>

                                <div className="form-control-8A">
                                    <label>Last Verification Comment</label>
                                    <textarea className="bg-slate-100 w-full border-2" readOnly={true} type="text" placeholder="" value={verificationComment} onChange={(e) => setVerificationComment(e.target.value)} />
                                </div> */}
                                {role !== 5001 && role !== 6002 && role !== 2003 &&
                                <div className="form-control-8A">
                                    <label>New Submission Comment</label>
                                    <textarea className="w-full border-2" type="text" placeholder="Enter New Comments Here" value={newSubmissionComment} onChange={(e) => setNewSubmissionComment(e.target.value)} />
                                </div>
                                }
                                {(role === 5001 || role === 6002 || role === 2003 || role === 2001) &&
                                <div className="form-control-8A">
                                    <label>New Verification Comment</label>
                                    <textarea className="w-full border-2" type="text" placeholder="Enter New Comments Here" value={newVerificationComment} onChange={(e) => setNewVerificationComment(e.target.value)} />
                                </div>
                                }

                                {/* {newVerificationComment !== "" &&
                                <div className="form-control-8A">
                                    <label>Verification Status</label>
                                    <select value={newVerificationStatus} onChange={(e) => setNewVerificationStatus(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Response Required">Response Required</option>
                                        <option value="Recommendation">Recommendation</option>
                                        <option value="Non Compliant">Non Compliant</option>
                                    </select>
                                </div>
                                } */}

                                <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl m-4">
                                    <table className="objects-8A min-w-full divide-y divide-gray-200">
    
                                        <thead className="bg-pacific-blue-50">
                                            <tr>
                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Date</th>
                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Type</th>
                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> User</th>
                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Comment</th>
                                            </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {commentThread.map((commentInThread, i) => (
                                                <tr key={commentInThread.id}>
                                                    <td className="px-4 md:px-6  py-2"> {commentInThread.commentDate.slice(0,21)} </td>
                                                    <td className="px-4 md:px-6  py-2 whitespace-nowrap"> {commentInThread.commentType} </td>
                                                    <td className="px-4 md:px-6  py-2"> {commentInThread.commentUser !== "" && users.find(user => user.id === commentInThread.commentUser) ? users.find(user => user.id === commentInThread.commentUser).firstName + " " + users.find(user => user.id === commentInThread.commentUser).lastName : ""} </td>
                                                    <td className="px-4 md:px-6  py-2"> {commentInThread.comment}{userId === commentInThread.commentUser && commentThread.length === i + 1 && verified !== "Compliant" ? <button className="hover:cursor-pointer float-right" onClick={() => { deleteCommentFromThread(commentInThread.id) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg></button> : <></>}</td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        )}

                        <input disabled = {saveClicked} type="submit" value={!saveClicked ? "Save" : "Please Wait..."} className="btn-8A btn-block-8A" />

                    </form>
                    {role !== 3001 && role !== 6001 && role !== 6002 &&
                        <Modal reportingYear={organisationFootprintYear} tableData={carbonEmissionFactors} onChange={setSelectedFactor} onClose={() => setShowModal(false)} show={showModal} title="Browse Emission Factor Database" />
                    }
                    </div>
            </div>
        )

    } else {

        return (
            <div className='flex bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                    <Header title="Organisations - Add / Edit Footprint Item" section="Organisation Footprint Item Details" url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateOrganisationFootprintItem