import { useEffect, useState } from "react";
import Header from "../UI/Header";
import axios from "axios";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import Sidebar from "../UI/Sidebar";
import CountUp from "react-countup";
import { MultiSelect } from "react-multi-select-component";
import ReactApexChart from "react-apexcharts";
import Button from '../UI/Button'
import { ExportToCsv } from 'export-to-csv'

import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from "chart.js";

ChartJS.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);


const DashboardClientProjects = () => {

    const [loading, setLoading] = useState(true);
    const [departmentsServicesActivitiesLoading, setDepartmentsServicesActivitiesLoading] = useState(true);
    const [clientsLoading, setClientsLoading] = useState(true);


    const [sidebarToggle, setSidebarToggle] = useState(false);

    const [numberOfRecordsToShow, setNumberOfRecordsToShow] = useState(50);

    const [servicesList, setServicesList] = useState([]);
    const [clients, setClients] = useState([]);

    const [services, setServices] = useState([]);

    const [servicesFullyFiltered, setServicesFullyFiltered] = useState([]);

    const [feesByDepartment, setFeesByDepartment] = useState([]);
    const [labelsByDepartment, setLabelsByDepartment] = useState([]);
    const [serviceCountByDepartment, setServiceCountByDepartment] = useState([]);

    const [feesByService, setFeesByService] = useState([]);
    const [labelsByService, setLabelsByService] = useState([]);
    const [serviceCount, setServiceCount] = useState([]);

    const [feesByClient, setFeesByClient] = useState([]);
    const [labelsByClient, setLabelsByClient] = useState([]);

    const [feesByStatus, setFeesByStatus] = useState([]);
    const [labelsByStatus, setLabelsByStatus] = useState([]);

    const [feesByClientBDStatus, setFeesByClientBDStatus] = useState([]);
    const [labelsByClientBDStatus, setLabelsByClientBDStatus] = useState([]);

    const [stackedLineChartFees, setStackedLineChartFees] = useState([]);
    const [stackedLineChartLabels, setStackedLineChartLabels] = useState([]);
    const [lineChartFees, setLineChartFees] = useState([]);
    const [lineChartFeesLive, setLineChartFeesLive] = useState([]);
    const [lineChartWinRates, setLineChartWinRates] = useState([]);
    const [lineChartLabels, setLineChartLabels] = useState([]);

    const [treeMapFees, setTreeMapFees] = useState([]);
    const [treeMapFeesByClient, setTreeMapFeesByClient] = useState([]);

    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedService, setSelectedService] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedClientBDStatus, setSelectedClientBDStatus] = useState([]);

    const internationalNumberFormat = new Intl.NumberFormat('en-US')

    const chartColours = [
        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
    ];
    const chartHoverColours = [
        "#10475C",
        "#007980",
        "#0E2343",
        "#96abb3",
        "#036860",
        "#0B4516",
        "#052827",
        "#3C6F26",
        "#3A4535",
        "#61703F",
        "#a16b1a",
        "#CEB691",
        "#2E211C",
        "#802741",
        "#732912",
        "#7A6009",
        "#4d6875",
        "#303372",
        "#3B2061",
        "#413F5C",
        "#481436",
        "#783378",
    ];

    const treeMapOptions = {

        plotOptions: {
            treemap: {
              enableShades: false
            }
          },

        legend: {
            show: true,
            itemMargin: {
                horizontal: 10,
                vertical: 10
            },
            fontFamily: 'TT Norms Pro',
            fontSize: 15,
            color: '#5d6a70'
        },
        chart: {
            height: 350,
            type: 'treemap'
        },
        dataLabels: {
            style: {
              fontSize: 16,
              fontFamily: "TT Norms Pro"
            },
          },
        title: {
            text: 'Fees per Service by Department GBP',
            align: 'center',
            style: {
                fontFamily: 'TT Norms Pro',
                fontSize: 18,
                color: '#5d6a70'
            },
        },

        colors: [

        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
        ]
    }

    const treeMapOptionsByClient = {

        plotOptions: {
            treemap: {
              enableShades: false
            }
          },

        legend: {
            show: true,
            itemMargin: {
                horizontal: 10,
                vertical: 10
            },
            fontFamily: 'TT Norms Pro',
            fontSize: 15,
            color: '#5d6a70'
        },
        chart: {
            height: 350,
            type: 'treemap'
        },
        dataLabels: {
            style: {
              fontSize: 16,
              fontFamily: "TT Norms Pro"
            },
          },
        title: {
            text: 'Fees per Department by Client GBP',
            align: 'center',
            style: {
                fontFamily: 'TT Norms Pro',
                fontSize: 18,
                color: '#5d6a70'
            },
        },
        colors: [

        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
        ]
    }

    const aggregateServices = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: val.service,
                });
            };
            return acc.sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0);
        }, []);
    };
    const aggregateDepartments = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department,
                });
            };
            return acc.sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : 0);
        }, []);
    };

    const aggregateClients = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientName === val.clientName);
            if (index === -1) {
                acc.push({
                    clientName: val.clientName,
                });
            };
            return acc;
        }, []);
    };

    const aggregateMonths = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceWinPendingMonth === val.serviceWinPendingMonth);
            if (index === -1) {
                acc.push({
                    serviceWinPendingMonth: val.serviceWinPendingMonth,
                });
            };
            return acc.sort((item1, item2) => item1.serviceWinPendingMonth < item2.serviceWinPendingMonth ? -1 : item1.serviceWinPendingMonth > item2.serviceWinPendingMonth ? 1 : 0);
        }, []);
    };

    const aggregateStatuses = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceStatus === val.serviceStatus);
            if (index === -1) {
                acc.push({
                    serviceStatus: val.serviceStatus,
                });
            };
            return acc.sort((item1, item2) => item1.serviceStatus < item2.serviceStatus ? -1 : item1.serviceStatus > item2.serviceStatus ? 1 : 0);
        }, []);
    };

    const aggregateClientBDStatuses = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.clientBDStatus === val.clientBDStatus);
            if (index === -1) {
                acc.push({
                    clientBDStatus: val.clientBDStatus,
                });
            };
            return acc.sort((item1, item2) => item1.clientBDStatus < item2.clientBDStatus ? -1 : item1.clientBDStatus > item2.clientBDStatus ? 1 : 0);
        }, []);
    };


    const dataChart5 = {
        labels: labelsByDepartment,
        datasets: [
            {
                data: feesByDepartment,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart6 = {
        labels: labelsByService.slice(0,numberOfRecordsToShow),
        datasets: [
            {
                data: feesByService.slice(0,numberOfRecordsToShow),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart6b = {
        labels: labelsByService,
        datasets: [
            {
                data: feesByService,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart7 = {
        labels: labelsByClient.slice(0,numberOfRecordsToShow),
        datasets: [
            {
                data: feesByClient.slice(0,numberOfRecordsToShow),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart7b = {
        labels: labelsByClient,
        datasets: [
            {
                data: feesByClient,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart8 = {
        labels: labelsByStatus,
        datasets: [
            {
                data: feesByStatus,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart8b = {
        labels: labelsByClientBDStatus,
        datasets: [
            {
                data: feesByClientBDStatus,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart9 = {
        labels: selectedMonth.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: stackedLineChartFees
    };

    const dataChart11 = {
        labels: selectedMonth.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: [{
            label: 'Quoted',
            data: lineChartFees.map(item => item.fees),
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: 'Quoted Average',
            data: lineChartFees.map(item => item.average),
            borderDash: [5, 5],
            fill: false,
            borderColor: 'rgb(255,0,0)',
            tension: 0.1
          }]
    };

    const dataChart12 = {
        labels: selectedMonth.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: [{
            label: 'Won (based on Live Date and includes all Live, Closed-completed and On-hold services)',
            data: lineChartFeesLive.map(item => item.fees),
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: 'Won Average',
            data: lineChartFeesLive.map(item => item.average),
            borderDash: [5, 5],
            fill: false,
            borderColor: 'rgb(255,0,0)',
            tension: 0.1
          }]
    };

    const dataChart13 = {
        labels: selectedMonth.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: [{
            label: 'Win Rate %',
            data: lineChartWinRates.map(item => item.rate),
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: 'Win Rate % Average',
            data: lineChartWinRates.map(item => item.average),
            borderDash: [5, 5],
            fill: false,
            borderColor: 'rgb(255,0,0)',
            tension: 0.1
          }]
    };

    const dataChart10 = {
        labels: labelsByDepartment,
        datasets: [
            {
                data: serviceCountByDepartment,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Fees per Department GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },

        },
        
    };

    
    const barOptionsHorizontal = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Fees per Department GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };


    const pieOptionsByService = {
        plugins: {
            title: {
                display: true,
                text: 'Fees per Service GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },
        },
    };

    const pieOptionsByClient = {
        plugins: {
            title: {
                display: true,
                text: 'Fees per Client GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },
        },
    };

    const pieOptionsByStatus = {
        plugins: {
            title: {
                display: true,
                text: 'Fees per Status GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },
        },
    };

    const pieOptionsByClientBDStatus = {
        plugins: {
            title: {
                display: true,
                text: 'Fees per Client BD Status GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },
        },
    };

    const pieOptionsServiceCountByDepartment = {
        plugins: {
            title: {
                display: true,
                text: 'Service Count per Department',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },
        },
    };

    
    const barOptionsHorizontalByService = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Fees per Service GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalByClient = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Fees per Client GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalByStatus = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Fees per Status GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalByClientBDStatus = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Fees per Client BD Status GBP',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'right',
                formatter: (value, ctx) => {

                    if (ctx.chart.data.labels[ctx.dataIndex] === "Tree") {
                        return "🌳"
                    }

                    if (ctx.chart.data.labels[ctx.dataIndex] === "Sapling") {
                        return "🪴"
                    }
                    
                    if (ctx.chart.data.labels[ctx.dataIndex] === "Seed") {
                        return "🌱"
                    }

                },
                display: 'auto',
                color: 'white',
                font: {
                    size: 50,
                  }

            },
            // datalabels: {
            //     display: false,
            // }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalServiceCountByDepartment = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Service Count per Department',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const lineBarOptions = {
        elements: {
            line: {
                tension: 0,
            },
            point: {
                radius: 2
            }
        },

        scales: {
            y: {
                min: 0,
                stacked: true,
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
        },
        plugins: {
            title: {
                display: true,
                text: 'Fees per Month',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    const lineOptions = {
        elements: {
            line: {
                tension: 0.5,
            },
            point: {
                radius: 2
            }
        },

        scales: {
            y: {
                min: 0,
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
        },
        plugins: {
            title: {
                display: true,
                text: 'Fees per Month',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            // legend: {
            //     display: false,
            // },
            datalabels: {
                display: true,
                formatter: function(value, context) {
                    return Math.round(value);
                }
            }
        },
    };

    const currentMonth = date => {

        const dateConverted = new Date(date)

        const yyyy = dateConverted.getFullYear();
        let mm = dateConverted.getMonth() + 1; // Months start at 0!

        if (mm < 10) mm = '0' + mm;

        const formattedMonth = yyyy + '-' + mm

        return formattedMonth
    }

    const arrayToCsv = (data) => {

        var dataToExport = [...data]

        dataToExport.forEach(a => delete a.activities)
        dataToExport.forEach(a => delete a.clientId)
        dataToExport.forEach(a => delete a.id)
        dataToExport.forEach(a => delete a.projectBillingPlanAndFeesChecked)
        dataToExport.forEach(a => delete a.projectId)
        dataToExport.forEach(a => delete a.projectInvoicingChecked)
        dataToExport.forEach(a => delete a.serviceInvoiceClient)
        dataToExport.forEach(a => delete a.serviceInvoiceClientContact)
        dataToExport.forEach(a => delete a.serviceManager)
        dataToExport.forEach(a => delete a.uniqueKey)

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Fee Analysis',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(dataToExport)

    }

    
    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/services_report')
            .then((res) => res.data)
            .then((res) => {
                setServices(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.projectNumber !== 0 && item.serviceCreatedMonth !== "2022-08").sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : 0))
                setSelectedDepartment(aggregateDepartments(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.projectNumber !== 0 && item.serviceCreatedMonth !== "2022-08")).map((item) => {
                    return {
                        label: item.department,
                        value: item.department
                    }
                }))
                setSelectedClient(aggregateClients(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.projectNumber !== 0 && item.serviceCreatedMonth !== "2022-08")).map((item) => {
                    return {
                        label: item.clientName,
                        value: item.clientName
                    }
                }))

                setSelectedMonth([{ value: currentMonth(Date()), label: currentMonth(Date()) }])

                setSelectedStatus(aggregateStatuses(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.projectNumber !== 0 && item.serviceCreatedMonth !== "2022-08")).map((item) => {
                    return {
                        label: item.serviceStatus,
                        value: item.serviceStatus
                    }
                }))

                setSelectedClientBDStatus(aggregateClientBDStatuses(res.filter((item) => item.projectToBeDeleted === "NO" && item.serviceToBeDeleted === "NO" && item.projectNumber !== 0 && item.serviceCreatedMonth !== "2022-08")).map((item) => {
                    return {
                        label: item.clientBDStatus,
                        value: item.clientBDStatus
                    }
                }))

                })
                
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/departments_services_activities')
            .then((res) => {
                setServicesList(aggregateServices(res.data).sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
                setSelectedService(aggregateServices(res.data).map((item) => {
                    return {
                        label: item.service,
                        value: item.service
                    }
                }))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setDepartmentsServicesActivitiesLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/clients')
            .then((response) => {
                setClients(response.data);
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setClientsLoading(false);
            });

    }, []);


    useEffect(() => {

            var filteredResByDepartments = []
            var filteredResByDepartmentsAndServices = []
            var filteredResByDepartmentsAndServicesAndClients = []
            var filteredResByDepartmentsAndServicesAndClientsAndStatuses = []
            var filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatuses = []
            var filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths = []
            var filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonthsLive = []
            var filteredRes = services
            var i, j
            var service, department, departmentAndService, clientAndDepartment, serviceStatus, clientBDStatus
            var fees = 0
            var allFees = []
            var clientName, month

            filteredResByDepartments = filteredRes.filter((itemA) => {
                return selectedDepartment.find((itemB) => {
                    return itemA.department === itemB.value;
                })
            })

            filteredResByDepartmentsAndServices = filteredResByDepartments.filter((itemA) => {
                return selectedService.find((itemB) => {
                    return itemA.service === itemB.value;
                })
            })

            filteredResByDepartmentsAndServicesAndClients = filteredResByDepartmentsAndServices.filter((itemA) => {
                return selectedClient.find((itemB) => {
                    return itemA.clientName === itemB.value;
                })
            })

            filteredResByDepartmentsAndServicesAndClientsAndStatuses = filteredResByDepartmentsAndServicesAndClients.filter((itemA) => {
                return selectedStatus.find((itemB) => {
                    return itemA.serviceStatus === itemB.value;
                })
            })

            filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatuses = filteredResByDepartmentsAndServicesAndClientsAndStatuses.filter((itemA) => {
                return selectedClientBDStatus.find((itemB) => {
                    return itemA.clientBDStatus === itemB.value;
                })
            })

            filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatuses.filter((itemA) => {
                return selectedMonth.find((itemB) => {
                    return itemA.serviceWinPendingMonth === itemB.value;
                })
            })

            filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonthsLive = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatuses.filter((itemA) => {
                return selectedMonth.find((itemB) => {
                    return itemA.serviceLiveMonth === itemB.value;
                })
            })

            setServicesFullyFiltered(filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths)


            for (j = 0; j < filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths.length; j++) {

                fees = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths[j].totalFee
                service = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths[j].service
                department = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths[j].department
                clientName = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths[j].clientName
                departmentAndService = department + " - " + service
                clientAndDepartment = clientName + " - " + department
                serviceStatus = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths[j].serviceStatus
                clientBDStatus = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths[j].clientBDStatus
                month = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonths[j].serviceWinPendingMonth

                allFees.push({
                    departmentAndService,
                    department,
                    service,
                    fees,
                    clientAndDepartment,
                    clientName,
                    serviceStatus,
                    clientBDStatus,
                    month
                })
                
            }

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            var result = [];
            allFees.reduce(function (res, value) {
                if (!res[value.departmentAndService]) {
                    res[value.departmentAndService] = {
                        departmentAndService: value.departmentAndService,
                        department: value.department,
                        service: value.service,
                        fees: 0,
                    };
                    result.push(res[value.departmentAndService]);
                }
                res[value.departmentAndService].fees +=
                    value.fees;
                return res;
            }, {});

            var treeMapArray = []
            var treeMapArrayFiltered = []

            var totalForThisDepartment = 0

            for (i = 0; i < selectedDepartment.length; i++) {

                totalForThisDepartment = 0

                department = selectedDepartment.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label)[i]

                treeMapArray.push({
                    name: department,
                    data: []
                })

                for (j = 0; j < result.length; j++) {

                    if (result[j].department === department && result[j].fees > 0.001) {

                        treeMapArray[i].data.push({
                            x: [treeMapArray[i].name, result[j].service],
                            y: Number(result[j].fees.toFixed(2))
                        },)

                        totalForThisDepartment = totalForThisDepartment + 1

                    }

                }

                if (totalForThisDepartment > 0) {
                    treeMapArrayFiltered.push(treeMapArray[i])
                }

            }

            setTreeMapFees(treeMapArrayFiltered)

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            
            result = [];
            allFees.reduce(function (res, value) {
                if (!res[value.clientAndDepartment]) {
                    res[value.clientAndDepartment] = {
                        clientAndDepartment: value.clientAndDepartment,
                        department: value.department,
                        clientName: value.clientName,
                        fees: 0,
                    };
                    result.push(res[value.clientAndDepartment]);
                }
                res[value.clientAndDepartment].fees +=
                    value.fees;
                return res;
            }, {});

            treeMapArray = []
            treeMapArrayFiltered = []

            var totalForThisClient = 0

            for (i = 0; i < selectedClient.length; i++) {

                totalForThisClient = 0

                clientName = selectedClient[i].value

                treeMapArray.push({
                    name: clientName,
                    data: []
                })

                for (j = 0; j < result.length; j++) {

                    if (result[j].clientName === clientName && result[j].fees > 0.001) {

                        treeMapArray[i].data.push({
                            x: [treeMapArray[i].name, result[j].department],
                            y: Number(result[j].fees.toFixed(2))
                        },)

                        totalForThisClient = totalForThisClient + 1

                    }

                }

                if (totalForThisClient > 0) {
                    treeMapArrayFiltered.push(treeMapArray[i])
                }

            }

            setTreeMapFeesByClient(treeMapArrayFiltered)

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            result = [];
            allFees.reduce(function (res, value) {
                if (!res[value.department]) {
                    res[value.department] = {
                        department: value.department,
                        fees: 0,
                        serviceCount: 0,
                    };
                    result.push(res[value.department]);
                }
                res[value.department].fees +=
                    value.fees;
                res[value.department].serviceCount = res[value.department].serviceCount + 1

                return res;
            }, {});

            result.sort((item1, item2) => item1.fees > item2.fees ? -1 : item1.fees < item2.fees ? 1 : 0)

            setFeesByDepartment(result.map(item => item.fees))
            setLabelsByDepartment(result.map(item => item.department))

            var departmentLabels = result.map(
                (item) => item.department
            );

            setServiceCountByDepartment(result.map(item => item.serviceCount))
            setServiceCount(allFees)

            result = [];
            allFees.reduce(function (res, value) {
                if (!res[value.service]) {
                    res[value.service] = {
                        service: value.service,
                        fees: 0,
                    };
                    result.push(res[value.service]);
                }
                res[value.service].fees +=
                    value.fees;
                return res;
            }, {});

            result.sort((item1, item2) => item1.fees > item2.fees ? -1 : item1.fees < item2.fees ? 1 : 0)

            setFeesByService(result.map(item => item.fees))
            setLabelsByService(result.map(item => item.service))

            result = [];
            allFees.reduce(function (res, value) {
                if (!res[value.clientName]) {
                    res[value.clientName] = {
                        clientName: value.clientName,
                        fees: 0,
                    };
                    result.push(res[value.clientName]);
                }
                res[value.clientName].fees +=
                    value.fees;
                return res;
            }, {});

            result.sort((item1, item2) => item1.fees > item2.fees ? -1 : item1.fees < item2.fees ? 1 : 0)

            setFeesByClient(result.map(item => item.fees))
            setLabelsByClient(result.map(item => item.clientName))

            result = [];
            allFees.reduce(function (res, value) {
                if (!res[value.serviceStatus]) {
                    res[value.serviceStatus] = {
                        serviceStatus: value.serviceStatus,
                        fees: 0,
                    };
                    result.push(res[value.serviceStatus]);
                }
                res[value.serviceStatus].fees +=
                    value.fees;
                return res;
            }, {});

            result.sort((item1, item2) => item1.fees > item2.fees ? -1 : item1.fees < item2.fees ? 1 : 0)

            setFeesByStatus(result.map(item => item.fees))
            setLabelsByStatus(result.map(item => item.serviceStatus))

            result = [];
            allFees.reduce(function (res, value) {
                if (!res[value.clientBDStatus]) {
                    res[value.clientBDStatus] = {
                        clientBDStatus: value.clientBDStatus,
                        fees: 0,
                    };
                    result.push(res[value.clientBDStatus]);
                }
                res[value.clientBDStatus].fees +=
                    value.fees;
                return res;
            }, {});

            result.sort((item1, item2) => item1.fees > item2.fees ? -1 : item1.fees < item2.fees ? 1 : 0)

            setFeesByClientBDStatus(result.map(item => item.fees))
            setLabelsByClientBDStatus(result.map(item => item.clientBDStatus))

            //Code here to create data for stacked line chart by department

            const feesPerMonthPerDepartment = [];
            var datasetsArray = [];
            var feesForThatDepartment = [];
            var feesPerMonthPerDepartmentSorted = [];
            var arrayOfMonthlyFees = [];

            for (i = 0; i < selectedMonth.length; i++) {

                month = selectedMonth[i].value

                // console.log(allFees)

                for (j = 0; j < departmentLabels.length; j++) {
                    department = departmentLabels[j];
                    fees = allFees
                        .filter((item) => item.department === department && item.month === month)
                        .map((item) => item.fees)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                        feesPerMonthPerDepartment.push({
                        month,
                        department,
                        fees,
                    });

                }

            }

            feesPerMonthPerDepartmentSorted =
            feesPerMonthPerDepartment.sort((item1, item2) =>
                    item1.month < item2.month ? -1 : item1.month > item2.month ? 1 : 0
                );

            for (i = 0; i < departmentLabels.length; i++) {
                department = departmentLabels[i];
                feesForThatDepartment =
                feesPerMonthPerDepartmentSorted.filter(
                        (item) => item.department === department
                    );

                arrayOfMonthlyFees = [];

                for (j = 0; j < selectedMonth.length; j++) {
                    month = selectedMonth.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label)[j];
                    fees = feesForThatDepartment
                        .filter((item) => item.month === month)
                        .map((item) => item.fees)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                        arrayOfMonthlyFees.push(fees);

                }

                datasetsArray.push({
                    label: department,
                    data: arrayOfMonthlyFees,
                    backgroundColor: chartColours,
                    borderColor: chartColours,
                    hoverBackgroundColor: chartHoverColours,
                    fill: true,
                    pointBorderColor: '#F1F6F8',
                    pointBackgroundColor: chartColours[i]
                });

            }

            setStackedLineChartLabels(selectedMonth.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label));
            setStackedLineChartFees(datasetsArray);
 
            //Code here to create data for simple line chart fees per month

            const feesPerMonth = [];

            var averageFees = allFees
                    .map((item) => item.fees)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0) / selectedMonth.length

            for (i = 0; i < selectedMonth.length; i++) {

                month = selectedMonth[i].value

                fees = allFees
                    .filter((item) => item.month === month && item.serviceStatus !== "Quote-QA" && item.serviceStatus !== "Quote-qualified" && item.serviceStatus !== "Closed-lost" && item.serviceStatus !== "Closed-cancelled")
                    .map((item) => item.fees)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    feesPerMonth.push(
                    {fees: fees, average: averageFees}
                );

            }

            // setLineChartLabels(selectedMonth.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label));
            setLineChartFees(feesPerMonth);


            //Code here to create data for simple line chart fees per month going live

            const feesPerMonthLive = [];

            var averageFeesLive = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonthsLive
                    .filter((item) => item.serviceStatus.slice(0,5) !== "Quote" && item.serviceStatus !== "Closed-lost" && item.serviceStatus !== "Closed-cancelled")
                    .map((item) => item.totalFee)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0) / selectedMonth.length

            for (i = 0; i < selectedMonth.length; i++) {

                month = selectedMonth[i].value

                fees = filteredResByDepartmentsAndServicesAndClientsAndStatusesAndClientBDStatusesAndMonthsLive
                    .filter((item) => item.serviceLiveMonth === month && item.serviceStatus.slice(0,5) !== "Quote" && item.serviceStatus !== "Closed-lost" && item.serviceStatus !== "Closed-cancelled")
                    .map((item) => item.totalFee)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    feesPerMonthLive.push(
                    {fees: fees, average: averageFeesLive}
                );

            }

            // setLineChartLabels(selectedMonth.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label));
            setLineChartFeesLive(feesPerMonthLive);

            const winRatePerMonthLive = [];
            var totalOfAllWinRatePercentages = 0
            var averageWinRate = 0

            for (i = 0; i < selectedMonth.length; i++) {

                totalOfAllWinRatePercentages = totalOfAllWinRatePercentages + (feesPerMonthLive[i].fees / feesPerMonth[i].fees) * 100

            }

            averageWinRate = totalOfAllWinRatePercentages / selectedMonth.length

            for (i = 0; i < selectedMonth.length; i++) {

                winRatePerMonthLive.push({rate: (feesPerMonthLive[i].fees / feesPerMonth[i].fees)*100, average: averageWinRate})

            }

            setLineChartWinRates(winRatePerMonthLive);

    }, [services, selectedDepartment, selectedMonth, selectedService, numberOfRecordsToShow, selectedClient, clients, selectedStatus, selectedClientBDStatus]);

    if (
        services.length > 0 &&
        !loading &&
        clients.length > 0 &&
        !clientsLoading
    ) {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-congress-blue"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-white"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="flex bg-slate-100 h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Eight Versa Service Fees" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' />

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-6">
                                    <div>
                                        <label>Department</label>
                                        <MultiSelect
                                            options={aggregateDepartments(services).map((item) => {
                                                return {
                                                    label: item.department,
                                                    value: item.department
                                                }
                                            })}
                                            value={selectedDepartment}
                                            onChange={setSelectedDepartment}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Service</label>
                                        <MultiSelect
                                            options={servicesList.map((item) => {
                                                return {
                                                    label: item.service,
                                                    value: item.service
                                                }
                                            })}
                                            value={selectedService}
                                            onChange={setSelectedService}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Client</label>
                                        <MultiSelect
                                            options={aggregateClients(services).map((item) => {
                                                return {
                                                    label: item.clientName,
                                                    value: item.clientName
                                                }
                                            })}
                                            value={selectedClient}
                                            onChange={setSelectedClient}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Month</label>
                                        <MultiSelect
                                            options={aggregateMonths(services).map((item) => {
                                                return {
                                                    label: item.serviceWinPendingMonth,
                                                    value: item.serviceWinPendingMonth
                                                }
                                            })}
                                            value={selectedMonth}
                                            onChange={setSelectedMonth}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Status</label>
                                        <MultiSelect
                                            options={aggregateStatuses(services).map((item) => {
                                                return {
                                                    label: item.serviceStatus,
                                                    value: item.serviceStatus
                                                }
                                            })}
                                            value={selectedStatus}
                                            onChange={setSelectedStatus}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div className="z-50">
                                        <label>Client BD Status</label>
                                        <MultiSelect
                                            options={aggregateClientBDStatuses(services).map((item) => {
                                                return {
                                                    label: item.clientBDStatus,
                                                    value: item.clientBDStatus
                                                }
                                            })}
                                            value={selectedClientBDStatus}
                                            onChange={setSelectedClientBDStatus}
                                            labelledBy="Select"
                                        />
                                    </div>
                                   
                                </div>
                            </div>
                        </div>


                        <div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid gap-2 sm:gap-7 sm:grid-cols-4">

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Total Fees: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={(
                                                        feesByDepartment.reduce(
                                                            (prev, curr) => Number(prev) + Number(curr),
                                                            0
                                                        )
                                                    ).toFixed(2)}
                                                    separator=","
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                    <div>
                                        <div className="text-sm text-gray-500 ">Services Count: </div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                start={0}
                                                end={serviceCount.length}
                                                separator=","
                                            /></div>
                                        </div>
                                    </div>
                                    <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                    </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <ReactApexChart options={treeMapOptions} series={treeMapFees} type="treemap" height={700} />
                        </div>

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <ReactApexChart options={treeMapOptionsByClient} series={treeMapFeesByClient} type="treemap" height={700} />
                        </div>

                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart5} options={pieOptions} />
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart5} options={barOptionsHorizontal} />
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart10} options={pieOptionsServiceCountByDepartment} />
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart10} options={barOptionsHorizontalServiceCountByDepartment} />
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-3">
                                    <div className="text-center">
                                        <br></br>
                                    <Button text="Top 10" onClick={() => setNumberOfRecordsToShow(10)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button  text="Top 20" onClick={() => setNumberOfRecordsToShow(20)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button text="Top 50" onClick={() => setNumberOfRecordsToShow(50)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {numberOfRecordsToShow===50 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart6b} options={pieOptionsByService} height={400}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart6} options={barOptionsHorizontalByService} height={400}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShow===20 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart6b} options={pieOptionsByService} height={300}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart6} options={barOptionsHorizontalByService} height={300}/>
                            </div>
                        </div>
                        }
                        {numberOfRecordsToShow===10 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart6b} options={pieOptionsByService} height={200}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart6} options={barOptionsHorizontalByService} height={200}/>
                            </div>
                        </div>
                        }

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-3">
                                    <div className="text-center">
                                        <br></br>
                                    <Button text="Top 10" onClick={() => setNumberOfRecordsToShow(10)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button  text="Top 20" onClick={() => setNumberOfRecordsToShow(20)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button text="Top 50" onClick={() => setNumberOfRecordsToShow(50)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {numberOfRecordsToShow===50 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart7b} options={pieOptionsByClient} height={400}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7} options={barOptionsHorizontalByClient} height={400}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShow===20 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart7b} options={pieOptionsByClient} height={300}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7} options={barOptionsHorizontalByClient} height={300}/>
                            </div>
                        </div>
                        }

                        {numberOfRecordsToShow===10 &&
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart7b} options={pieOptionsByClient} height={200}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7} options={barOptionsHorizontalByClient} height={200}/>
                            </div>
                        </div>
                        }

                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart8} options={pieOptionsByStatus} />
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart8} options={barOptionsHorizontalByStatus} />
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart8b} options={pieOptionsByClientBDStatus} />
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart8b} options={barOptionsHorizontalByClientBDStatus} />
                            </div>
                        </div>

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <Line data={dataChart9} options={lineBarOptions} height={100} />
                        </div>

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <Line data={dataChart11} options={lineOptions} height={100} />
                        </div>

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <Line data={dataChart12} options={lineOptions} height={100} />
                        </div>

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <Line data={dataChart13} options={lineOptions} height={100} />
                        </div>


                        {/* <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <Line data={dataChart7} options={lineOptions} height={100} />
                        </div> */}

                        <br></br>
                        <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(servicesFullyFiltered)} />

                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Eight Versa Service Fees" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' />
                        <p>Data is loading...</p>
                    </div>

                </div>
            </div>
        );
    }
};

export default DashboardClientProjects;
