import { useState, useEffect } from 'react'
import { useMatch, Navigate } from 'react-router-dom'
import axios from 'axios'
import ReactSelect from 'react-select'
import Moment from 'react-moment'
import AuthService from '../../services/AuthService'
import authHeader from '../../services/AuthHeader'
import { v4 as uuidv4 } from 'uuid'
import { MultiSelect } from "react-multi-select-component";

const ModalUpdateValue = (props) => {

    const [valueToUpdate, setValueToUpdate] = useState(props.valueToUpdate)
    const [valueToUpdateForReactSelect, setValueToUpdateForReactSelect] = useState({value: props.valueToUpdate, label: props.valueToUpdate})

    const match = useMatch('/viewClientProject/:projectId')

    const [invoicesToUpdate, setInvoicesToUpdate] = useState([])
    const [multipleInvoicesToUpdate, setMultipleInvoicesToUpdate] = useState([])    
    const [servicesToUpdate, setServicesToUpdate] = useState([])
    const [projectsToUpdate, setProjectsToUpdate] = useState([])
    const [eventFootprintsToUpdate, setEventFootprintsToUpdate] = useState([])
    const [organisationFootprintsToUpdate, setOrganisationFootprintsToUpdate] = useState([])
    const [BREEAMAssessmentsToUpdate, setBREEAMAssessmentsToUpdate] = useState([])
    const [timesheetsToUpdate, setTimesheetsToUpdate] = useState([])
    const [performanceGoalsToUpdate, setPerformanceGoalsToUpdate] = useState([])
    const [performanceTechSkillsToUpdate, setPerformanceTechSkillsToUpdate] = useState([])
    const [performanceTrackerExchangeRatesToUpdate, setPerformanceTrackerExchangeRatesToUpdate] = useState([])
    const [users, setUsers] = useState([])
    const [contacts, setContacts] = useState([])
    const [clients, setClients] = useState([])
    const [uncertaintyCategories, setUncertaintyCategories] = useState([])
    const [projects, setProjects] = useState([])
    const [projectsServices, setProjectsServices] = useState([])
    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [clientsLoading, setClientsLoading] = useState(true)
    const [contactsLoading, setContactsLoading] = useState(true)
    const [uncertaintyCategoriesLoading, setUncertaintyCategoriesLoading] = useState(true)
    const [serviceLiveDate, setServiceLiveDate] = useState('')
    const [serviceWinPendingDate, setServiceWinPendingDate] = useState('')
    const [serviceClosedDate, setServiceClosedDate] = useState('')
    const [projectLiveDate, setProjectLiveDate] = useState('')
    const [projectClosedDate, setProjectClosedDate] = useState('')
    
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    function formatDateWithTime(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes(),
            seconds = '' + d.getSeconds();

        if (month.length < 2)
            month = '0' + month;

        if (day.length < 2)
            day = '0' + day;

        if (hours.length < 2)
            hours = '0' + hours;
        if (minutes.length < 2)
            minutes = '0' + minutes;
        if (seconds.length < 2)
            seconds = '0' + seconds;

        return [year, month, day].join('-') +  " " + [hours, minutes, seconds].join(':');
    }

    const newDate = new Date()
    const [todaysDate, setTodaysDate] = useState(formatDate(newDate))

    const [calculatedText, setCalculatedText] = useState('')
    const [userId, setUserId] = useState(false)
    const [updateProjectManagerOnFutureInvoices, setUpdateProjectManagerOnFutureInvoices] = useState('YES')
    const [updateServiceManagerOnFutureInvoices, setUpdateServiceManagerOnFutureInvoices] = useState('YES')
    const [updateConsultantForAllItems, setUpdateConsultantForAllItems] = useState('NO')
    const [updateServiceStatusForAllItems, setUpdateServiceStatusForAllItems] = useState('NO')
    const [deals, setDeals] = useState([])
    const [dealName, setDealName] = useState('')
    const [invoices, setInvoices] = useState('')
    const [originalValueToUpdate, setOriginalValueToUpdate] = useState(props.valueToUpdate)
    const [serviceInvoiceClientContact, setServiceInvoiceClientContact] = useState('')
    const [projectClientContact, setProjectClientContact] = useState('')
    const [minimumLength, setMinimumLength] = useState(0)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')    
    const moment = require('moment');
    const [updateFromXero, setUpdateFromXero]= useState('NO')

    const [keyWordsCheck, setKeyWordsCheck] = useState([{keyWord: "train ", subCategory: "3.6 Business Travel"},
    {keyWord: " trains ", subCategory: "3.6 Business Travel"},
    {keyWord: "accomodation", subCategory: "3.6 Business Travel"},
    {keyWord: "Uber ", subCategory: "3.6 Business Travel"},
    {keyWord: "Inn ", subCategory: "3.6 Business Travel"},
    {keyWord: "Travel ", subCategory: "3.6 Business Travel"},
    {keyWord: "Tube ", subCategory: "3.6 Business Travel"},
    // {keyWord: " Room", subCategory: "3.6 Business Travel"},
    {keyWord: " prop ", subCategory: "#CHECK"},
    {keyWord: "Membership ", subCategory: "#CHECK"},
    {keyWord: "Recharge ", subCategory: "#CHECK"},
    {keyWord: " Car ", subCategory: "3.6 Business Travel"},
    {keyWord: " bus ", subCategory: "3.6 Business Travel"},
    {keyWord: "minibus", subCategory: "3.6 Business Travel"},
    {keyWord: " Cars", subCategory: "3.6 Business Travel"},
    {keyWord: "Car ", subCategory: "3.6 Business Travel"},
    {keyWord: "hotel", subCategory: "3.6 Business Travel"},
    {keyWord: " ibis ", subCategory: "3.6 Business Travel"},
    {keyWord: " Hilton ", subCategory: "3.6 Business Travel"},
    {keyWord: " Night", subCategory: "3.6 Business Travel"},
    {keyWord: "flight", subCategory: "3.6 Business Travel"},
    {keyWord: "Addison Lee", subCategory: "3.6 Business Travel"},
    {keyWord: "bike ", subCategory: "3.6 Business Travel"},
    {keyWord: "bikes  ", subCategory: "3.6 Business Travel"},
    {keyWord: "Mileage", subCategory: "3.6 Business Travel"},
    {keyWord: "Trainline", subCategory: "3.6 Business Travel"},
    {keyWord: "TfL", subCategory: "3.6 Business Travel"},
    {keyWord: "Postage", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Shipping Charge", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Royal Mail", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Production supplies ", subCategory: "#CHECK"},
    {keyWord: "Utilities", subCategory: "#CHECK"},
    {keyWord: "Utility", subCategory: "#CHECK"},
    {keyWord: "service charge", subCategory: "#CHECK"},
    {keyWord: "Machinery", subCategory: "#CHECK"},
    {keyWord: "Diesel", subCategory: "#CHECK"},
    {keyWord: "Deposit", subCategory: "#CHECK"},
    {keyWord: "Limousine", subCategory: "3.6 Business Travel"},
    {keyWord: "Black Lane", subCategory: "3.6 Business Travel"},
    {keyWord: "Blacklane", subCategory: "3.6 Business Travel"},
    // {keyWord: "Technology", subCategory: "#CHECK"},
    // {keyWord: " tech ", subCategory: "#CHECK"},
    {keyWord: "taxi", subCategory: "3.6 Business Travel"},
    {keyWord: "courier", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: "Delivery ", subCategory: "3.4 Upstream Transportation & Distribution"},
    {keyWord: " Rail ", subCategory: "3.6 Business Travel"},
    {keyWord: "Fuel", subCategory: "#CHECK"},
    {keyWord: "Petrol", subCategory: "#CHECK"},
    {keyWord: " Accom", subCategory: "3.6 Business Travel"},
    {keyWord: "Travel", subCategory: "3.6 Business Travel"},
    {keyWord: "Transport", subCategory: "3.6 Business Travel"},
    {keyWord: "Editing cost", subCategory: "#CHECK"},
    {keyWord: "Water Rate", subCategory: "#CHECK"},
    {keyWord: "Waste Water", subCategory: "#CHECK"},
    {keyWord: "Awards entry", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "License fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Parking", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "BBC Public Service Rights", subCategory: "#CHECK EXCLUDE"},
    {keyWord: " Tax ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Online charge", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Toll ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Bank charge", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "License", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Carbon offset", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Business Rate", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Admin Fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Wetransfer", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Deposit", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Refund", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Cancelled", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Cancellation", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Archive", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Business Charges", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Recycling", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Location Fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Flight Change", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Offset", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Overdraft", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Payroll", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Interest", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Debit", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Credit", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "ATM", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Handling Fee", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Donation", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Charity", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Entry", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Gas ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "Electricity", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "waste disposal", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "levies payable", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "levy", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "visa ", subCategory: "#CHECK EXCLUDE"},
    {keyWord: "exchange", subCategory: "#CHECK EXCLUDE"}  
    ]) 

    const basicUncertaintyFactors = [{
        "activityOrEmissionCategory": "Thermal energy",
        "basicUncertaintyFactor": "1.05"
    }, {
        "activityOrEmissionCategory": "Electricity",
        "basicUncertaintyFactor": "1.05"
    }, {
        "activityOrEmissionCategory": "Semi-finished products",
        "basicUncertaintyFactor": "1.05"
    }, {
        "activityOrEmissionCategory": "Raw materials",
        "basicUncertaintyFactor": "1.05"
    }, {
        "activityOrEmissionCategory": "Transport services",
        "basicUncertaintyFactor": "2"
    }, {
        "activityOrEmissionCategory": "Waste treatment services",
        "basicUncertaintyFactor": "1.05"
    }, {
        "activityOrEmissionCategory": "Infrastructure",
        "basicUncertaintyFactor": "3"
    }, {
        "activityOrEmissionCategory": "CO2 emissions",
        "basicUncertaintyFactor": "1.05"
    }, {
        "activityOrEmissionCategory": "Methane emissions from combustion",
        "basicUncertaintyFactor": "1.5"
    }, {
        "activityOrEmissionCategory": "Methane emissions from agriculture",
        "basicUncertaintyFactor": "1.2"
    }, {
        "activityOrEmissionCategory": "N2O emissions from combustion",
        "basicUncertaintyFactor": "1.5"
    }, {
        "activityOrEmissionCategory": "N2O emissions from agriculture",
        "basicUncertaintyFactor": "1.4"
    }, {
        "activityOrEmissionCategory": "N/A",
        "basicUncertaintyFactor": "1"
    }]

    function formatInvoiceDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    function stringToDate(_date,_format,_delimiter)
    {
        var formatLowerCase=_format.toLowerCase();
        var formatItems=formatLowerCase.split(_delimiter);
        var dateItems=_date.split(_delimiter);
        var monthIndex=formatItems.indexOf("mm");
        var dayIndex=formatItems.indexOf("dd");
        var yearIndex=formatItems.indexOf("yyyy");
        var month=parseInt(dateItems[monthIndex]);
        month-=1;
        var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
        return formatedDate;
    }

    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 575,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 575,
            fontSize: 13
        }),
    }

    const setConcatinatedString = (e, checked) => {

        if (checked) {
            if (!valueToUpdate.includes(e) && valueToUpdate.length === 0) {
                setValueToUpdate(e)
            }

            if (!valueToUpdate.includes(e) && valueToUpdate.length > 0) {
                setValueToUpdate(valueToUpdate + " | " + e)
            }

        }

        if (!checked) {

            var positionOfStringtoRemove
            var newString = valueToUpdate
            if (valueToUpdate.includes(e)) {
                positionOfStringtoRemove = newString.indexOf(e)

                if (!newString.includes("|")) {

                    newString = ""
                }

                if (newString.slice(positionOfStringtoRemove + e.length + 1, positionOfStringtoRemove + e.length + 2) === "|") {

                    newString = newString.slice(0, positionOfStringtoRemove) + newString.slice(positionOfStringtoRemove + e.length + 3, newString.length)

                }

                positionOfStringtoRemove = newString.indexOf(e)
                
                if (newString.slice(positionOfStringtoRemove - 2, positionOfStringtoRemove -1 ) === "|") {

                    newString = newString.slice(0, positionOfStringtoRemove - 3) + newString.slice(positionOfStringtoRemove + e.length, newString.length)

                }

                setValueToUpdate(newString)

            }  
        }

    }

    const onSubmit = async (e) => {
        e.preventDefault()

        var valueToUpdateUserIdOrNo
        var newServicesToUpdate
        var newOrganisationFootprintsToUpdate
        var newBREEAMAssessmentsToUpdate
        var newInvoicesToUpdate
        var newProjectToUpdate

        if (props.fieldToUpdate === "invoiceLineStatus") {

            newInvoicesToUpdate = {...invoicesToUpdate}

            if (invoicesToUpdate) {

                newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineStatus = valueToUpdate
                updateForm(newInvoicesToUpdate)
                setInvoicesToUpdate(newInvoicesToUpdate)

            }

            //This if statement is needed to determine if the edit call is being made from addOrEditClientProjectInvoice or listProjectClientInvoiceDetails
            if (props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate)) {

                props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineStatus = valueToUpdate

            } else {

                props.arrayToShow.find(item => item.id === props.objectToUpdate).invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineStatus = valueToUpdate
            }

            props.onClose()
        }

        if (props.fieldToUpdate === "invoiceLineProjectManager") {

            newInvoicesToUpdate = {...invoicesToUpdate}

            if (valueToUpdate !== "") {

                if (updateProjectManagerOnFutureInvoices==="YES"){  //update the PM on all future unissued invoices for this service ID

                    var newProjectsToUpdate

                    await axios.get(process.env.REACT_APP_API_URL+'/invoices_by_project/'+ newInvoicesToUpdate.projectId)
                    .then((res) => {
    
                        var invoice = res.data.filter((invoice) => stringToDate(invoice.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(newInvoicesToUpdate.invoiceDate,"YYYY-MM-DD","-") && invoice.invoiceIssued==="NO").sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)

                        invoice.forEach(invoice => {
                            var invoiceLines = invoice.invoiceLines
    
                            var recsToUpdate = 0
                            invoiceLines.forEach(invoiceLine => {

                                    invoiceLine.projectManager = valueToUpdate
                                    recsToUpdate++
                                    
                                });
    
                            if (recsToUpdate>0){  //only make the axios call when the value needs to change
                                axios.put(process.env.REACT_APP_API_URL + '/invoices/' + invoice.id, { ...invoice, invoiceLines: invoiceLines})
                            }
                            
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    })


                    var invoiceLine

                    for (invoiceLine = 0; invoiceLine < props.arrayToShow.filter(item => item.projectId === newInvoicesToUpdate.projectId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(newInvoicesToUpdate.invoiceDate,"YYYY-MM-DD","-") && item.invoiceIssued==="NO").length; invoiceLine++) {
                        props.arrayToShow.filter(item => item.projectId === newInvoicesToUpdate.projectId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(newInvoicesToUpdate.invoiceDate,"YYYY-MM-DD","-") && item.invoiceIssued==="NO")[invoiceLine].projectManagerId = valueToUpdate
                    }

                    await axios.get(process.env.REACT_APP_API_URL + '/projects/' + newInvoicesToUpdate.projectId)
                    .then((res) => {
                        newProjectsToUpdate = res.data
                    })
                    .catch((err) => {
                        console.log(err);
                    })
        
                    newProjectsToUpdate.projectManagerId = valueToUpdate
        
                    axios.put(process.env.REACT_APP_API_URL + '/projects/' + newInvoicesToUpdate.projectId, newProjectsToUpdate)
        
                    //updateForm({ projectId: servicesToUpdate.projectId, description: servicesToUpdate.description, serviceComments: servicesToUpdate.serviceComments, service: servicesToUpdate.service, department: servicesToUpdate.department, serviceStatus: servicesToUpdate.serviceStatus, serviceManager: valueToUpdate, workStage: servicesToUpdate.workStage, toBeDeleted: servicesToUpdate.toBeDeleted, activities: servicesToUpdate.activities, serviceCreatedDate: servicesToUpdate.serviceCreatedDate, serviceLiveDate: servicesToUpdate.serviceLiveDate, serviceClosedDate: servicesToUpdate.serviceClosedDate, dealNumber: servicesToUpdate.dealNumber, dealName: servicesToUpdate.dealName, customerPo: servicesToUpdate.customerPo, lineItemNumber: servicesToUpdate.lineItemNumber, changedFlag: servicesToUpdate.changedFlag, serviceInvoiceClient: servicesToUpdate.serviceInvoiceClient, serviceInvoiceClientContact: servicesToUpdate.serviceInvoiceClientContact })
                    setUpdateProjectManagerOnFutureInvoices("YES")
                    props.onClose()
    
                } else {

                    newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).projectManager = valueToUpdate

                    props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).projectManagerId = valueToUpdate

                    updateForm(newInvoicesToUpdate)

                    setUpdateProjectManagerOnFutureInvoices("YES")

                    props.onClose()

                }

            }
        }

        if (props.fieldToUpdate === "invoiceLineServiceManager") {

            newInvoicesToUpdate = {...invoicesToUpdate}

            if (valueToUpdate !== "") {

                if (updateServiceManagerOnFutureInvoices==="YES"){  //update the SM on all future unissued invoices for this service ID

                    await axios.get(process.env.REACT_APP_API_URL+'/invoices_by_project/'+ newInvoicesToUpdate.projectId)
                    .then((res) => {
    
                        var invoice = res.data.filter((invoice) => stringToDate(invoice.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(newInvoicesToUpdate.invoiceDate,"YYYY-MM-DD","-") && invoice.invoiceIssued==="NO").sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)
    
                        invoice.forEach(invoice => {
                            var invoiceLines = invoice.invoiceLines
    
                            var recsToUpdate = 0
                            invoiceLines.forEach(invoiceLine => {
                                if (invoiceLine.serviceId===newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).serviceId){
                                    invoiceLine.serviceManager = valueToUpdate
                                    recsToUpdate++
                                    }
                                });
    
                            if (recsToUpdate>0){  //only make the axios call when the value needs to change
                                axios.put(process.env.REACT_APP_API_URL + '/invoices/' + invoice.id, { ...invoice, invoiceLines: invoiceLines})
                            }
                            
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    })


                    var invoiceLine

                    for (invoiceLine = 0; invoiceLine < props.arrayToShow.filter(item => item.serviceId === newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(newInvoicesToUpdate.invoiceDate,"YYYY-MM-DD","-") && item.invoiceIssued==="NO").length; invoiceLine++) {

                        props.arrayToShow.filter(item => item.serviceId === newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).serviceId && stringToDate(item.invoiceDate,"YYYY-MM-DD","-") >= stringToDate(newInvoicesToUpdate.invoiceDate,"YYYY-MM-DD","-") && item.invoiceIssued==="NO")[invoiceLine].serviceManager = valueToUpdate
                    
                    }

                    await axios.get(process.env.REACT_APP_API_URL + '/projects_services/' + newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).serviceId)
                    .then((res) => {
                        newServicesToUpdate = res.data
                    })
                    .catch((err) => {
                        console.log(err);
                    })
        
                    newServicesToUpdate.serviceManager = valueToUpdate
        
                    axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).serviceId, newServicesToUpdate)
        
                    //updateForm({ projectId: servicesToUpdate.projectId, description: servicesToUpdate.description, serviceComments: servicesToUpdate.serviceComments, service: servicesToUpdate.service, department: servicesToUpdate.department, serviceStatus: servicesToUpdate.serviceStatus, serviceManager: valueToUpdate, workStage: servicesToUpdate.workStage, toBeDeleted: servicesToUpdate.toBeDeleted, activities: servicesToUpdate.activities, serviceCreatedDate: servicesToUpdate.serviceCreatedDate, serviceLiveDate: servicesToUpdate.serviceLiveDate, serviceClosedDate: servicesToUpdate.serviceClosedDate, dealNumber: servicesToUpdate.dealNumber, dealName: servicesToUpdate.dealName, customerPo: servicesToUpdate.customerPo, lineItemNumber: servicesToUpdate.lineItemNumber, changedFlag: servicesToUpdate.changedFlag, serviceInvoiceClient: servicesToUpdate.serviceInvoiceClient, serviceInvoiceClientContact: servicesToUpdate.serviceInvoiceClientContact })
                    setUpdateServiceManagerOnFutureInvoices("YES")
                    props.onClose()
    
                } else {

                    newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).serviceManager = valueToUpdate

                    props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).serviceManager = valueToUpdate

                    updateForm(newInvoicesToUpdate)

                    setUpdateServiceManagerOnFutureInvoices("YES")

                    props.onClose()

                }

            }
        }

        if (props.fieldToUpdate === "invoiceLineComments" || props.fieldToUpdate === "invoiceLineCommentsFromTracker") {

            newInvoicesToUpdate = {...invoicesToUpdate}

            newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineComments = valueToUpdate
            updateForm(newInvoicesToUpdate)

            //This if statement is needed to determine if the edit call is being made from addOrEditClientProjectInvoice or listProjectClientInvoiceDetails
            if (props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate)) {

                props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineComments = valueToUpdate

            } else {

                props.arrayToShow.find(item => item.id === props.objectToUpdate).invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineComments = valueToUpdate
            }

            props.onClose()
        }

        if (props.fieldToUpdate === "value") {

            newInvoicesToUpdate = {...invoicesToUpdate}

            if (newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate)) {

                newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).value = Number(valueToUpdate)
                updateForm(newInvoicesToUpdate)

            }

            //This if statement is needed to determine if the edit call is being made from addOrEditClientProjectInvoice or listProjectClientInvoiceDetails
            if (props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate)) {

                props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).value = Number(valueToUpdate)

            } else {

                props.arrayToShow.find(item => item.id === props.objectToUpdate).invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).value = Number(valueToUpdate)
            }

            props.onClose()
        }

        if (props.fieldToUpdate === "consultancyFee") {

            //factor =  new consultancyFee / current consultancyFee

            //loop through all fees (where costs === 0) and multiple number of hours and fee by factor

            newServicesToUpdate = {...servicesToUpdate}

            var currentConsultancyFee = newServicesToUpdate.activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0) - newServicesToUpdate.activities.map(item => item.cost).reduce((prev, curr) => Number(prev) + Number(curr), 0)

            if (currentConsultancyFee === 0) {
                window.alert("The current total fee and number of hours is 0.  Please set at least 1 hour against at least one activity before using this function to update the total fee")
                props.onClose()
                return
            }

            var factor = Number(valueToUpdate) / currentConsultancyFee

            
            var activity

            for (activity = 0; activity < newServicesToUpdate.activities.filter(item => item.cost === 0).length; activity++) {

                newServicesToUpdate.activities.filter(item => item.cost === 0)[activity].fee = newServicesToUpdate.activities.filter(item => item.cost === 0)[activity].fee * factor
                newServicesToUpdate.activities.filter(item => item.cost === 0)[activity].estimatedHours = newServicesToUpdate.activities.filter(item => item.cost === 0)[activity].estimatedHours * factor

                props.arrayToShow.find(item => item.id === props.objectToUpdate).activities.filter(item => item.cost === 0)[activity].fee = props.arrayToShow.find(item => item.id === props.objectToUpdate).activities.filter(item => item.cost === 0)[activity].fee * factor
                props.arrayToShow.find(item => item.id === props.objectToUpdate).activities.filter(item => item.cost === 0)[activity].estimatedHours = props.arrayToShow.find(item => item.id === props.objectToUpdate).activities.filter(item => item.cost === 0)[activity].estimatedHours * factor

            }

            await updateForm(newServicesToUpdate)

            props.onClose()
        }

        if (props.fieldToUpdate === "estimatedHours") {

            newServicesToUpdate = {...servicesToUpdate}

            if (newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate)) {

                newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate).estimatedHours = Number(valueToUpdate)
                newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate).fee = Number((Number(valueToUpdate) * newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate).hourlyRate).toFixed(2))
                await updateForm(newServicesToUpdate)

                props.arrayToShow.find(item => item.activityId === props.arrayIdToUpdate).estimatedHours = Number(valueToUpdate)
                props.arrayToShow.find(item => item.activityId === props.arrayIdToUpdate).fee = Number((Number(valueToUpdate) * newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate).hourlyRate).toFixed(2))
    
            }

            props.onClose()
        }

        if (props.fieldToUpdate === "cost") {

            newServicesToUpdate = {...servicesToUpdate}

            if (newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate)) {

                newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate).cost = Number(valueToUpdate)
                newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate).fee = Number(valueToUpdate)
                await updateForm(newServicesToUpdate)
                props.arrayToShow.find(item => item.activityId === props.arrayIdToUpdate).cost = Number(valueToUpdate)
                props.arrayToShow.find(item => item.activityId === props.arrayIdToUpdate).fee = Number(valueToUpdate)

            }

            props.onClose()

        }

        if (props.fieldToUpdate === "costCategory") {

            newServicesToUpdate = {...servicesToUpdate}

            if (newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate)) {

                newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate).costCategory = valueToUpdate
                await updateForm(newServicesToUpdate)
                props.arrayToShow.find(item => item.activityId === props.arrayIdToUpdate).costCategory = valueToUpdate

            }

            props.onClose()

        }

        if (props.fieldToUpdate === "activityComplete") {

            newServicesToUpdate = {...servicesToUpdate}

            if (newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate)) {

                newServicesToUpdate.activities.find(item => item.activityId === props.arrayIdToUpdate).activityComplete = valueToUpdate
                await updateForm(newServicesToUpdate)
                props.arrayToShow.find(item => item.activityId === props.arrayIdToUpdate).activityComplete = valueToUpdate

            }

            props.onClose()

        }

        if (props.fieldToUpdate === "invoiceLineDescription") {

            newInvoicesToUpdate = {...invoicesToUpdate}

            if (newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate)) {

                newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineDescription = valueToUpdate
                updateForm(newInvoicesToUpdate)

            }

            //This if statement is needed to determine if the edit call is being made from addOrEditClientProjectInvoice or listProjectClientInvoiceDetails
            if (props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate)) {

                props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineDescription = valueToUpdate

            } else {

                props.arrayToShow.find(item => item.id === props.objectToUpdate).invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineDescription = valueToUpdate
            }

            props.onClose()
        }

        if (props.fieldToUpdate === "invoiceNumber") {

            // Check that the service can be added to the selected invoice!
            
            var currInvoiceId, currClientId, currInvoiceCustPO, currInvoiceContactId
            var newInvoiceId, newClientId, newInvoiceCustPO, newInvoiceContactId, newInvoiceLines, newClientName

            currInvoiceId = props.arrayToShow.filter(invoice => invoice.id===props.valueToUpdate)[0].id
            currClientId = props.arrayToShow.filter(invoice => invoice.id===props.valueToUpdate)[0].clientId
            currInvoiceCustPO = props.arrayToShow.filter(invoice => invoice.id===props.valueToUpdate)[0].invoiceCustPo
            currInvoiceContactId = props.arrayToShow.filter(invoice => invoice.id===props.valueToUpdate)[0].invoiceContactId

            newInvoiceId = props.arrayToShow.filter(invoice => invoice.id===valueToUpdate)[0].id
            newInvoiceLines = props.arrayToShow.filter(invoice => invoice.id===valueToUpdate)[0].invoiceLines
            newClientId = props.arrayToShow.filter(invoice => invoice.id===valueToUpdate)[0].clientId
            newInvoiceCustPO = props.arrayToShow.filter(invoice => invoice.id===valueToUpdate)[0].invoiceCustPo
            newInvoiceContactId = props.arrayToShow.filter(invoice => invoice.id===valueToUpdate)[0].invoiceContactId


            if (currInvoiceId===newInvoiceId) {  // invoices are the same
                window.alert("Please choose a different Invoice Number")
                return
            }else if (newInvoiceLines.length>0) {  // invoices lines already exist on the new invoice
                if (currClientId !== newClientId) { // the client on the current invoice is not equal to the client on the new invoice
                    window.alert("The client that has been set for the invoice you are transferring to does not match the client that this invoice is for.  Please review the details and try again.")
                    return
                }
                else if (currInvoiceCustPO!==newInvoiceCustPO) {  //the current po doesn't match the invoice po
                    window.alert("The PO that has been set for the invoice that you are trying to transfer to does not match the PO applied to the current invoice.  Please review the details and try again.")
                    return
                } else {

                    var invoiceLineToTransfer

                    //Loop through the invoices
                    for (let i = 0; i < props.arrayToShow.length; i++) {
                        //Loop through the invoiceLines
                        for (let j = 0; j < props.arrayToShow[i].invoiceLines.length; j++) {
                            if (props.arrayToShow[i].invoiceLines[j].invoiceLineItemId===props.arrayIdToUpdate){
                                //Take a copy of the inv line to transfer
                                invoiceLineToTransfer = props.arrayToShow[i].invoiceLines[j]
                                //Remove it from the invoice
                                props.arrayToShow[i].invoiceLines.splice(j, 1)
                            }
                        }
                    }

                    var recordToUpdate = props.arrayToShow.filter(invoice => invoice.id===props.valueToUpdate)[0]
                    //Remove the old record from the DB
                    await axios.put(process.env.REACT_APP_API_URL + '/invoices/' + props.valueToUpdate, {...recordToUpdate})
        
                    //Loop through the invoices
                    for (let i = 0; i < props.arrayToShow.length; i++) {
                        //Add the record from the new invoice
                        if (props.arrayToShow[i].id===valueToUpdate){
                            props.arrayToShow[i].invoiceLines.push({...invoiceLineToTransfer})
                            props.arrayToShow[i].clientId=currClientId
                            props.arrayToShow[i].invoiceCustPo=currInvoiceCustPO
                        }
                    }
        
                    recordToUpdate = props.arrayToShow.filter(invoice => invoice.id===valueToUpdate)[0]
                    await axios.put(process.env.REACT_APP_API_URL + '/invoices/' + valueToUpdate, {...recordToUpdate, clientId: currClientId, invoiceCustPo: currInvoiceCustPO})
                }
    
            }else{  //the first record being added to the invoice, so use the PO and client from the current invoice
                var invoiceLineToTransfer

                //Loop through the invoices
                for (let i = 0; i < props.arrayToShow.length; i++) {
                    //Loop through the invoiceLines
                    for (let j = 0; j < props.arrayToShow[i].invoiceLines.length; j++) {
                        if (props.arrayToShow[i].invoiceLines[j].invoiceLineItemId===props.arrayIdToUpdate){
                            //Take a copy of the inv line to transfer
                            invoiceLineToTransfer = props.arrayToShow[i].invoiceLines[j]
                            //Remove it from the invoice
                            props.arrayToShow[i].invoiceLines.splice(j, 1)
                        }
                    }
                }

                var recordToUpdate = props.arrayToShow.filter(invoice => invoice.id===props.valueToUpdate)[0]
                //Remove the old record from the DB
                await axios.put(process.env.REACT_APP_API_URL + '/invoices/' + props.valueToUpdate, {...recordToUpdate})
    
                //Loop through the invoices
                for (let i = 0; i < props.arrayToShow.length; i++) {
                    //Add the record from the new invoice
                    if (props.arrayToShow[i].id===valueToUpdate){
                        props.arrayToShow[i].invoiceLines.push({...invoiceLineToTransfer})
                        props.arrayToShow[i].clientId=currClientId
                        props.arrayToShow[i].invoiceContactId=currInvoiceContactId
                        props.arrayToShow[i].invoiceCustPo=currInvoiceCustPO
                    }
                }
    
                recordToUpdate = props.arrayToShow.filter(invoice => invoice.id===valueToUpdate)[0]
                await axios.put(process.env.REACT_APP_API_URL + '/invoices/' + valueToUpdate, {...recordToUpdate, clientId: currClientId, invoiceCustPo: currInvoiceCustPO})

                //get clientName
                await axios.get(process.env.REACT_APP_API_URL + '/clients')
                .then((res) => {
                    newClientName= res.data.find(client => client.id === currClientId).clientName
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setClientsLoading(false);
                });

                if (currClientId!==newClientId || currInvoiceCustPO!==newInvoiceCustPO || currInvoiceContactId!==newInvoiceContactId){
                    window.alert("The invoice line has been transferred.  Due to there being no other invoice lines on the invoice, the invoice details have been set to:\r\n\r\nClient ID: " + newClientName + "\r\nClient PO: "+currInvoiceCustPO)
                }
            }
            props.onClose()
        }

        if (props.fieldToUpdate === "okToInvoice") {

            /////////////////////////////////////////////////////////
            //Check that the activity description is not the default
            /////////////////////////////////////////////////////////

            let varProjectID = ""
            let varActivityString = ""
            let varActivityStringProjectNumber = ""
            let varActivityStringProjectName = ""
            let varActivityStringService = ""
            let varInvoiceLineDescription = invoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).invoiceLineDescription
            let varServiceID = invoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).serviceId
            let minimumLengthOfDescription = 0

            if (window.location.href.includes("addOrUpdateClientProjectInvoiceDetails")){  //called from the edit invoice screen
                //console.log(props.projectId)
                varProjectID = props.projectId
            }else{
                //console.log(match.params.projectId)
                varProjectID = match.params.projectId
            }

            //Get project details
            await axios.get(process.env.REACT_APP_API_URL + '/projects/' + varProjectID)
            .then((res) => {
                varActivityStringProjectNumber = res.data.projectNumber
                varActivityStringProjectName = res.data.projectName
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                //nothing
            });

            //Get service name
            await axios.get(process.env.REACT_APP_API_URL+ '/projects_services_by_projectId/' + varProjectID, { headers: authHeader() })
            .then((response) => {
                const tempProjectServices = response.data.filter((item) => item.toBeDeleted === "NO")
                varActivityStringService = tempProjectServices.find(service => service.id === varServiceID).service
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                //nothing
            });

            varActivityString = varActivityStringProjectNumber + " - " + varActivityStringProjectName + ": " + varActivityStringService + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>"

            setMinimumLength(varActivityString.length -15)

            minimumLengthOfDescription = varActivityString.length -15

            //Compare the InvoiceLineDescription with the default projnum + projname + service
            if (varActivityString===varInvoiceLineDescription || varInvoiceLineDescription.length < varActivityString.length -15 || varInvoiceLineDescription.includes("ENTER WORK DETAILS HERE") || varInvoiceLineDescription.includes("MMM-YY")){
                window.alert("You must add a valid invoice line description before you can set the invoice line as SM Approved.  Please ensure minumum length of " + minimumLengthOfDescription + " characters and that all information has been included.")
            }else{
                if (invoicesToUpdate) {

                    newInvoicesToUpdate = {...invoicesToUpdate}

                    // console.log("about to update DB")    
                    newInvoicesToUpdate.invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).okToInvoice = valueToUpdate
                    updateForm(newInvoicesToUpdate)
                    setInvoicesToUpdate(newInvoicesToUpdate)
    
                }
    
                //This if statement is needed to determine if the edit call is being made from addOrEditClientProjectInvoice or listProjectClientInvoiceDetails
                if (props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate)) {
    
                    props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).okToInvoice = valueToUpdate
    
                } else {
    
                    props.arrayToShow.find(item => item.id === props.objectToUpdate).invoiceLines.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).okToInvoice = valueToUpdate
                }
    
                props.onClose()
            }

        }

        if (props.fieldToUpdate === "serviceStatus") {

            if (updateServiceStatusForAllItems === "YES") {

                var service
                var servicesWithThisDealAndStatus = []

                await axios.get(process.env.REACT_APP_API_URL + '/projects_services_by_projectId/' + servicesToUpdate.projectId)
                .then((res) => {
                    servicesWithThisDealAndStatus = res.data.filter(item => item.dealNumber === servicesToUpdate.dealNumber && item.serviceStatus === servicesToUpdate.serviceStatus && item.toBeDeleted === "NO")
                })
                .catch((err) => {
                    console.log(err);
                })
            }

            //If the user is attempting to set the service status to Live
            if (valueToUpdate==="Live"){

                var varProjectID = props.arrayToShow.find(item => item.id === props.objectToUpdate).projectId
                var tempProject    

                if (updateServiceStatusForAllItems === "YES") { 

                    for (service = 0; service < servicesWithThisDealAndStatus.length; service++) {

                        if (servicesWithThisDealAndStatus[service].serviceManager === "6303a09f7ba3f683fef4e25a") {
                            window.alert("You cannot set the service to \"Live\" while a Service Manager for this Opportunity is \"TBC\"")
                            return
                        }

                        if (servicesWithThisDealAndStatus[service].serviceWinPendingDate === "") {
                            window.alert("You cannot set the service to \"Live\" without it first having been set to Quote-win-pending")
                            return
                        }

                    }

                } else {

                    if (props.arrayToShow.find(item => item.id === props.objectToUpdate).serviceManager === "6303a09f7ba3f683fef4e25a"){
                        window.alert("You cannot set the service to \"Live\" while the Service Manager is \"TBC\"")
                        return
                    }

                    if (props.arrayToShow.find(item => item.id === props.objectToUpdate).serviceWinPendingDate === ""){
                        window.alert("You cannot set the service to \"Live\" without it first having been set to Quote-win-pending")
                        return
                    }

                }

                //Get the project status
                await axios.get(process.env.REACT_APP_API_URL+ '/projects/' + varProjectID, { headers: authHeader() })
                .then((response) => {
                    tempProject= response.data
                })
                .catch((err) => {
                    console.log(err);
                })

                await axios.put(process.env.REACT_APP_API_URL + '/projects/' + varProjectID, {...tempProject, projectStatus: "Live"})

            }

            var serviceLiveDateToUpdate
            var serviceClosedDateToUpdate
            var serviceWinPendingDateToUpdate
                
            if (updateServiceStatusForAllItems === "YES") {

                for (service = 0; service < servicesWithThisDealAndStatus.length; service++) {

                    newServicesToUpdate = servicesWithThisDealAndStatus[service]
                    serviceClosedDateToUpdate = servicesWithThisDealAndStatus[service].serviceClosedDate
                    serviceLiveDateToUpdate = servicesWithThisDealAndStatus[service].serviceLiveDate
                    serviceWinPendingDateToUpdate = servicesWithThisDealAndStatus[service].serviceWinPendingDate

                    props.arrayToShow.find(item => item.id === newServicesToUpdate.id).serviceStatus = valueToUpdate

                    // if (serviceLiveDate === todaysDate) {
                    //     serviceLiveDateToUpdate = serviceLiveDate
                    // } 

                    if (valueToUpdate==="Live") {
                        serviceLiveDateToUpdate = serviceLiveDate
                    }

                    if (valueToUpdate==="Quote-win-pending") {
                        serviceWinPendingDateToUpdate = serviceWinPendingDate
                        props.arrayToShow.find(item => item.id === newServicesToUpdate.id).serviceWinPendingDate = serviceWinPendingDate
                    }

                    if (valueToUpdate.slice(0,6)==="Closed") {
                        serviceClosedDateToUpdate = serviceClosedDate
                    } 

                    newServicesToUpdate.serviceStatus = valueToUpdate
                    newServicesToUpdate.serviceLiveDate = serviceLiveDateToUpdate
                    newServicesToUpdate.serviceClosedDateToUpdate = serviceClosedDateToUpdate
                    newServicesToUpdate.serviceWinPendingDate = serviceWinPendingDateToUpdate

                    await axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + newServicesToUpdate.id, newServicesToUpdate)
                }

            } else {

                await axios.get(process.env.REACT_APP_API_URL + '/projects_services/' + props.objectToUpdate)
                    .then((res) => {
                        newServicesToUpdate = res.data
                        serviceClosedDateToUpdate = res.data.serviceClosedDate
                        serviceLiveDateToUpdate = res.data.serviceLiveDate
                        serviceWinPendingDateToUpdate = res.data.serviceWinPendingDate
                    })
                    .catch((err) => {
                        console.log(err);
                    })

                props.arrayToShow.find(item => item.id === props.objectToUpdate).serviceStatus = valueToUpdate

                // if (serviceLiveDate === todaysDate) {
                //     serviceLiveDateToUpdate = serviceLiveDate
                // } 

                if (valueToUpdate==="Live") {
                    serviceLiveDateToUpdate = serviceLiveDate
                }

                if (valueToUpdate==="Quote-win-pending") {
                    serviceWinPendingDateToUpdate = serviceWinPendingDate
                    props.arrayToShow.find(item => item.id === props.objectToUpdate).serviceWinPendingDate = serviceWinPendingDate
                }

                if (valueToUpdate.slice(0,6)==="Closed") {
                    serviceClosedDateToUpdate = serviceClosedDate
                } 

                newServicesToUpdate.serviceStatus = valueToUpdate
                newServicesToUpdate.serviceLiveDate = serviceLiveDateToUpdate
                newServicesToUpdate.serviceClosedDate = serviceClosedDateToUpdate
                newServicesToUpdate.serviceWinPendingDate = serviceWinPendingDateToUpdate

                await updateForm(newServicesToUpdate)

            }

            setUpdateServiceStatusForAllItems("NO")

            props.onClose()

        }

        if (props.fieldToUpdate === "serviceManager") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).serviceManager = valueToUpdate

            if (updateServiceManagerOnFutureInvoices==="YES"){  //update the SM on all future unissued invoices for this service ID

                await axios.get(process.env.REACT_APP_API_URL+'/invoices_by_project/'+ props.arrayToShow[0].projectId)
                .then((res) => {

                    var invoice = res.data.filter((invoice) => stringToDate(invoice.invoiceDate,"YYYY-MM-DD","-") > Date.now() && invoice.invoiceIssued==="NO").sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)
                    console.log("Invoices: " + invoice.length)

                    invoice.forEach(invoice => {
                        var invoiceLines = invoice.invoiceLines

                        var recsToUpdate = 0
                        invoiceLines.forEach(invoiceLine => {
                            if (invoiceLine.serviceId===props.objectToUpdate){
                                invoiceLine.serviceManager = valueToUpdate
                                recsToUpdate++
                                }
                            });

                        if (recsToUpdate>0){  //only make the axios call when the value needs to change
                            axios.put(process.env.REACT_APP_API_URL + '/invoices/' + invoice.id, { ...invoice, invoiceLines: invoiceLines})
                        }
                        
                    });
                })
                .catch((err) => {
                    console.log(err);
                })

            }

            await axios.get(process.env.REACT_APP_API_URL + '/projects_services/' + props.objectToUpdate)
            .then((res) => {
                newServicesToUpdate = res.data
            })
            .catch((err) => {
                console.log(err);
            })

            newServicesToUpdate.serviceManager = valueToUpdate

            updateForm(newServicesToUpdate)

            //updateForm({ projectId: servicesToUpdate.projectId, description: servicesToUpdate.description, serviceComments: servicesToUpdate.serviceComments, service: servicesToUpdate.service, department: servicesToUpdate.department, serviceStatus: servicesToUpdate.serviceStatus, serviceManager: valueToUpdate, workStage: servicesToUpdate.workStage, toBeDeleted: servicesToUpdate.toBeDeleted, activities: servicesToUpdate.activities, serviceCreatedDate: servicesToUpdate.serviceCreatedDate, serviceLiveDate: servicesToUpdate.serviceLiveDate, serviceClosedDate: servicesToUpdate.serviceClosedDate, dealNumber: servicesToUpdate.dealNumber, dealName: servicesToUpdate.dealName, customerPo: servicesToUpdate.customerPo, lineItemNumber: servicesToUpdate.lineItemNumber, changedFlag: servicesToUpdate.changedFlag, serviceInvoiceClient: servicesToUpdate.serviceInvoiceClient, serviceInvoiceClientContact: servicesToUpdate.serviceInvoiceClientContact })
            setUpdateServiceManagerOnFutureInvoices("YES")
            props.onClose()
        }

        if (props.fieldToUpdate === "serviceComments") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).serviceComments = valueToUpdate

            await axios.get(process.env.REACT_APP_API_URL + '/projects_services/' + props.objectToUpdate)
            .then((res) => {
                newServicesToUpdate = res.data
            })
            .catch((err) => {
                console.log(err);
            })

            newServicesToUpdate.serviceComments = valueToUpdate

            await updateForm(newServicesToUpdate)

            props.onClose()
        }

        if (props.fieldToUpdate === "serviceInvoiceClient") {
            //If there's no contact and the client cbo isn't touched (but has a value) then set the contact to the first record in the list (should be the same as the first record on the screen)
            var varContact
            if (valueToUpdate!=="" && serviceInvoiceClientContact===""){
                varContact = contacts.find(item => item.clientId === valueToUpdate).id
            }else{
                varContact=serviceInvoiceClientContact
            }
            props.arrayToShow.find(item => item.id === props.objectToUpdate).serviceInvoiceClient = valueToUpdate
            props.arrayToShow.find(item => item.id === props.objectToUpdate).serviceInvoiceClientContact = varContact

            await axios.get(process.env.REACT_APP_API_URL + '/projects_services/' + props.objectToUpdate)
            .then((res) => {
                newServicesToUpdate = res.data
            })
            .catch((err) => {
                console.log(err);
            })

            newServicesToUpdate.serviceInvoiceClient = valueToUpdate
            newServicesToUpdate.serviceInvoiceClientContact = varContact

            await updateForm(newServicesToUpdate)

            //Code here to also update any clients and contacts on 
            //all future invoices that this service appears on:

            await axios.get(process.env.REACT_APP_API_URL+'/invoices_by_project/'+ props.arrayToShow[0].projectId)
            .then((res) => {
                var invoice = res.data.filter((invoice) => stringToDate(invoice.invoiceDate,"YYYY-MM-DD","-") > Date.now() && invoice.invoiceIssued==="NO").sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)

                invoice.forEach(invoice => {
                    var invoiceLines = invoice.invoiceLines
                    var recsToUpdate = 0
                    invoiceLines.forEach(invoiceLine => {
                        if (invoiceLine.serviceId===props.objectToUpdate){
                            recsToUpdate++
                            }
                        });

                    if (recsToUpdate>0){
                        axios.put(process.env.REACT_APP_API_URL + '/invoices/' + invoice.id, { ...invoice, clientId: valueToUpdate, invoiceContactId: varContact})
                    }
                    
                });
            })
            .catch((err) => {
                console.log(err);
            })

            
            props.onClose()
        }

        if (props.fieldToUpdate === "customerPo") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).customerPo = valueToUpdate

            await axios.get(process.env.REACT_APP_API_URL + '/projects_services/' + props.objectToUpdate)
            .then((res) => {
                newServicesToUpdate = res.data
            })
            .catch((err) => {
                console.log(err);
            })

            newServicesToUpdate.customerPo = valueToUpdate

            await updateForm(newServicesToUpdate)

            props.onClose()
        }

        if (props.fieldToUpdate === "projectManagerId") {

            if (props.arrayToShow.find(item => item.id === props.objectToUpdate).projectStatus==="Live" && valueToUpdate==="6303a09f7ba3f683fef4e25a") { //userID = TBC
                window.alert("You cannot change the project manager to \"TBC\" because the project is live")
            }else{

                props.arrayToShow.find(item => item.id === props.objectToUpdate).projectManagerId = valueToUpdate

                if (updateProjectManagerOnFutureInvoices==="YES"){  //update the PM on all future unissued invoices

                    await axios.get(process.env.REACT_APP_API_URL+'/invoices_by_project/'+ props.objectToUpdate)
                    .then((res) => {

                        var invoice = res.data.filter((invoice) => stringToDate(invoice.invoiceDate,"YYYY-MM-DD","-") > Date.now() && invoice.invoiceIssued==="NO").sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)
                        invoice.forEach(invoice => {

                            var invoiceLines = invoice.invoiceLines
                            invoiceLines.forEach(invoiceLine => {
                                console.log("Old PM: " + invoiceLine.projectManager)
                                invoiceLine.projectManager = valueToUpdate
                                console.log("New PM: " + valueToUpdate)
                            });

                            axios.put(process.env.REACT_APP_API_URL + '/invoices/' + invoice.id, { ...invoice, invoiceLines: invoiceLines})
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

                }

                updateForm({ ...projectsToUpdate, projectManagerId: valueToUpdate})
                setUpdateProjectManagerOnFutureInvoices("YES")
                props.onClose()
            }
        }

        if (props.fieldToUpdate === "projectClient") {

            var varContact
            if (valueToUpdate!=="" && projectClientContact===""){
                varContact = contacts.find(item => item.clientId === valueToUpdate).id
            }else{
                varContact=projectClientContact
            }
            props.arrayToShow.find(item => item.id === props.objectToUpdate).clientId = valueToUpdate
            props.arrayToShow.find(item => item.id === props.objectToUpdate).projectClientContactId = varContact

            await updateForm({ ...projectsToUpdate, clientId: valueToUpdate, projectClientContactId: varContact })

            props.onClose()

        }

        if (props.fieldToUpdate === "projectManagementContractor") {

            newProjectToUpdate = {...projectsToUpdate}

            console.log(props.arrayToShow)

            props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectCurrentRIBAStage.contractor = valueToUpdate
            newProjectToUpdate.projectCurrentRIBAStage.contractor = valueToUpdate

            await updateForm({ ...newProjectToUpdate })

            props.onClose()

        }

        // if (props.fieldToUpdate === "projectClientContactId") {

        //     // props.arrayToShow.find(item => item.id === props.objectToUpdate).projectClientContactId = valueToUpdate
            
        //     // updateForm({ ...projectsToUpdate, projectClientContactId: valueToUpdate})

        //     // props.onClose()
        // }

        if (props.fieldToUpdate === "evidenceDocumentFilename") {

            await axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + props.objectToUpdate)
            .then((res) => {

                var newBREEAMAssessment = res.data

                newBREEAMAssessment.credits.find(item => item.id === props.arrayIdToUpdate).documentaryEvidence.find(item => item.id === props.subArrayIdToUpdate).filename = valueToUpdate

                axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + props.objectToUpdate, { ...newBREEAMAssessment})

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).documentaryEvidence.find(item => item.id === props.subArrayIdToUpdate).filename = valueToUpdate

                props.onClose()

            })
            .catch((err) => {
                console.log(err);
            })

        }

        if (props.fieldToUpdate === "BREEAMAssessmentComments") {

            props.arrayToShow.comments = valueToUpdate

            updateForm({ ...BREEAMAssessmentsToUpdate, comments: valueToUpdate})

            props.onClose()
        }

        if (props.fieldToUpdate === "associateExistingFiles") {

            await axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + props.objectToUpdate)
            .then((res) => {

                var newBREEAMAssessment = res.data
                var newFilesArray = []
                var newFile
                var evidenceRequired
                var individualEvidenceDocuments
                var individualEvidenceDocument
                var evidenceDocumentAssigned = true

                for (newFile = 0; newFile < valueToUpdate.length; newFile++) {
                    if (newBREEAMAssessment.credits.find(item => item.id === props.arrayIdToUpdate).files.find(item => item.filename === valueToUpdate[newFile].value)) {
                        //add to newFilesArray, but keep existing id and description
                        newFilesArray.push({id: newBREEAMAssessment.credits.find(item => item.id === props.arrayIdToUpdate).files.find(item => item.filename === valueToUpdate[newFile].value).id, filename: newBREEAMAssessment.credits.find(item => item.id === props.arrayIdToUpdate).files.find(item => item.filename === valueToUpdate[newFile].value).filename, description: newBREEAMAssessment.credits.find(item => item.id === props.arrayIdToUpdate).files.find(item => item.filename === valueToUpdate[newFile].value).description})
                    } else {
                        newFilesArray.push({id: uuidv4(), filename: valueToUpdate[newFile].value, description: ""})
                    }
                }

                //if a piece of documentary evidence is not present in the newFilesArray then do not update
                for (evidenceRequired = 0; evidenceRequired < newBREEAMAssessment.credits.find(item => item.id === props.arrayIdToUpdate).documentaryEvidence.length; evidenceRequired++) {

                    individualEvidenceDocuments = newBREEAMAssessment.credits.find(item => item.id === props.arrayIdToUpdate).documentaryEvidence[evidenceRequired].filename.split("|")

                    for (individualEvidenceDocument = 0; individualEvidenceDocument < individualEvidenceDocuments.length; individualEvidenceDocument++) {
                        if (!newFilesArray.find(item => item.filename === individualEvidenceDocuments[individualEvidenceDocument].trim()) && individualEvidenceDocuments[individualEvidenceDocument] !== "") {
                            evidenceDocumentAssigned = false
                        }
                    }
                }

                if (evidenceDocumentAssigned) {

                    newBREEAMAssessment.credits.find(item => item.id === props.arrayIdToUpdate).files = newFilesArray

                    axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + props.objectToUpdate, { ...newBREEAMAssessment})

                    props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).files = newFilesArray

                } else {

                    window.alert("Files assigned as evidence cannot be unassigned from this credit")
                }

                props.onClose()

            })
            .catch((err) => {
                console.log(err);
            })

        }

        if (props.fieldToUpdate === "Team Member Name") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).name = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "Team Member Company") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).company = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "Team Member Email") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).email = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "Team Member Tel") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).tel = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "BREEAM Stage Event") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).event = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "BREEAM Stage Event Date") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).eventDate = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "BREEAM Stage Event Reminder Date") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).eventReminderDate = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "BREEAM Stage Event Owner") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).eventOwner = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "BREEAMCreditsValue") {

            newBREEAMAssessmentsToUpdate = {...BREEAMAssessmentsToUpdate}

            if (newBREEAMAssessmentsToUpdate.credits.find(item => item.id === props.arrayIdToUpdate)) {

                newBREEAMAssessmentsToUpdate.credits.find(item => item.id === props.arrayIdToUpdate).credits = valueToUpdate
                updateForm(newBREEAMAssessmentsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).credits = valueToUpdate

            setBREEAMAssessmentsToUpdate(newBREEAMAssessmentsToUpdate)

            props.onClose()
        }

        if (props.fieldToUpdate === "BREEAMMinimumStandardsValue") {

            newBREEAMAssessmentsToUpdate = {...BREEAMAssessmentsToUpdate}

            if (newBREEAMAssessmentsToUpdate.credits.find(item => item.id === props.arrayIdToUpdate)) {

                newBREEAMAssessmentsToUpdate.credits.find(item => item.id === props.arrayIdToUpdate).creditsMinimumStandards = valueToUpdate
                updateForm(newBREEAMAssessmentsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).creditsMinimumStandards = valueToUpdate

            setBREEAMAssessmentsToUpdate(newBREEAMAssessmentsToUpdate)

            props.onClose()
        }

        if (props.fieldToUpdate === "BREEAMCreditsTargetValue") {

            newBREEAMAssessmentsToUpdate = {...BREEAMAssessmentsToUpdate}

            if (newBREEAMAssessmentsToUpdate.credits.find(item => item.id === props.arrayIdToUpdate)) {

                newBREEAMAssessmentsToUpdate.credits.find(item => item.id === props.arrayIdToUpdate).creditsTargeted = valueToUpdate
                updateForm(newBREEAMAssessmentsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).creditsTargeted = valueToUpdate

            setBREEAMAssessmentsToUpdate(newBREEAMAssessmentsToUpdate)

            props.onClose()
        }

        if (props.fieldToUpdate === "BREEAMCreditsPotentialValue") {

            newBREEAMAssessmentsToUpdate = {...BREEAMAssessmentsToUpdate}

            if (newBREEAMAssessmentsToUpdate.credits.find(item => item.id === props.arrayIdToUpdate)) {

                newBREEAMAssessmentsToUpdate.credits.find(item => item.id === props.arrayIdToUpdate).creditsPotential = valueToUpdate
                updateForm(newBREEAMAssessmentsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).creditsPotential = valueToUpdate

            setBREEAMAssessmentsToUpdate(newBREEAMAssessmentsToUpdate)

            props.onClose()
        }


        if (props.fieldToUpdate === "projectStatus") {

            if (props.arrayToShow.find(item => item.id === props.objectToUpdate).projectManagerId==="6303a09f7ba3f683fef4e25a" && valueToUpdate==="Live") { //userID = TBC
                window.alert("You cannot change the project status to \"Live\" because the project manager is \"TBC\"")
            }else{
                props.arrayToShow.find(item => item.id === props.objectToUpdate).projectStatus = valueToUpdate

                var projectLiveDateToUpdate
                var projectClosedDateToUpdate
    
                if (projectLiveDate === todaysDate) {
                    projectLiveDateToUpdate = projectLiveDate
                } else {
                    projectLiveDateToUpdate = projectsToUpdate.projectLiveDate
                }
    
                if (projectClosedDate === todaysDate) {
                    projectClosedDateToUpdate = projectClosedDate
                } else {
                    projectClosedDateToUpdate = projectsToUpdate.projectClosedDate
                }

                updateForm({ ...projectsToUpdate, projectStatus: valueToUpdate, projectClosedDate: projectClosedDateToUpdate, projectLiveDate: projectLiveDateToUpdate })
    
                props.onClose()
            }

        }
        
        if (props.fieldToUpdate === "dealNumberSeed") {

            let varDealName = ""

            if (valueToUpdateForReactSelect.value.length!==0){
                varDealName = deals.find(item => item.id === valueToUpdateForReactSelect.value).properties.dealname
            }
            props.arrayToShow.find(item => item.id === props.objectToUpdate).dealNumberSeed = valueToUpdateForReactSelect.value
            props.arrayToShow.find(item => item.id === props.objectToUpdate).dealNumberSeedName = varDealName
            updateForm({ ...projectsToUpdate, dealNumberSeed: valueToUpdateForReactSelect.value, dealNumberSeedName: varDealName })

            props.onClose()
        }

        if (props.fieldToUpdate === "dealNumber") {

            let varDealName = ""

            if (valueToUpdateForReactSelect.value.length!==0){
                varDealName = deals.find(item => item.id === valueToUpdateForReactSelect.value).properties.dealname
            }

            props.arrayToShow.find(item => item.id === props.objectToUpdate).dealNumber = valueToUpdateForReactSelect.value
            props.arrayToShow.find(item => item.id === props.objectToUpdate).dealName = varDealName

            await axios.get(process.env.REACT_APP_API_URL + '/projects_services/' + props.objectToUpdate)
            .then((res) => {
                newServicesToUpdate = res.data
            })
            .catch((err) => {
                console.log(err);
            })

            newServicesToUpdate.dealNumber = valueToUpdateForReactSelect.value
            newServicesToUpdate.dealName = varDealName

            await updateForm(newServicesToUpdate)

            props.onClose()
        }

        if (props.fieldToUpdate === "projectName") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).projectName = valueToUpdate

            updateForm({ ...projectsToUpdate, projectName: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectManagementStage") {

            props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectCurrentRIBAStage.stage = valueToUpdate

            newProjectToUpdate = {...projectsToUpdate}

            newProjectToUpdate.projectCurrentRIBAStage.stage = valueToUpdate

            updateForm({ ...newProjectToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectManagementProgressComments") {

            props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectCurrentRIBAStage.progressComments = valueToUpdate

            newProjectToUpdate = {...projectsToUpdate}

            newProjectToUpdate.projectCurrentRIBAStage.progressComments = valueToUpdate

            updateForm({ ...newProjectToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectManagementKPIDescription") {

            props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectCurrentRIBAStage.clientKPIs.find(item => item.id === props.arrayIdToUpdate).KPIDescription = valueToUpdate

            newProjectToUpdate = {...projectsToUpdate}

            newProjectToUpdate.projectCurrentRIBAStage.clientKPIs.find(item => item.id === props.arrayIdToUpdate).KPIDescription = valueToUpdate

            updateForm({ ...newProjectToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectManagementKPITarget") {

            props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectCurrentRIBAStage.clientKPIs.find(item => item.id === props.arrayIdToUpdate).KPITarget = valueToUpdate

            newProjectToUpdate = {...projectsToUpdate}

            newProjectToUpdate.projectCurrentRIBAStage.clientKPIs.find(item => item.id === props.arrayIdToUpdate).KPITarget = valueToUpdate

            updateForm({ ...newProjectToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectManagementKPIAchieved") {

            props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectCurrentRIBAStage.clientKPIs.find(item => item.id === props.arrayIdToUpdate).KPIAchieved = valueToUpdate

            newProjectToUpdate = {...projectsToUpdate}

            newProjectToUpdate.projectCurrentRIBAStage.clientKPIs.find(item => item.id === props.arrayIdToUpdate).KPIAchieved = valueToUpdate

            updateForm({ ...newProjectToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectManagementKPIAchievedDate") {

            props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectCurrentRIBAStage.clientKPIs.find(item => item.id === props.arrayIdToUpdate).KPIAchievedDate = valueToUpdate

            newProjectToUpdate = {...projectsToUpdate}

            newProjectToUpdate.projectCurrentRIBAStage.clientKPIs.find(item => item.id === props.arrayIdToUpdate).KPIAchievedDate = valueToUpdate

            updateForm({ ...newProjectToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectDescription") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).projectDescription = valueToUpdate

            updateForm({ ...projectsToUpdate, projectDescription: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectInvoicingNotes") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).projectInvoicingNotes = valueToUpdate

            updateForm({ ...projectsToUpdate, projectInvoicingNotes: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectLocation") {

            if (props.arrayToShow.find(item => item.projectId === props.objectToUpdate)) {

                props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectLocation = valueToUpdate

            } else {

                props.arrayToShow.find(item => item.id === props.objectToUpdate).projectLocation = valueToUpdate

            }

            updateForm({ ...projectsToUpdate, projectLocation: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectBillingPlanAndFeesChecked") {

            console.log(userId)

            props.arrayToShow.find(item => item.id === props.objectToUpdate).projectBillingPlanAndFeesChecked = valueToUpdate

            if (valueToUpdate === "YES") {
                valueToUpdateUserIdOrNo = userId
            } else {
                valueToUpdateUserIdOrNo = "NO"
            }

            updateForm({ ...projectsToUpdate, projectBillingPlanAndFeesChecked: valueToUpdateUserIdOrNo })

            props.onClose()
        }

        if (props.fieldToUpdate === "projectInvoicingChecked") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).projectInvoicingChecked = valueToUpdate

            if (valueToUpdate === "YES") {
                valueToUpdateUserIdOrNo = userId
            } else {
                valueToUpdateUserIdOrNo = "NO"
            }

            updateForm({ ...projectsToUpdate, projectInvoicingChecked: valueToUpdateUserIdOrNo })

            props.onClose()
        }

        if (props.fieldToUpdate === "invoiceDate") {

            if (valueToUpdate.slice(0,7) < todaysDate.slice(0,7)) {

                if (window.confirm("Warning - Date Entered is in a Past Month!  Is this OK?")) {

                } else {
                    return
                }

            }

            newInvoicesToUpdate = {...invoicesToUpdate}

            if (props.arrayToShow.find(item => item.id === props.objectToUpdate)) {

                props.arrayToShow.find(item => item.id === props.objectToUpdate).invoiceDate = valueToUpdate

            } else {

                props.arrayToShow.find(item => item.invoiceId === props.objectToUpdate).invoiceDate = valueToUpdate

            }

            updateForm({...newInvoicesToUpdate, invoiceDate: valueToUpdate})

            //Loop through invoices set as "to update"
            multipleInvoicesToUpdate.forEach(item => {

                if (item.isChecked===true){
                     
                    if (window.location.href.includes("projectsTrackerInvoicing")){  //called from the billing plan tracker

                        let iCount = 0
                        //need to loop through the billingplantracker array to set the invoice date on the service line records
                        props.arrayToShow.forEach(row => {
                            
                            if (props.arrayToShow[iCount].invoiceId === item.id){
                                props.arrayToShow[iCount].invoiceDate = item.newInvoiceDate
                            }
                            iCount++
                        });

                    }else{  //called from the project screen
                        props.arrayToShow.find(array => array.id === item.id).invoiceDate = item.newInvoiceDate
                    }
                    axios.put(process.env.REACT_APP_API_URL + '/invoices/' + item.id, { ...item, invoiceDate: item.newInvoiceDate})

                }

            });

            props.onClose()
        }

        if (props.fieldToUpdate === "reductionProjection" && props.collectionToUpdate === "events_footprints") {

            if (eventFootprintsToUpdate.eventFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                eventFootprintsToUpdate.eventFootprintItems.find(item => item.id === props.arrayIdToUpdate).reductionProjection = valueToUpdate
                updateForm(eventFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).reductionProjection = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "reductionProjectionYear" && props.collectionToUpdate === "events_footprints") {

            if (eventFootprintsToUpdate.eventFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                eventFootprintsToUpdate.eventFootprintItems.find(item => item.id === props.arrayIdToUpdate).reductionProjectionYear = valueToUpdate
                updateForm(eventFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).reductionProjectionYear = valueToUpdate

            props.onClose()
        }

        if (props.fieldToUpdate === "reductionProjection" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).reductionProjection = valueToUpdate
                updateForm(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).reductionProjection = valueToUpdate

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            props.onClose()
        }

        if (props.fieldToUpdate === "reductionProjectionYear" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).reductionProjectionYear = valueToUpdate
                updateForm(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).reductionProjectionYear = valueToUpdate

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            props.onClose()
        }

        if (props.fieldToUpdate === "reductionMeasures" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).reductionMeasures = valueToUpdate
                updateForm(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).reductionMeasures = valueToUpdate

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            props.onClose()

        }

        if (props.fieldToUpdate === "reductionControlCategory" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).reductionControlCategory = valueToUpdate
                updateForm(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).reductionControlCategory = valueToUpdate

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            props.onClose()

        }

        if (props.fieldToUpdate === "basicUncertainty" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).basicUncertainty = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).basicUncertaintyFactor = basicUncertaintyFactors.find(item => item.activityOrEmissionCategory === valueToUpdate).basicUncertaintyFactor

                updateForm(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).basicUncertainty = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).basicUncertaintyFactor = basicUncertaintyFactors.find(item => item.activityOrEmissionCategory === valueToUpdate).basicUncertaintyFactor

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            props.onClose()

        }

        if (props.fieldToUpdate === "dataType" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataType = valueToUpdate

                if (valueToUpdate === "Primary Data") {

                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnology = "Data generated using the same technology"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyClassification = "Very Good"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyFactor = "1"
        
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTime = "Data with less than 3 years of difference"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeClassification = "Very Good"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeFactor = "1"
        
                }
        
                if (valueToUpdate === "Secondary Data") {
        
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnology = "Data generated using a similar but different technology"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyClassification = "Good"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyFactor = "1.2"
        
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTime = "Data with less than 10 years of difference"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeClassification = "Fair"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeFactor = "1.2"
                    
                }
        
                if (valueToUpdate === "Financial Data") {
        
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnology = "Data generated using a different technology"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyClassification = "Fair"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyFactor = "1.5"
        
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTime = "Data with less than 6 years of difference"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeClassification = "Good"
                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeFactor = "1.1"
                    
                }


                updateForm(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataType = valueToUpdate

            if (valueToUpdate === "Primary Data") {

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnology = "Data generated using the same technology"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyClassification = "Very Good"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyFactor = "1"
    
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTime = "Data with less than 3 years of difference"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeClassification = "Very Good"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeFactor = "1"
    
            }
    
            if (valueToUpdate === "Secondary Data") {
    
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnology = "Data generated using a similar but different technology"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyClassification = "Good"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyFactor = "1.2"
    
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTime = "Data with less than 10 years of difference"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeClassification = "Fair"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeFactor = "1.2"
                
            }
    
            if (valueToUpdate === "Financial Data") {
    
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnology = "Data generated using a different technology"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyClassification = "Fair"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyFactor = "1.5"
    
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTime = "Data with less than 6 years of difference"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeClassification = "Good"
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeFactor = "1.1"
                
            }

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            props.onClose()

        }

        if (props.fieldToUpdate === "organisationFootprintItemDescription") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).itemDescription = valueToUpdate
                updateForm(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).itemDescription = valueToUpdate

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            props.onClose()

        }

        if (props.fieldToUpdate === "dataReviewConsultant") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataReviewConsultant = valueToUpdate
                updateForm(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataReviewConsultant = valueToUpdate

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            props.onClose()

        }

        if (props.fieldToUpdate === "dataReviewProgress") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            // if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

            //     newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataReviewProgress = valueToUpdate
            //     updateForm(newOrganisationFootprintsToUpdate)

            // }

            // props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataReviewProgress = valueToUpdate

            // setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            // props.onClose()

            if (props.arrayToShowFiltered && updateConsultantForAllItems === "YES") {

                var footprintItem

                //updateForm - all ids in props.arrayToShowFiltered
                //Update props.arrayToShow - all ids in props.arrayToShowFiltered

                for (footprintItem = 0; footprintItem < props.arrayToShowFiltered.length; footprintItem++) {

                    if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id)) {

                        newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).dataReviewProgress = valueToUpdate
                        props.arrayToShow.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).dataReviewProgress = valueToUpdate
                    
                    }

                    updateForm(newOrganisationFootprintsToUpdate)

                }

            } else {

                if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataReviewProgress = valueToUpdate
                    updateForm(newOrganisationFootprintsToUpdate)

                }

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataReviewProgress = valueToUpdate

            }

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            setUpdateConsultantForAllItems("NO")

            props.onClose()

        }

        if (props.fieldToUpdate === "calculationConsultant") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (props.arrayToShowFiltered && updateConsultantForAllItems === "YES") {

                var footprintItem

                //updateForm - all ids in props.arrayToShowFiltered
                //Update props.arrayToShow - all ids in props.arrayToShowFiltered

                for (footprintItem = 0; footprintItem < props.arrayToShowFiltered.length; footprintItem++) {

                    if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id)) {

                        newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).calculationConsultant = valueToUpdate
                        props.arrayToShow.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).calculationConsultant = valueToUpdate
                    
                    }

                    updateForm(newOrganisationFootprintsToUpdate)

                }

            } else {

                if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).calculationConsultant = valueToUpdate
                    updateForm(newOrganisationFootprintsToUpdate)

                }

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).calculationConsultant = valueToUpdate

            }

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            setUpdateConsultantForAllItems("NO")

            props.onClose()

        }

        if (props.fieldToUpdate === "calculationProgress") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            // if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

            //     newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).calculationProgress = valueToUpdate
            //     updateForm(newOrganisationFootprintsToUpdate)

            // }

            // props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).calculationProgress = valueToUpdate

            // setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            // props.onClose()

            if (props.arrayToShowFiltered && updateConsultantForAllItems === "YES") {

                var footprintItem

                //updateForm - all ids in props.arrayToShowFiltered
                //Update props.arrayToShow - all ids in props.arrayToShowFiltered

                for (footprintItem = 0; footprintItem < props.arrayToShowFiltered.length; footprintItem++) {

                    if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id)) {

                        newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).calculationProgress = valueToUpdate
                        props.arrayToShow.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).calculationProgress = valueToUpdate
                    
                    }

                    updateForm(newOrganisationFootprintsToUpdate)

                }

            } else {

                if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).calculationProgress = valueToUpdate
                    updateForm(newOrganisationFootprintsToUpdate)

                }

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).calculationProgress = valueToUpdate

            }

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            setUpdateConsultantForAllItems("NO")

            props.onClose()

        }

        if (props.fieldToUpdate === "QAConsultant") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (props.arrayToShowFiltered && updateConsultantForAllItems === "YES") {

                var footprintItem

                //updateForm - all ids in props.arrayToShowFiltered
                //Update props.arrayToShow - all ids in props.arrayToShowFiltered

                for (footprintItem = 0; footprintItem < props.arrayToShowFiltered.length; footprintItem++) {

                    if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id)) {

                        newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).QAConsultant = valueToUpdate
                        props.arrayToShow.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).QAConsultant = valueToUpdate
                    
                    }

                    updateForm(newOrganisationFootprintsToUpdate)

                }

            } else {

                if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).QAConsultant = valueToUpdate
                    updateForm(newOrganisationFootprintsToUpdate)

                }

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).QAConsultant = valueToUpdate

            }

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            setUpdateConsultantForAllItems("NO")

            props.onClose()

        }

        if (props.fieldToUpdate === "QAProgress") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            // if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

            //     newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).QAProgress = valueToUpdate
            //     updateForm(newOrganisationFootprintsToUpdate)

            // }

            // props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).QAProgress = valueToUpdate

            // setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            // props.onClose()

            if (props.arrayToShowFiltered && updateConsultantForAllItems === "YES") {

                var footprintItem

                //updateForm - all ids in props.arrayToShowFiltered
                //Update props.arrayToShow - all ids in props.arrayToShowFiltered

                for (footprintItem = 0; footprintItem < props.arrayToShowFiltered.length; footprintItem++) {

                    if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id)) {

                        newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).QAProgress = valueToUpdate
                        props.arrayToShow.find(item => item.id === props.arrayToShowFiltered[footprintItem].id).QAProgress = valueToUpdate
                    
                    }

                    updateForm(newOrganisationFootprintsToUpdate)

                }

            } else {

                if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                    newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).QAProgress = valueToUpdate
                    updateForm(newOrganisationFootprintsToUpdate)

                }

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).QAProgress = valueToUpdate

            }

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            setUpdateConsultantForAllItems("NO")

            props.onClose()

        }

        if (props.fieldToUpdate === "submitOrganisationFootprintItem") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                var uniqueId = uuidv4()

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).submissionComment = valueToUpdate

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).submitted = formatDate(Date())

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).commentThread.push({commentDate: Date(), comment:valueToUpdate, commentType: "Submission", commentUser: userId, id: uniqueId})
                
                updateForm(newOrganisationFootprintsToUpdate)

                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)


                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).commentThread.push({commentDate: Date(), comment:valueToUpdate, commentType: "Submission", commentUser: userId, id: uniqueId})

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).submitted = formatDate(Date())

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).submissionComment = valueToUpdate

            }

            props.onClose()

        }

        if (props.fieldToUpdate === "dataQualityTechnology" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityTechnology = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityTechnologyClassification = uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityTechnologyFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityTechnology = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityTechnologyClassification = uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityTechnologyFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "dataQualityTime" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityTime = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityTimeClassification = uncertaintyCategories.filter(item => item.field === "dataQualityTime").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityTimeFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityTime").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityTime = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityTimeClassification = uncertaintyCategories.filter(item => item.field === "dataQualityTime").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityTimeFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityTime").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "dataQualityGeography" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityGeography = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityGeographyClassification = uncertaintyCategories.filter(item => item.field === "dataQualityGeography").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityGeographyFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityGeography").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityGeography = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityGeographyClassification = uncertaintyCategories.filter(item => item.field === "dataQualityGeography").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityGeographyFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityGeography").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "dataQualityCompleteness" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityCompleteness = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityCompletenessClassification = uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityCompletenessFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityCompleteness = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityCompletenessClassification = uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityCompletenessFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "dataQualityReliability" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityReliability = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityReliabilityClassification = uncertaintyCategories.filter(item => item.field === "dataQualityReliability").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).dataQualityReliabilityFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityReliability").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityReliability = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityReliabilityClassification = uncertaintyCategories.filter(item => item.field === "dataQualityReliability").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).dataQualityReliabilityFactor = Number(uncertaintyCategories.filter(item => item.field === "dataQualityReliability").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "emissionsFactorTechnology" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnology = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnology = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTechnologyFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "emissionsFactorTime" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTime = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTime = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorTimeFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "emissionsFactorGeography" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorGeography = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorGeographyClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorGeographyFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorGeography = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorGeographyClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorGeographyFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "emissionsFactorCompleteness" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorCompleteness = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorCompletenessClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorCompletenessFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorCompleteness = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorCompletenessClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorCompletenessFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "emissionsFactorReliability" && props.collectionToUpdate === "organisations_footprints") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorReliability = valueToUpdate
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorReliabilityClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").find(item => item.ratingDescription === valueToUpdate).classification
                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).emissionsFactorReliabilityFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").find(item => item.ratingDescription === valueToUpdate).ratingValue)
                updateForm(newOrganisationFootprintsToUpdate)
                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorReliability = valueToUpdate
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorReliabilityClassification = uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").find(item => item.ratingDescription === valueToUpdate).classification
            props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).emissionsFactorReliabilityFactor = Number(uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").find(item => item.ratingDescription === valueToUpdate).ratingValue)

            props.onClose()
        }

        if (props.fieldToUpdate === "organisationFootprintNotes") {

            props.arrayToShow.organisationFootprintNotes = valueToUpdate

            updateForm({ ...organisationFootprintsToUpdate, organisationFootprintNotes: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "organisationFootprintInternalNotes") {

            props.arrayToShow.organisationFootprintInternalNotes = valueToUpdate

            console.log(props.arrayToShow)

            updateForm({ ...organisationFootprintsToUpdate, organisationFootprintInternalNotes: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "organisationFootprintProjectNumber") {

            props.arrayToShow.organisationFootprintProjectNumber = valueToUpdate

            console.log(props.arrayToShow)

            updateForm({ ...organisationFootprintsToUpdate, organisationFootprintProjectNumber: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "organisationFootprintSummaryComments") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).organisationFootprintSummaryComments = valueToUpdate

            updateForm({ ...organisationFootprintsToUpdate, organisationFootprintSummaryComments: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "organisationFootprintScope1Comments") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).organisationFootprintScope1Comments = valueToUpdate

            updateForm({ ...organisationFootprintsToUpdate, organisationFootprintScope1Comments: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "organisationFootprintScope2Comments") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).organisationFootprintScope2Comments = valueToUpdate

            updateForm({ ...organisationFootprintsToUpdate, organisationFootprintScope2Comments: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "organisationFootprintScope3Comments") {

            props.arrayToShow.find(item => item.id === props.objectToUpdate).organisationFootprintScope3Comments = valueToUpdate

            updateForm({ ...organisationFootprintsToUpdate, organisationFootprintScope3Comments: valueToUpdate })

            props.onClose()
        }

        if (props.fieldToUpdate === "itemConsultantComment") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).itemConsultantComment = valueToUpdate
                updateForm(newOrganisationFootprintsToUpdate)

                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            if (props.arrayToShow.find(item => item.id === props.objectToUpdate)) {

                props.arrayToShow.find(item => item.id === props.objectToUpdate).organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).itemConsultantComment = valueToUpdate

            } else {

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).itemConsultantComment = valueToUpdate

            }

            props.onClose()
        }

        if (props.fieldToUpdate === "itemCommentForReport") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).itemCommentForReport = valueToUpdate
                updateForm(newOrganisationFootprintsToUpdate)

                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            if (props.arrayToShow.find(item => item.id === props.objectToUpdate)) {

                props.arrayToShow.find(item => item.id === props.objectToUpdate).organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).itemCommentForReport = valueToUpdate

            } else {

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).itemCommentForReport = valueToUpdate

            }

            props.onClose()
        }

        if (props.fieldToUpdate === "itemConsultantCommentForDataCollection") {

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate)) {

                newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).itemConsultantCommentForDataCollection = valueToUpdate
                updateForm(newOrganisationFootprintsToUpdate)

                setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            }

            if (props.arrayToShow.find(item => item.id === props.objectToUpdate)) {

                props.arrayToShow.find(item => item.id === props.objectToUpdate).organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).itemConsultantCommentForDataCollection = valueToUpdate

            } else {

                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).itemConsultantCommentForDataCollection = valueToUpdate

            }

            props.onClose()
        }

        if (props.fieldToUpdate === "commentThread") {

            var commentType

            if (props.role === 3001) {

                commentType = "Client"

            } else {

                if (props.title === "Internal QA Comment") {
                    commentType = "QA"
                } else {
                    commentType = "Consultant"
                }

                // var checkBox = document.getElementById("QA")

                // if (checkBox.checked === true) {

                //     commentType = "QA"
                // } else {
                //     commentType = "Consultant"
                // }

            }

            uniqueId = uuidv4()

            newOrganisationFootprintsToUpdate = {...organisationFootprintsToUpdate}

            newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).commentThread.push({commentDate: Date(), comment:valueToUpdate, commentType: commentType, commentUser: userId, id: uniqueId})
            
            updateForm(newOrganisationFootprintsToUpdate)

            setOrganisationFootprintsToUpdate(newOrganisationFootprintsToUpdate)

            if (props.arrayToShow.find(item => item.id === props.arrayIdToUpdate)) {
                props.arrayToShow.find(item => item.id === props.arrayIdToUpdate).commentThread.push({commentDate: Date(), comment:valueToUpdate, commentType: commentType, commentUser: userId, id: uniqueId})
            } else {
                props.arrayToShow[0].organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).commentThread.push({commentDate: Date(), comment:valueToUpdate, commentType: commentType, commentUser: userId, id: uniqueId})
            }

            // Uncomment the below when ready for email notification functionality
            // **********************************************************************

            if (commentType === "Client" || commentType === "QA") {
                var consultantEmail
                var consultantName
                if (newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).calculationConsultant === "") {
                    consultantEmail = users.find(item => item.id === newOrganisationFootprintsToUpdate.organisationFootprintConsultant).emailId
                    consultantName = users.find(item => item.id === newOrganisationFootprintsToUpdate.organisationFootprintConsultant).firstName
                } else {
                    consultantEmail = users.find(item => item.id === newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).calculationConsultant).emailId
                    consultantName = users.find(item => item.id === newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).calculationConsultant).firstName
                }
                await axios.post(process.env.REACT_APP_API_URL + '/generic_email', { "recipient": consultantEmail, "message": "Dear " + consultantName + ",<br></br>The following comment has been added by " + AuthService.getCurrentUserName() + " against the following footprint item: " + newOrganisationFootprintsToUpdate.organisationFootprintItems.find(item => item.id === props.arrayIdToUpdate).itemDescription +  "<br></br>Please visit the Vero Data Collection Tracker to review:<br></br><br></br>" + valueToUpdate + "<br></br>", "subject": "Vero: Organisation Footprint Comment Added - " + newOrganisationFootprintsToUpdate.organisationFootprintRef })
            }

            // if (commentType === "Consultant" && checkBoxExternalEmail.checked === true) {
            //     await axios.post(process.env.REACT_APP_API_URL + '/generic_email', { "recipient": contacts.find(item => item.id === newOrganisationFootprintsToUpdate.organisationFootprintClientContact).contactEmail, "message": "Dear " + contacts.find(item => item.id === newOrganisationFootprintsToUpdate.organisationFootprintClientContact).contactName + ",<br></br>The following comment has been added by " + AuthService.getCurrentUserName() + "<br></br>Please visit the Vero Data Collection Tracker to review:<br></br><br></br>" + valueToUpdate + "<br></br>", "subject": "Vero: Organisation Footprint Comment Added" })
            // }
            // **********************************************************************

            props.onClose()
        }

        if (props.fieldToUpdate === "projectManagementCommentThread") {

            uniqueId = uuidv4()

            var dateToStamp = formatDateWithTime(Date())

            newProjectToUpdate = {...projectsToUpdate}

            newProjectToUpdate.projectCurrentRIBAStage.commentThread.push({commentDate: dateToStamp, comment:valueToUpdate, commentType: "Consultant", commentUser: userId, id: uniqueId})
            
            updateForm(newProjectToUpdate)

            setProjectsToUpdate(newProjectToUpdate)

            props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectCurrentRIBAStage.commentThread.push({commentDate: dateToStamp, comment:valueToUpdate, commentType: "Consultant", commentUser: userId, id: uniqueId})

            props.onClose()
        }

        if (props.fieldToUpdate === "projectManagementKPI") {

            uniqueId = uuidv4()

            newProjectToUpdate = {...projectsToUpdate}

            newProjectToUpdate.projectCurrentRIBAStage.clientKPIs.push({KPIDescription: valueToUpdate, KPITarget:"", KPIAchieved: "", KPIAchievedDate: "", id: uniqueId})
            
            updateForm(newProjectToUpdate)

            setProjectsToUpdate(newProjectToUpdate)

            props.arrayToShow.find(item => item.projectId === props.objectToUpdate).projectCurrentRIBAStage.clientKPIs.push({KPIDescription: valueToUpdate, KPITarget:"", KPIAchieved: "", KPIAchievedDate: "", id: uniqueId})

            props.onClose()
        }

        if (props.fieldToUpdate === "timesheetNotes" && props.collectionToUpdate === "timesheets") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetNotes = valueToUpdate            
            await updateForm({ ...timesheetsToUpdate, timesheetNotes: valueToUpdate})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetMonday" && props.collectionToUpdate === "timesheets") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetMonday = valueToUpdate
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetMonday))+Number(valueToUpdate)
            await updateForm({ ...timesheetsToUpdate, timesheetMonday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetTuesday" && props.collectionToUpdate === "timesheets") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetTuesday = valueToUpdate
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetTuesday))+Number(valueToUpdate)
            await updateForm({ ...timesheetsToUpdate, timesheetTuesday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetWednesday" && props.collectionToUpdate === "timesheets") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetWednesday = valueToUpdate
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetWednesday))+Number(valueToUpdate)
            await updateForm({ ...timesheetsToUpdate, timesheetWednesday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetThursday" && props.collectionToUpdate === "timesheets") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetThursday = valueToUpdate
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetThursday))+Number(valueToUpdate)
            await updateForm({ ...timesheetsToUpdate, timesheetThursday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetFriday" && props.collectionToUpdate === "timesheets") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetFriday = valueToUpdate
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetFriday))+Number(valueToUpdate)
            await updateForm({ ...timesheetsToUpdate, timesheetFriday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetSaturday" && props.collectionToUpdate === "timesheets") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetSaturday = valueToUpdate
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetSaturday))+Number(valueToUpdate)
            await updateForm({ ...timesheetsToUpdate, timesheetSaturday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetSunday" && props.collectionToUpdate === "timesheets") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetSunday = valueToUpdate
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetSunday))+Number(valueToUpdate)
            await updateForm({ ...timesheetsToUpdate, timesheetSunday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "cloneInvoice") {

            if (valueToUpdate.length>0){

                // Get invoice master data
                const invoiceMaster = props.arrayToShow.filter((invoice) => invoice.id === props.objectToUpdate)
               
                //Get the next invoice number
                var invoiceNum = 0
                if (props.arrayToShow.filter((item) => item.invoiceNumber.substring(0, 1) === "D").length > 0) {
                    invoiceNum = Number(props.arrayToShow.filter((item) => item.invoiceNumber.substring(0, 1) === "D").sort((item1, item2) => Number(item1.invoiceNumber.slice(1)) < Number(item2.invoiceNumber.slice(1)) ? -1 : Number(item1.invoiceNumber.slice(1)) > Number(item2.invoiceNumber.slice(1)) ? 1 : 0)[props.arrayToShow.filter((item) => item.invoiceNumber.substring(0, 1) === "D").length - 1].invoiceNumber.slice(1)) + 1
                    invoiceNum = "D" + invoiceNum
                } else {
                    invoiceNum = "D1"
                }

                //update the uuid on any invoiceLines
                if (invoiceMaster[0].invoiceLines.length>0){
                    var uniqueId = uuidv4()
                    for (let index = 0; index < invoiceMaster[0].invoiceLines.length; index++) {
                        uniqueId = uuidv4()
                        invoiceMaster[0].invoiceLines[index].invoiceLineItemId = uniqueId
                        invoiceMaster[0].invoiceLines[index].okToInvoice = "NO"
                    }
                }

                await axios.post(process.env.REACT_APP_API_URL + '/invoices', {...invoiceMaster[0], invoiceNumber: invoiceNum, invoiceDate: valueToUpdate, invoiceLines:invoiceMaster[0].invoiceLines, invoiceIssued:"NO", invoicePaid:"NO"})

                props.onClose()

            }else{
                window.alert("Please enter a date for the cloned invoice")
            }

        }

        if (props.fieldToUpdate === "footprintItemId") {
            
            //Check that the dates are valid
            if (fromDate!=="" && toDate!=="") {

                if (fromDate >= toDate) {

                    window.alert("Please ensure that your To Date appears after your From Date")

                }else{
            
                    axios.get(process.env.REACT_APP_API_URL + '/XeroPOsByDate/'+ fromDate + '/' + toDate)
                        .then(async (res) => {
                            var xeroRecords = res.data.PurchaseOrders
                            var subItemIndex, checkWord
                            var xeroRecordsToInsert=[]
                            var varFootprintItemID = props.objectToUpdate
                            var varFootprint = props.collectionToUpdate
                            var varSubItems = props.arrayToShow

                            xeroRecords.forEach(record => {

                                var comment = ""
                                for (subItemIndex = 0; subItemIndex < record.LineItems.length; subItemIndex++) {
                                                            
                                    if (record.LineItems[subItemIndex].Description !== undefined){

                                        for (checkWord = 0; checkWord < keyWordsCheck.length; checkWord++) {       
                                            if (record.LineItems[subItemIndex].Description.toLowerCase().includes(keyWordsCheck[checkWord].keyWord.toLowerCase())) {
                                                comment = keyWordsCheck[checkWord].subCategory
                                            }else{
                                                comment=varFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === varFootprintItemID).itemDescription
                                            }
                                        }
                                        xeroRecordsToInsert = [...xeroRecordsToInsert, {id:uuidv4(), footprintId: varFootprint.id, organisationId: varFootprint.organisationId, year: varFootprint.organisationFootprintYear, subCategory: varFootprint.organisationFootprintItems.find(footprintItem => footprintItem.id === varFootprintItemID).itemSubCategory, footprintItemId: varFootprintItemID, consumption: Number(record.LineItems[subItemIndex].LineAmount), unit: record.CurrencyCode, emissionFactor: 0, carbonEmissions: 0, description: record.LineItems[subItemIndex].Description, comments: comment, supplier: record.Contact.Name, facility: "", individual: "", fromPostCode: "", toPostCode: "", transportMode: "", multiplier: "0", emissionFactorSource: ""}]
                                    }
                                }
                            });

                            var newFootprintSubItems
                            newFootprintSubItems = [ ...varSubItems ]

                            newFootprintSubItems = newFootprintSubItems.concat(xeroRecordsToInsert)

                            props.onChange(newFootprintSubItems)

                            window.alert("Pulled in " + xeroRecordsToInsert.length + " records")

                            props.onClose()
                        })
                        .catch((err) => {
                            console.log(err);
                        }).finally(() => {
                            setLoading(false);
                        });
                }

              }else{
                window.alert("Please ensure that you have entered valid From/To Dates")
            }

        }       

        if (props.fieldToUpdate === "goalNotes") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalNotes = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalNotes: valueToUpdate})
            await props.onClose()
        }

        if (props.fieldToUpdate === "goalDetail") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalDetail = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalDetail: valueToUpdate})
            await props.onClose()
        }

        if (props.fieldToUpdate === "goalMonth1") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalMonth1 = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalMonth1: valueToUpdate})
            props.updateInvData()
            await props.onClose()
        }

        if (props.fieldToUpdate === "goalMonth2") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalMonth2 = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalMonth2: valueToUpdate})
            props.updateInvData()
            await props.onClose()
        }

        if (props.fieldToUpdate === "goalMonth3") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalMonth3 = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalMonth3: valueToUpdate})
            props.updateInvData()
            await props.onClose()
        }

        if (props.fieldToUpdate === "goalMonth4") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalMonth4 = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalMonth4: valueToUpdate})
            props.updateInvData()
            await props.onClose()
        }

        if (props.fieldToUpdate === "goalMonth5") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalMonth5 = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalMonth5: valueToUpdate})
            props.updateInvData()
            await props.onClose()
        }

        if (props.fieldToUpdate === "goalMonth6") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalMonth6 = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalMonth6: valueToUpdate})
            props.updateInvData()
            await props.onClose()
        }

        if (props.fieldToUpdate === "goalSummary") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalSummary = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalSummary: valueToUpdate})
            await props.onClose()
        }

        if (props.fieldToUpdate === "goalStatus") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalStatus = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalStatus: valueToUpdate})
            await props.onClose()
        }
        
        if (props.fieldToUpdate === "goalType") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, goalType: valueToUpdate})
            await props.onClose()
        }

        if (props.fieldToUpdate === "startDate") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).startDate = valueToUpdate
            await updateForm({ ...performanceGoalsToUpdate, startDate: valueToUpdate})
            props.dateChanged()
            await props.onClose()
        }

        if (props.fieldToUpdate === "techSkillStartDate") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).startDate = valueToUpdate
            await updateForm({ ...performanceTechSkillsToUpdate, startDate: valueToUpdate})
            props.skillDateChanged()
            await props.onClose()
        }

        if (props.fieldToUpdate === "techSkillAttainment") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).attainment = valueToUpdate
            await updateForm({ ...performanceTechSkillsToUpdate, attainment: valueToUpdate})
            await props.onClose()
        }

        if (props.fieldToUpdate === "techSkillGoalPerService") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).goalPerService = valueToUpdate
            await updateForm({ ...performanceTechSkillsToUpdate, goalPerService: valueToUpdate})
            await props.onClose()
        }

        if (props.fieldToUpdate === "rateEURGBP") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).rate = valueToUpdate
            await updateForm({ ...performanceTrackerExchangeRatesToUpdate, rate: Number(valueToUpdate)})
            props.exchRateChanged()
            await props.onClose()
        }

        if (props.fieldToUpdate === "siteElectricityReadingValue") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).readingValue = Number(valueToUpdate)
            await updateForm(props.arrayToShow.find(item => item.id === props.objectToUpdate))
            await props.onClose()
        }

    }

    const updateForm = async (form) => {

        if (props.collectionToUpdate === "invoices") {

            await axios.put(process.env.REACT_APP_API_URL + '/invoices/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "projects_services") {

            await axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "projects") {

            await axios.put(process.env.REACT_APP_API_URL + '/projects/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "events_footprints") {

            await axios.put(process.env.REACT_APP_API_URL + '/events_footprints/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "organisations_footprints") {

            await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "buildings_BREEAM_assessments") {

            await axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "timesheets") {

            await axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "performance_tracker_goals") {

          await axios.put(process.env.REACT_APP_API_URL + '/performance_tracker_goals/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "performance_tracker_tech_skills") {

            await axios.put(process.env.REACT_APP_API_URL + '/performance_tracker_tech_skills/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "performance_tracker_exchange_rates") {

            await axios.put(process.env.REACT_APP_API_URL + '/performance_tracker_exchange_rates/' + props.objectToUpdate, form)

        }

        if (props.collectionToUpdate === "site_logs_electricity") {

            await axios.put(process.env.REACT_APP_API_URL + '/site_log_electricity/' + props.objectToUpdate, form)

        }

    }

    const getInvoiceData = async () => {

        //If its for the invoice line transfer function then
        if (props.fieldToUpdate !== "invoiceNumber"){

            await axios.get(process.env.REACT_APP_API_URL + '/invoices/' + props.objectToUpdate)
                .then((res) => {
                    setInvoicesToUpdate(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            await axios.get(process.env.REACT_APP_API_URL+'/invoices_by_project/'+ props.invoiceProjectId)
                .then((res) => {
                    let iCount = 0
                    var resCopy = res.data.filter((invoice) => stringToDate(invoice.invoiceDate,"YYYY-MM-DD","-") > stringToDate(props.valueToUpdate,"YYYY-MM-DD","-") && invoice.invoiceIssued==="NO").sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)
                    resCopy.forEach(item => {
                        resCopy[iCount].invoiceDateToDisplay = formatInvoiceDate(item.invoiceDate)
                        resCopy[iCount].newInvoiceDate = item.invoiceDate
                        iCount++
                    });
                    setMultipleInvoicesToUpdate(resCopy)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }

    }

    useEffect(() => {

        const newDate = new Date()
        setTodaysDate(formatDate(newDate))

        setLoading(true)
        setUsersLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/users_internal_only')
            .then((res) => {
                setUsers(res.data.sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                setUserId(res.data.find(user => user.emailId === AuthService.getCurrentUserName()).id)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

        if (props.collectionToUpdate !== "invoices" && 
        props.collectionToUpdate !== "projects_services" && 
        props.collectionToUpdate !== "projects" && 
        props.collectionToUpdate !== "events_footprints" && 
        props.collectionToUpdate !== "organisations_footprints" &&
        props.collectionToUpdate !== "timesheets") {

            setLoading(false)
        }


        if (props.collectionToUpdate === "invoices") {

            getInvoiceData()

        }

        if (props.collectionToUpdate === "projects_services") {

            axios.get(process.env.REACT_APP_API_URL + '/projects_services/' + props.objectToUpdate)
                .then((res) => {
                    setServicesToUpdate(res.data)
                    setServiceClosedDate(res.data.serviceClosedDate)
                    setServiceLiveDate(res.data.serviceLiveDate)
                    setServiceWinPendingDate(res.data.serviceWinPendingDate)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            setClientsLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/clients')
                .then((res) => {
                    setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setClientsLoading(false);
                });

            if (props.fieldToUpdate==="dealNumber"){

                axios.post(process.env.REACT_APP_API_URL + '/hubspotDealsFiltered')
                .then((response) => {
                    setDeals(response.data.results)
                    setValueToUpdateForReactSelect({value: props.valueToUpdate, label: props.valueToUpdate + " - " + props.arrayToShow.find(item => item.id === props.objectToUpdate).dealName})
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            }

            if (props.fieldToUpdate==="serviceInvoiceClient"){

                setContactsLoading(true)

                axios.get(process.env.REACT_APP_API_URL + '/contacts')
                .then((res) => {
                    setContacts(res.data.sort((item1, item2) => item1.contactName + item1.contactName < item2.contactName + item2.contactName ? -1 : 0))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setContactsLoading(false);
                });
                setServiceInvoiceClientContact(props.arrayToShow.find(item => item.id === props.objectToUpdate).serviceInvoiceClientContact)
            }

        }

        if (props.fieldToUpdate==="projectClient" || props.fieldToUpdate==="projectManagementContractor"){

            setContactsLoading(true)
            setClientsLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/clients')
            .then((res) => {
                setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setClientsLoading(false);
            });

            axios.get(process.env.REACT_APP_API_URL + '/contacts')
            .then((res) => {
                setContacts(res.data.sort((item1, item2) => item1.contactName + item1.contactName < item2.contactName + item2.contactName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setContactsLoading(false);
            });
            setProjectClientContact(props.arrayToShow[0].projectClientContactId)
        }

        if (props.collectionToUpdate === "projects") {

            axios.get(process.env.REACT_APP_API_URL + '/projects/' + props.objectToUpdate)
                .then((res) => {
                    setProjectsToUpdate(res.data)
                    setProjectClosedDate(res.data.projectClosedDate)
                    setProjectLiveDate(res.data.projectLiveDate)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            setContacts(props.contacts)

            if (props.fieldToUpdate==="dealNumberSeed"){

                axios.post(process.env.REACT_APP_API_URL + '/hubspotDealsFiltered')
                .then((response) => {
                    setDeals(response.data.results)
                    setValueToUpdateForReactSelect({value: props.valueToUpdate, label: props.valueToUpdate + " - " + props.arrayToShow.find(item => item.id === props.objectToUpdate).dealNumberSeedName})
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            }
        }
        
        if (props.collectionToUpdate === "events_footprints") {

            axios.get(process.env.REACT_APP_API_URL + '/events_footprints/' + props.objectToUpdate)
                .then((res) => {
                    setEventFootprintsToUpdate(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }

        if (props.collectionToUpdate === "organisations_footprints") {

            axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints/' + props.objectToUpdate)
                .then((res) => {
                    setOrganisationFootprintsToUpdate(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }

        if (props.fieldToUpdate==="commentThread" && props.role !== 3001 && props.role !== 6001) {

            setContactsLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/contacts')
            .then((res) => {
                setContacts(res.data.sort((item1, item2) => item1.contactName + item1.contactName < item2.contactName + item2.contactName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setContactsLoading(false);
            });

        }

        if (props.collectionToUpdate === "buildings_BREEAM_assessments") {

            axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + props.objectToUpdate)
                .then((res) => {
                    setBREEAMAssessmentsToUpdate(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }

        if (props.collectionToUpdate === "timesheets") {

            axios.get(process.env.REACT_APP_API_URL + '/timesheets/' + props.objectToUpdate)
                .then((res) => {
                    setTimesheetsToUpdate(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }

        if (props.collectionToUpdate === "invoices" && props.fieldToUpdate === "invoiceNumber") {
    
            axios.get(process.env.REACT_APP_API_URL+'/invoices_by_project/'+ match.params.projectId)
                .then((res) => {
                    // setInvoices(res.data.filter(item => item.invoiceNumber.substring(0,1) === "D"))
                    setInvoices(res.data.filter(item => item.invoiceIssued === "NO"))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }

        if (props.fieldToUpdate.slice(0,11) === "dataQuality" || props.fieldToUpdate === "emissionsFactorTechnology" || props.fieldToUpdate === "emissionsFactorTime" || props.fieldToUpdate === "emissionsFactorGeography" || props.fieldToUpdate === "emissionsFactorCompleteness" || props.fieldToUpdate === "emissionsFactorReliability" ) {
    
            setUncertaintyCategoriesLoading(true)
            
            axios.get(process.env.REACT_APP_API_URL + '/select_options_footprints_uncertainty_categories')
            .then((res) => {
                setUncertaintyCategories(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUncertaintyCategoriesLoading(false);
            });

        }

        if (props.collectionToUpdate === "performance_tracker_goals") {

          axios.get(process.env.REACT_APP_API_URL + '/performance_tracker_goals/' + props.objectToUpdate)
              .then((res) => {
                  setPerformanceGoalsToUpdate(res.data)
              })
              .catch((err) => {
                  console.log(err);
              }).finally(() => {
                  setLoading(false);
              });
        }

        if (props.collectionToUpdate === "performance_tracker_tech_skills") {

            axios.get(process.env.REACT_APP_API_URL + '/performance_tracker_tech_skills/' + props.objectToUpdate)
                .then((res) => {
                    setPerformanceTechSkillsToUpdate(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }

        if (props.collectionToUpdate === "performance_tracker_exchange_rates") {

            axios.get(process.env.REACT_APP_API_URL + '/performance_tracker_exchange_rates/' + props.objectToUpdate)
                .then((res) => {
                    setPerformanceTrackerExchangeRatesToUpdate(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }

        setValueToUpdate(props.valueToUpdate)

    }, [props.objectToUpdate, props.valueToUpdate, props.collectionToUpdate, props.show, props.arrayToShow, props.fieldToUpdate, props.contacts])

    useEffect(() => {

        if (Date(valueToUpdate)){

            const date1 = new Date(invoicesToUpdate.invoiceDate);
            const date2 = new Date(valueToUpdate);
            const diffTime = Math.abs(date2 - date1);
            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (multipleInvoicesToUpdate.length>0){

                if (date1<date2){ //shift will be back in time
                    setCalculatedText("Would you also like to move these invoices forward by ("+ diffDays +" days)?")
                }else if (date1>date2){
                    setCalculatedText("Would you also like to move these invoices back by ("+ diffDays +" days)?")
                    diffDays = diffDays*-1
                }else{
                    setCalculatedText("")
                }
    
                //loop through the records to add the days
                let iCount = 0
                var multipleInvoicesToUpdateCopy = multipleInvoicesToUpdate
                multipleInvoicesToUpdateCopy.forEach(item => {
                    let newInvoiceDate = moment(item.invoiceDate).add(diffDays, 'days').locale('en').format("YYYY-MM-DD")
                    multipleInvoicesToUpdateCopy[iCount].newInvoiceDate = newInvoiceDate
                    iCount++
                 });
                setMultipleInvoicesToUpdate(multipleInvoicesToUpdateCopy)

            }

        }

    }, [valueToUpdate, invoicesToUpdate.invoiceDate])

    const handleChange=(e)=>{
        const { name, checked }= e.target;
        if (name==="allselect")
        {
            const checkedValue = multipleInvoicesToUpdate.map( (item)=>{return{ ...item, isChecked:checked}})
            setMultipleInvoicesToUpdate(checkedValue)
        }else{
            const checkedValue = multipleInvoicesToUpdate.map( (item)=>
            item.id===name? {...item, isChecked:checked}:item)
            setMultipleInvoicesToUpdate(checkedValue)
        }

    }

    const handleDateChange=(e)=>{
        const { name } = e.target;
        const dateValue = multipleInvoicesToUpdate.map( (item)=>
        item.id===name? {...item, newInvoiceDate:e.target.value}:item)
        setMultipleInvoicesToUpdate(dateValue)
    }

    const updatePMOnFutureInvoices=(e)=>{
        const { name, checked }= e.target;

        console.log(e.target.checked)

        if (e.target.checked) {
            setUpdateProjectManagerOnFutureInvoices("YES")
        } else{
            setUpdateProjectManagerOnFutureInvoices("NO")
        }
    }

    const updateSMOnFutureInvoices=(e)=>{
        const { name, checked }= e.target;

        console.log(e.target.checked)

        if (e.target.checked) {
            setUpdateServiceManagerOnFutureInvoices("YES")
        } else{
            setUpdateServiceManagerOnFutureInvoices("NO")
        }
    }

    const updateConsultantOrQAForAllItems=(e)=>{
        const { name, checked }= e.target;

        console.log(e.target.checked)

        if (e.target.checked) {
            setUpdateConsultantForAllItems("YES")
        } else{
            setUpdateConsultantForAllItems("NO")
        }
    }

    const updateServiceStatusesForAllItems=(e)=>{
        const { name, checked }= e.target;

        console.log(e.target.checked)

        if (e.target.checked) {
            setUpdateServiceStatusForAllItems("YES")
        } else{
            setUpdateServiceStatusForAllItems("NO")
        }
    }

    if (!props.show) {
        return null
    }
    return (
        <div className="update-value-modal z-50">
            <div className="update-value-modal-content">
                <div className="update-value-modal-header">
                    <p className="update-value-modal-title text-congress-blue text-xl">{props.title}</p>
                </div>
                <div className="update-value-modal-body">
                    <form className="" onSubmit={onSubmit}>
                    {/* <form className="add-form-8A" onSubmit={onSubmit}> */}
                        {props.fieldToUpdate === "invoiceLineStatus" &&

                            <div>
                                <label className="text-center">Invoice Line Status: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "Green" || valueToUpdate === "Amber" || valueToUpdate === "Red") && <option value="Green">Green</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "Green" || valueToUpdate === "Amber" || valueToUpdate === "Red") && <option value="Amber">Amber</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "Green" || valueToUpdate === "Amber" || valueToUpdate === "Red") && <option value="Red">Red</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "Third Party Fee" || valueToUpdate === "Sub-contractor Fee" || valueToUpdate === "Project Expenses") && <option value="Third Party Fee">Third Party Fee</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "Third Party Fee" || valueToUpdate === "Sub-contractor Fee" || valueToUpdate === "Project Expenses") && <option value="Sub-contractor Fee">Sub-contractor Fee</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "Third Party Fee" || valueToUpdate === "Sub-contractor Fee" || valueToUpdate === "Project Expenses") && <option value="Project Expenses">Project Expenses</option>}
                                </select>
                            </div>

                        }

                        {props.fieldToUpdate === "invoiceLineCommentsFromTracker" &&
                            <div>
                            <label className="text-center">Invoice Line Comment: </label>
                            {props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).projectNumber !== undefined &&
                                <div>
                                    <div className="text-congress-blue font-bold pt-2">{props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).projectNumber}</div>
                                    <div className="text-congress-blue font-bold">{props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).projectName}</div>
                                    <div className="text-congress-blue font-bold">{props.arrayToShow.find(item => item.invoiceLineItemId === props.arrayIdToUpdate).serviceDescription}</div>
                                </div>
                            }   
                            <div className="form-control-8A w-full p-2">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Comments Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            <br></br>
                            <br></br>
                            </div>

                        }

                            {props.fieldToUpdate === "invoiceLineComments" &&
                            <div>
                            <label className="text-center">Invoice Line Comment: </label>
                            <div className="form-control-8A w-full p-2">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Comments Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            <br></br>
                            <br></br>
                            </div>

                        }

                        {props.fieldToUpdate === "value" &&
                            <div>
                                <label className="text-center">Invoice Line Value: </label>
                            <div className="form-control-8A">
                                <input type="number" step="0.0001" placeholder="Enter Invoice Line Value Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "invoiceLineDescription" &&
                            <div>
                                 <label className="text-center">Invoice Line Description: (Characters: {valueToUpdate.length})</label>
                            <div className="form-control-8A">
                                {/* <input type="text" placeholder="Enter Invoice Line Description Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} /> */}
                                <textarea className="w-full h-32" type="text" placeholder="Enter Invoice Line Description Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>
                        }

                        {props.fieldToUpdate === "invoiceNumber" &&
                            <div>
                                 <label className="text-center">Select the invoice that you would like to move this invoice line to: </label>
                            <div className="form-control-8A">
                                {/* <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}> */}
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {props.arrayToShow.filter(item => item.invoiceIssued === "NO").sort((item1, item2) => item1.invoiceNumber < item2.invoiceNumber ? -1 : item1.invoiceNumber > item2.invoiceNumber ? 1 : 0).map((invoice) => (
                                        <option key={invoice.id} value={invoice.id}>{invoice.invoiceNumber}</option>
                                    ))}
                                </select>
                            </div>
                            </div>
                        }

                        {props.fieldToUpdate === "invoiceLineProjectManager" &&
                            <div>
                                <div>
                                    <label className="text-center">Project Manager: </label>
                                    <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                        {users.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).map((user) => (
                                            <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='pt-2 pb-10'>
                                    <label>Would you like to update the Project Manager for this project on all future unissued invoices?</label>
                                    <div className='pl-2 inline'>
                                        <input type="checkbox" id="cascadePM" name="cascadePM" defaultChecked={true} onChange={updatePMOnFutureInvoices}/>
                                    </div>
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "invoiceLineServiceManager" &&
                        <div>
                            <div>
                                <label className="text-center">Service Manager: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {users.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).map((user) => (
                                        <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                    ))}
                                </select>
                            </div>

                            <div className='pt-2 pb-10'>
                                <label>Would you like to update the Service Manager for this service on all future unissued invoices?</label>
                                <div className='pl-2 inline'>
                                    <input type="checkbox" id="cascadeSM" name="cascadeSM" defaultChecked={true} onChange={updateSMOnFutureInvoices} />
                                </div>
                            </div>

                        </div>
                        }

                        {props.fieldToUpdate === "projectManagerId" &&
                            <div>
                                <label className="text-center">Project Manager: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {users.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).map((user) => (
                                        <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                    ))}
                                </select>
                                {/* <div className='pt-2 pb-10'>
                                    <label>Would you like to update the Project Manager for all future unissued invoices?</label>
                                    <div className='pl-2 inline'>
                                        <input type="checkbox" id="cascadePM" name="cascadePM" onChange={updatePMOnFutureInvoices}/>
                                    </div>
                                </div> */}
                            </div>
                        }

                        {props.fieldToUpdate === "projectClientContactId" &&
                            <div>
                                <label className="text-center">Client Contact: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {contacts.map((contact) => (
                                        <option key={contact.id} value={contact.id}>{contact.contactName}</option>
                                    ))}
                                </select>
                            </div>
                        }

                        {props.fieldToUpdate === "BREEAMAssessmentComments" &&
                            <div>
                                <label className="text-center">BREEAM Assessment Notes: </label>
                                <div className="form-control-8A">
                                    <textarea className="w-full h-32" type="text" placeholder="Enter Project Name Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "projectName" &&
                            <div>
                                <label className="text-center">Project Name: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Project Name Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "projectManagementKPIDescription" &&
                            <div>
                                <label className="text-center">KPI: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter KPI Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "projectManagementKPITarget" &&
                            <div>
                                <label className="text-center">KPI Rating Target: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter KPI Target Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "projectManagementKPIAchieved" &&
                            <div>
                                <label className="text-center">KPI Rating Achieved: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Rating Achieved Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "projectManagementKPIAchievedDate" &&
                            <div>
                                <div className = "pb-3">
                                    <label className="text-center">Achieved Date: </label><input type="date" placeholder="Event Date" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "projectManagementProgressComments" &&
                            <div>
                                <label className="text-center">Progress Comments: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Progress Comments Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "projectManagementStage" &&
                            <div>
                                <label className="text-center">Project Management Stage: </label>
                            <div className="form-control-8A">
                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="0 - Strategic definition">0 - Strategic definition</option>
                                <option value="1 - Preparation and briefing">1 - Preparation and briefing</option>
                                <option value="2 - Concept design">2 - Concept design</option>
                                <option value="3 - Spatial coordination">3 - Spatial coordination</option>
                                <option value="4 - Technical design">4 - Technical design</option>
                                <option value="5 - Manufacturing and construction">5 - Manufacturing and construction</option>
                                <option value="6 - Handover">6 - Handover</option>
                                <option value="7 - Use">7 - Use</option>
                            </select>
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "projectDescription" &&
                            <div>
                                <label className="text-center">Project Description: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Project Description Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "projectInvoicingNotes" &&
                            <div>
                                <label className="text-center">Project Invoicing Notes: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Project Invoicing Notes Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "projectLocation" &&
                            <div>
                                <label className="text-center">BD Notes: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter BD Notes Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "organisationFootprintSummaryComments" &&
                            <div>
                                <label className="text-center">Organisation Footprint Summary: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Organisation Footprint Notes Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "organisationFootprintNotes" &&
                            <div>
                                <label className="text-center">Organisation Footprint Notes: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Organisation Footprint Notes Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "organisationFootprintInternalNotes" &&
                            <div>
                                <label className="text-center">Organisation Footprint Internal Notes: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-96" type="text" placeholder="Enter Organisation Footprint Inernal Notes Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "organisationFootprintProjectNumber" &&
                            <div>
                                <label className="text-center">Organisation Footprint Project Number: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Organisation Footprint Project Number Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "organisationFootprintScope1Comments" &&
                            <div>
                                <label className="text-center">Organisation Footprint Scope 1 Summary: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Organisation Footprint Scope 1 Notes Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "organisationFootprintScope2Comments" &&
                            <div>
                                <label className="text-center">Organisation Footprint Scope 2 Summary: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Organisation Footprint Scope 2 Notes Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "organisationFootprintScope3Comments" &&
                            <div>
                                <label className="text-center">Organisation Footprint Scope 3 Summary: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Organisation Footprint Scope 3 Notes Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "itemConsultantComment" &&
                            <div>
                                <label className="text-center">Consultant Comments: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Consultant Comments Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "itemCommentForReport" &&
                            <div>
                                <label className="text-center">Organisation Sub Category Notes: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Organisation Sub Category Notes Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "itemConsultantCommentForDataCollection" &&
                            <div>
                                <label className="text-center">Consultant Comment: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Consultant Comment Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "submitOrganisationFootprintItem" &&
                            <div>
                                <label className="text-center">Submission Comment: </label>
                                <div className="form-control-8A">
                                    <textarea className="w-full h-32" type="text" placeholder="Enter Submission Comment Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "commentThread" &&
                            <div>
                                <label className="text-center">Comment: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Comment Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            {/* {props.role !== 3001 &&
                            <div className="pb-2">
                            <input type="checkbox" id="QA" />
                            <span className="pl-2">Internal QA Comment</span>
                            </div> 
                            } */}
                            
                            </div>

                        }

                        {props.fieldToUpdate === "projectManagementCommentThread" &&
                            <div>
                                <label className="text-center">Comment: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter Comment Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            
                            </div>

                        }

                        {props.fieldToUpdate === "projectManagementKPI" &&
                            <div>
                                <label className="text-center">KPI: </label>
                            <div className="form-control-8A">
                                <textarea className="w-full h-32" type="text" placeholder="Enter KPI Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            
                            </div>

                        }

                        {props.fieldToUpdate === "projectBillingPlanAndFeesChecked" &&

                        <div>
                            <label className="text-center">Fees Checked: </label>
                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        }

                        {props.fieldToUpdate === "projectInvoicingChecked" &&

                        <div>
                            <label className="text-center">Appointment Checked: </label>
                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        }

                        {props.fieldToUpdate === "serviceStatus" && todaysDate !== "" &&
                            <div>
                                <label className="text-center">Service Status: </label>
                                {/* <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => {setValueToUpdate(e.target.value); setServiceLiveDate(e.target.value === "Live" ? todaysDate : "" ); setServiceClosedDate(e.target.value.slice(0,6) === "Closed" ? todaysDate : "")}}>
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate.slice(0,5) === "Quote") && <option value="Quote-qualified">Quote-qualified</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate.slice(0,5) === "Quote") && <option value="Quote-QA">Quote-QA</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate.slice(0,5) === "Quote") && <option value="Quote-win-pending">Quote-win-pending</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate.slice(0,5) === "Quote") && <option value="Quote-contract-pending">Quote-contract-pending</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "Live") && <option value="Live">Live</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "On-hold" || valueToUpdate === "Live") && <option value="On-hold">On-hold</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "Live" || valueToUpdate === "Closed-completed") && <option value="Closed-completed">Closed-completed</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "Live" || valueToUpdate === "Closed-cancelled") && <option value="Closed-cancelled">Closed-cancelled</option>}
                                    {(props.role.toString().slice(0,1) === "2") && <option value="Closed-lost">Closed-lost</option>}
                                </select> */}
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => {setValueToUpdate(e.target.value); setServiceWinPendingDate(e.target.value === "Quote-win-pending" ? todaysDate : "" ); setServiceLiveDate(e.target.value === "Live" ? todaysDate : "" ); setServiceClosedDate(e.target.value.slice(0,6) === "Closed" ? todaysDate : "")}}>
                                    {(props.userDepartment === "Leadership Team" || props.userDepartment === "Business Development" || props.userDepartment === "System Development" || props.userDepartment === "Directors" || valueToUpdate === "Quote-qualified") && <option value="Quote-qualified">Quote-qualified</option>}
                                    {(props.userDepartment === "Leadership Team" || props.userDepartment === "Business Development" || props.userDepartment === "System Development" || props.userDepartment === "Directors" || valueToUpdate === "Quote-QA") && <option value="Quote-QA">Quote-QA</option>}
                                    {(props.userDepartment === "Leadership Team" || props.userDepartment === "Business Development" || props.userDepartment === "System Development" || props.userDepartment === "Directors" || valueToUpdate === "Quote-win-pending") && <option value="Quote-win-pending">Quote-win-pending</option>}
                                    {(props.userDepartment === "Leadership Team" || props.userDepartment === "Business Development" || props.userDepartment === "System Development" || props.userDepartment === "Directors" || valueToUpdate === "Quote-contract-pending") && <option value="Quote-contract-pending">Quote-contract-pending</option>}
                                    {(props.userDepartment === "Leadership Team" || props.userDepartment === "Business Development" || props.userDepartment === "System Development" || props.userDepartment === "Directors" || valueToUpdate === "Live") && <option value="Live">Live</option>}
                                    {(props.role.toString().slice(0,1) === "2" || valueToUpdate === "On-hold" || valueToUpdate === "Live") && <option value="On-hold">On-hold</option>}
                                    {(valueToUpdate === "Live" || valueToUpdate === "Closed-completed") && <option value="Closed-completed">Closed-completed</option>}
                                    {(valueToUpdate === "Live" || valueToUpdate === "Closed-cancelled") && <option value="Closed-cancelled">Closed-cancelled</option>}
                                    {(props.role.toString().slice(0,1) === "2") && <option value="Closed-lost">Closed-lost</option>}
                                </select>
                                {valueToUpdate === "Live" && <div>{"Live Date: "}<input type="date" placeholder="Live Date" value={serviceLiveDate} onChange={(e) => setServiceLiveDate(e.target.value)} /></div>}
                                {valueToUpdate === "Live" && servicesToUpdate.serviceLiveDate !== "" && servicesToUpdate.serviceLiveDate !== serviceLiveDate && <div><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>{"Prior Live Date: "}<input type="date" disabled={true} value={servicesToUpdate.serviceLiveDate} /></div>}
                                
                                {valueToUpdate === "Quote-win-pending" && <div>{"Quote-win-pending Date: "}<input type="date" placeholder="Quote-win-pending Date" value={serviceWinPendingDate} onChange={(e) => setServiceWinPendingDate(e.target.value)} /></div>}
                                {valueToUpdate === "Quote-win-pending" && servicesToUpdate.serviceWinPendingDate !== "" && servicesToUpdate.serviceWinPendingDate !== serviceWinPendingDate && <div><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>{"Prior Quote-win-pending Date: "}<input type="date" disabled={true} value={servicesToUpdate.serviceWinPendingDate} /></div>}
                                
                                {valueToUpdate.slice(0,6) === "Closed" && <div>{"Closed Date: " + serviceClosedDate}</div>}

                                <div className='pt-2 pb-10'>
                                <label>Update the Status for all services against this Opportunity?</label>
                                <div className='pl-2 inline'>
                                    <input type="checkbox" id="cascadeServiceStatus" name="cascadeServiceStatus" defaultChecked={false} onChange={updateServiceStatusesForAllItems} />
                                </div>
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "projectStatus" &&
                            <div>
                                <label className="text-center">Project Status: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => {setValueToUpdate(e.target.value); setProjectLiveDate(e.target.value === "Live" ? todaysDate : "" ); setProjectClosedDate(e.target.value.slice(0,6) === "Closed" ? todaysDate : "")}}>
                                    <option value="Quote">Quote</option>
                                    <option value="Live">Live</option>
                                    <option value="On-hold">On-hold</option>
                                    <option value="Closed-completed">Closed-completed</option>
                                    <option value="Closed-cancelled">Closed-cancelled</option>
                                    <option value="Closed-lost">Closed-lost</option>
                                </select>
                            </div>

                        }

                        {props.fieldToUpdate === "dealNumberSeed" &&
                            <div className="pb-5">
                                <label className="text-center">Hubspot Deal Seed #: </label>
                                {/* <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                <option value="">Please Select...</option>
                                {deals.map((deal) => (                                    
                                    <option key={deal.id} value={deal.id} > {deal.id + ' - ' + deal.properties.dealname}</option>
                                ))}
                            </select> */}
                            <ReactSelect
                            styles={styles}
                            value={valueToUpdateForReactSelect}
                            onChange={setValueToUpdateForReactSelect}
                            options={deals.map((deal) => {
                                return {
                                    label: deal.id + ' - ' + deal.properties.dealname,
                                    value: deal.id
                                }
                            })} />
                            </div>

                        }

                        {props.fieldToUpdate === "dealNumber" &&
                            <div className="pb-5">
                                <label className="text-center">Hubspot Deal #: </label>
                                {/* <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => e.target.value ? setValueToUpdate(e.target.value) : setValueToUpdate("")}>
                                <option value="">Please Select...</option>
                                {deals.map((deal) => (                                    
                                    <option key={deal.id} value={deal.id} > {deal.id + ' - ' + deal.properties.dealname}</option>
                                ))}
                            </select> */}
                            <ReactSelect
                            styles={styles}
                            value={valueToUpdateForReactSelect}
                            onChange={setValueToUpdateForReactSelect}
                            options={deals.map((deal) => {
                                return {
                                    label: deal.id + ' - ' + deal.properties.dealname,
                                    value: deal.id
                                }
                            })} />
                            </div>

                        }

                        {props.fieldToUpdate === "serviceManager" &&
                            <div>
                                <label className="text-center">Service Manager: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {users.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).map((user) => (
                                        <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                    ))}
                                </select>
                                {/* <div className='pt-2 pb-10'>
                                    <label>Would you like to update the Service Manager for this service on all future unissued invoices?</label>
                                    <div className='pl-2 inline'>
                                        <input type="checkbox" id="cascadeSM" name="cascadeSM" onChange={updateSMOnFutureInvoices} />
                                    </div>
                                </div> */}
                            </div>

                        }

                        {props.fieldToUpdate === "serviceComments" &&
                            <div>
                                <label className="text-center">Service Comments: </label>
                            <div className="form-control-8A">
                                {/* <input type="text" placeholder="Enter Invoice Line Description Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} /> */}
                                <textarea className="w-full h-32" type="text" placeholder="Enter Service Comments Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "customerPo" &&
                            <div className="form-control-8A">
                                <label>Client PO</label>
                                <div className="form-control-8A">
                                    <textarea className="w-full h-full" type="text" placeholder="Enter Client PO here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "serviceInvoiceClient" &&
                            <div>
                                {/* <div className="form-control-8A">
                                    <label>Client PO</label>
                                    <div className="form-control-8A">
                                        <textarea className="w-full h-full" type="text" placeholder="Enter Client PO here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    </div>
                                </div> */}
                                <div className="form-control-8A">
                                    <label>Client to Invoice</label>
                                    <select value={valueToUpdate} onChange={(e) => {setValueToUpdate(e.target.value); setServiceInvoiceClientContact(contacts.filter(item => item.clientId === e.target.value)[0] ? contacts.filter(item => item.clientId === e.target.value)[0].id : "") }} onClick={(e) => {setValueToUpdate(e.target.value); setServiceInvoiceClientContact(contacts.filter(item => item.clientId === e.target.value)[0] ? contacts.filter(item => item.clientId === e.target.value)[0].id : "") }}>
                                        <option value="">Please Select...</option>
                                            {clients.map((client) => (
                                                <option key={client.id} value={client.id}>{client.clientName}</option>
                                            ))}
                                    </select>
                                </div>
                                <div className="form-control-8A">
                                    <label>Client Contact</label>
                                    <select value={serviceInvoiceClientContact} onChange={(e) => setServiceInvoiceClientContact(e.target.value)}>
                                        {/* <option key={serviceInvoiceClientContact} value={serviceInvoiceClientContact}>{serviceInvoiceClientContact}</option> */}
                                        {contacts.filter(item => item.clientId === valueToUpdate).map((contact) => (
                                            <option key={contact.id} value={contact.id}>{contact.contactName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "projectClient" &&
                            <div>
                                <div className="form-control-8A">
                                    <label>Project Client</label>
                                    <select value={valueToUpdate} onChange={(e) => {setValueToUpdate(e.target.value); setProjectClientContact(contacts.filter(item => item.clientId === e.target.value)[0] ? contacts.filter(item => item.clientId === e.target.value)[0].id : "") }} onClick={(e) => {setValueToUpdate(e.target.value); setProjectClientContact(contacts.filter(item => item.clientId === e.target.value)[0] ? contacts.filter(item => item.clientId === e.target.value)[0].id : "") }}>
                                        <option value="">Please Select...</option>
                                            {clients.map((client) => (
                                                <option key={client.id} value={client.id}>{client.clientName}</option>
                                            ))}
                                    </select>
                                </div>
                                <div className="form-control-8A">
                                    <label>Client Contact</label>
                                    <select value={projectClientContact} onChange={(e) => setProjectClientContact(e.target.value)}>
                                        {/* <option key={serviceInvoiceClientContact} value={serviceInvoiceClientContact}>{serviceInvoiceClientContact}</option> */}
                                        {contacts.filter(item => item.clientId === valueToUpdate).map((contact) => (
                                            <option key={contact.id} value={contact.id}>{contact.contactName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "projectManagementContractor" &&

                            <div className="form-control-8A">
                                <label>Project Contractor</label>
                                <select value={valueToUpdate} onChange={(e) => {setValueToUpdate(e.target.value)}}>
                                    <option value="">Please Select...</option>
                                        {clients.map((client) => (
                                            <option key={client.id} value={client.id}>{client.clientName}</option>
                                        ))}
                                </select>
                            </div>

                        }

                        {props.fieldToUpdate === "okToInvoice" &&

                            <div>
                                <label className="text-center">SM Approved: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </select>
                            </div>

                        }

                        {props.fieldToUpdate === "evidenceDocumentFilename" && typeof valueToUpdate === "string" &&

                        <div className="pb-3">
                        <div className="pb-3">
                        {props.selectionOptions.map((item) => (
                        <div key={item.filename}>
                            <input
                            type="checkbox"
                            checked={valueToUpdate === item.filename || valueToUpdate.includes("| " + item.filename + " |") || valueToUpdate.slice(0, item.filename.length + 2) === item.filename + " |" || valueToUpdate.slice(valueToUpdate.length - (item.filename.length + 2), valueToUpdate.length) === "| " + item.filename ? true : false}
                            onChange={() => setConcatinatedString(item.filename, valueToUpdate === item.filename || valueToUpdate.includes("| " + item.filename + " |") || valueToUpdate.slice(0, item.filename.length + 2) === item.filename + " |" || valueToUpdate.slice(valueToUpdate.length - (item.filename.length + 2), valueToUpdate.length) === "| " + item.filename ? false : true)}
                            />
                            <label className="pl-2">{item.filename}</label>
                        </div>
                        ))}
                        </div>
                        <label className="pt-3">{valueToUpdate}</label>
                        </div>
                        }

                        {(props.fieldToUpdate === "Team Member Name" || props.fieldToUpdate === "Team Member Company" || props.fieldToUpdate === "Team Member Email" || props.fieldToUpdate === "Team Member Tel" || props.fieldToUpdate === "BREEAM Stage Event") &&

                        <div>
                            <div>
                                <label className="text-center">{props.fieldToUpdate + ":"}</label>
                                <div className="form-control-8A">
                                    <input type="text" placeholder="Enter Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>

                        </div>

                        }

                        {props.fieldToUpdate === "BREEAM Stage Event Date" &&
                        <div>
                            <div className = "pb-3">
                                <label className="text-center">Event Date: </label><input type="date" placeholder="Event Date" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>
                        }

                        {props.fieldToUpdate === "BREEAM Stage Event Reminder Date" &&
                        <div>
                            <div className = "pb-3">
                                <label className="text-center">Event Reminder Date: </label><input type="date" placeholder="Event Reminder Date" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>
                        }

                        {props.fieldToUpdate === "BREEAM Stage Event Owner" &&

                        <div>
                            <label className="text-center">Stage Owner: </label>
                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Project Manager">Project Manager</option>
                                <option value="Client">Client</option>
                                <option value="Architect">Architect</option>
                                <option value="M&E">M&E</option>
                                <option value="Contractor">Contractor</option>
                                <option value="Civil Engineer">Civil Engineer</option>
                                <option value="Ecologist">Ecologist</option>
                                <option value="Specialist Consultant">Specialist Consultant</option>
                                <option value="Eight Versa">Eight Versa</option>
                            </select>
                        </div>

                        }

                        {props.fieldToUpdate === "BREEAMCreditsValue" &&
                        <div>
                            <label className="text-center">Credit Value: </label>
                            <div className="form-control-8A">
                                <input type="text" placeholder="Enter Credit Value Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "BREEAMMinimumStandardsValue" &&
                        <div>
                            <label className="text-center">Minimum Standards Value: </label>
                            <div className="form-control-8A">
                                <input type="text" placeholder="Enter Minimum Standards Value Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "BREEAMCreditsTargetValue" &&
                        <div>
                            <label className="text-center">Minimum Standards Value: </label>
                            <div className="form-control-8A">
                                <input type="text" placeholder="Enter Target Value Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "BREEAMCreditsPotentialValue" &&
                        <div>
                            <label className="text-center">Minimum Standards Value: </label>
                            <div className="form-control-8A">
                                <input type="text" placeholder="Enter Potential Value Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "consultancyFee" &&
                            <div>
                                <label className="text-center">Consultancy Fee: </label>
                            <div className="form-control-8A">
                                <input type="number" step="0.0001" placeholder="Enter Consultancy Fee Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }


                        {props.fieldToUpdate === "estimatedHours" &&
                            <div>
                                <label className="text-center">Estimated Hours: </label>
                            <div className="form-control-8A">
                                <input type="number" step="0.0001" placeholder="Enter Esimated Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "cost" &&

                            <div>
                                <label className="text-center">Cost: </label>
                            <div className="form-control-8A">
                                <input type="number" step="0.0001" placeholder="Enter Cost Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                            </div>

                        }

                        {props.fieldToUpdate === "costCategory" &&

                            <div className="form-control-8A">
                                <label>Cost Category</label>
                                <select value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Certifications Fees">Certifications Fees</option>
                                <option value="Shipping and Couriers">Shipping and Couriers</option>
                                <option value="Data Search">Data Search</option>
                                {/* <option value="Entertaining">Entertaining</option> */}
                                <option value="Labs and Testing">Labs and Testing</option>
                                {/* <option value="Reprographics">Reprographics</option> */}
                                {/* <option value="Sundry">Sundry</option> */}
                                <option value="Travel and Accomodation">Travel</option>
                                <option value="Other">Other</option>
                                <option value="Freelance Environmental Consultants">Freelance Environmental Consultants</option>
                                </select>
                            </div>

                        }

                        {props.fieldToUpdate === "activityComplete" &&

                            <div>
                                <label className="text-center">Activity Complete: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </select>
                            </div>

                        }

                        {props.fieldToUpdate === "invoiceDate" &&
                            <div>
                                <label className="text-center">Invoice Date: </label><input type="date" placeholder="Invoice Date" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                {multipleInvoicesToUpdate.length>0 && (
                                    <div className="pt-2" >
                                        <p>{calculatedText}</p>
                                        <div className="h-[220px]">
                                            <div className="overflow-auto h-[200px]">
                                                <table className="objects-8A min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-jungle-mist-100">
                                                        <tr>
                                                            <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Inv#</th>
                                                            <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Current Inv Date</th>
                                                            <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Proposed Inv Date</th>
                                                            <th scope="col" className="p-2 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Accept<input className="ml-2" type="checkbox" name="allselect" onChange={handleChange} /></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {multipleInvoicesToUpdate.map((invoice) => (
                                                            <tr key={invoice.id}>
                                                                <td className="pl-2">{invoice.invoiceNumber}</td>
                                                                <td className="pl-2">{invoice.invoiceDateToDisplay}</td>
                                                                <td className="pl-2"><input type="date" name={invoice.id} value={invoice.newInvoiceDate} onChange={handleDateChange}/></td>
                                                                <td className="pl-5"><input type="checkbox" name={invoice.id} checked={invoice?.isChecked||false} onChange={handleChange}/>{invoice?.isChecked ? " YES" : ""}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }

                        {props.fieldToUpdate === "cloneInvoice" &&
                            <div className="form-control-8A">
                                <label className="text-center">Please choose an Invoice Date for the clone: </label><input type="date" placeholder="Invoice Date" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        }

                        {props.fieldToUpdate === "reductionProjection" &&
                        <div>
                            <label className="text-center">Annual Reduction Forecast %: </label>
                            <div className="form-control-8A">
                                <input type="number" step="0.0001" placeholder="Enter Reduction Forecast % Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "reductionProjectionYear" &&
                        <div>
                            <label className="text-center">Reduction Forecast Year: </label>
                            <div className="form-control-8A">
                                <input type="number" step="0.0001" placeholder="Enter Reduction Forecast Year Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "reductionMeasures" &&
                        <div>
                            <label className="text-center">Reduction Measures: </label>
                            <div className="form-control-8A">
                                <input type="text" placeholder="Enter Reduction Measures Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "reductionControlCategory" &&
                        <div>
                            <label className="text-center">Reduction Control Category: </label>
                            <div className="form-control-8A">
                            <select value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="Full Control">Full Control</option>
                                        <option value="Partial Control">Partial Control</option>
                                        <option value="No Control">No Control</option>
                                        <option value="N/A">N/A</option>
                                    </select>
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "dataType" &&
                        <div>
                            <label className="text-center">Data Type: </label>
                            <div className="form-control-8A">
                                <select value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    <option value="">Please Select...</option>
                                    <option value="Primary Data">Primary Data</option>
                                    <option value="Secondary Data">Secondary Data</option>
                                    <option value="Financial Data">Financial Data</option>
                                </select>
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "basicUncertainty" &&
                        <div>
                            <label className="text-center">Basic Uncertainty: </label>
                            <div className="form-control-8A">
                                <select value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {basicUncertaintyFactors.map((item) => (
                                        <option key={item.activityOrEmissionCategory} value={item.activityOrEmissionCategory}>{item.activityOrEmissionCategory}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "organisationFootprintItemDescription" &&
                        <div>
                            <label className="text-center">Footprint Item Description: </label>
                            <div className="form-control-8A">
                                <input type="text" placeholder="Enter Footprint Item Description Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "dataReviewConsultant" &&
                        <div>
                            <label className="text-center">Data Review Consultant: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {users.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).map((user) => (
                                        <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                    ))}
                                </select>
                        </div>

                        }

                        {props.fieldToUpdate === "dataReviewProgress" &&
                        <div>
                            <label className="text-center">Data Review Progress: </label>
                            <div className="form-control-8A">
                                <select value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    <option value="">Please Select...</option>
                                    <option value="Excluded">Excluded</option>
                                    <option value="Open">Open</option>
                                    <option value="In-progress">In-progress</option>
                                    <option value="Pending Action">Pending Action</option>
                                    <option value="Completed">Completed </option>
                                </select>
                            </div>
                            <div className='pt-2 pb-10'>
                                <label>Would you like to update the Data Review Progress for all filtered items?</label>
                                <div className='pl-2 inline'>
                                    <input type="checkbox" id="cascadeDataReviewProgress" name="cascadeDataReviewProgress" defaultChecked={false} onChange={updateConsultantOrQAForAllItems} />
                                </div>
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "calculationConsultant" &&
                        <div>
                            <label className="text-center">Calculation Consultant: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {users.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).map((user) => (
                                        <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                    ))}
                                </select>

                                <div className='pt-2 pb-10'>
                                <label>Would you like to update the Calculation Consultant for all filtered items?</label>
                                <div className='pl-2 inline'>
                                    <input type="checkbox" id="cascadeCalculationConsultant" name="cascadeCalculationConsultant" defaultChecked={false} onChange={updateConsultantOrQAForAllItems} />
                                </div>
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "calculationProgress" &&
                        <div>
                            <label className="text-center">Calculation Progress: </label>
                            <div className="form-control-8A">
                                <select value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    <option value="">Please Select...</option>
                                    <option value="Excluded">Excluded</option>
                                    <option value="Open">Open</option>
                                    <option value="In-progress">In-progress</option>
                                    <option value="Pending Action">Pending Action</option>
                                    <option value="Completed">Completed </option>
                                </select>
                            </div>
                            <div className='pt-2 pb-10'>
                                <label>Would you like to update the Calculation Progress for all filtered items?</label>
                                <div className='pl-2 inline'>
                                    <input type="checkbox" id="cascadeCalculationProgress" name="cascadeCalculationProgress" defaultChecked={false} onChange={updateConsultantOrQAForAllItems} />
                                </div>
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "QAConsultant" &&
                        <div>
                            <label className="text-center">QA Consultant: </label>
                                <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    {users.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).map((user) => (
                                        <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                    ))}
                                </select>

                                <div className='pt-2 pb-10'>
                                <label>Would you like to update the QA Consultant for all filtered items?</label>
                                <div className='pl-2 inline'>
                                    <input type="checkbox" id="cascadeQAConsultant" name="cascadeQAConsultant" defaultChecked={false} onChange={updateConsultantOrQAForAllItems} />
                                </div>
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "QAProgress" &&
                        <div>
                            <label className="text-center">QA Progress: </label>
                            <div className="form-control-8A">
                                <select value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                    <option value="">Please Select...</option>
                                    <option value="Excluded">Excluded</option>
                                    <option value="Open">Open</option>
                                    <option value="In-progress">In-progress</option>
                                    <option value="Pending Action">Pending Action</option>
                                    <option value="Completed">Completed </option>
                                </select>

                            </div>
                            <div className='pt-2 pb-10'>
                                <label>Would you like to update the QA Progress for all filtered items?</label>
                                <div className='pl-2 inline'>
                                    <input type="checkbox" id="cascadeQAProgress" name="cascadeQAProgress" defaultChecked={false} onChange={updateConsultantOrQAForAllItems} />
                                </div>
                            </div>
                        </div>

                        }

                        {props.fieldToUpdate === "dataQualityTechnology" &&
                        <div>
                            <label className="text-center">Data Quality - Technology: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "dataQualityTime" &&
                        <div>
                            <label className="text-center">Data Quality - Time: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "dataQualityTime").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "dataQualityGeography" &&
                        <div>
                            <label className="text-center">Data Quality - Geography: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "dataQualityGeography").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "dataQualityCompleteness" &&
                        <div>
                            <label className="text-center">Data Quality - Completeness: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "dataQualityReliability" &&
                        <div>
                            <label className="text-center">Data Quality - Reliability: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "dataQualityReliability").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "emissionsFactorTechnology" &&
                        <div>
                            <label className="text-center">Emissions Factor - Technology: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "emissionsFactorTime" &&
                        <div>
                            <label className="text-center">Emissions Factor - Time: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "emissionsFactorGeography" &&
                        <div>
                            <label className="text-center">Emissions Factor - Geography: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "emissionsFactorCompleteness" &&
                        <div>
                            <label className="text-center">Emissions Factor - Completeness: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "emissionsFactorReliability" &&
                        <div>
                            <label className="text-center">Emissions Factor - Reliability: </label>

                            <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                {uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").map((item) => (
                                    <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                ))}
                            </select>

                        </div>

                        }

                        {props.fieldToUpdate === "timesheetNotes" &&
                            <div className="form-control-8A w-full p-2 h-24">
                                <div className="form-control-8A">
                                    <input type="Text" placeholder="Enter Notes" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }
                        {props.fieldToUpdate === "timesheetMonday" &&
                            <div className="form-control-8A w-full p-2 h-24">
                                <div className="form-control-8A">
                                    <input type="number" step="0.0001" placeholder="Enter Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    {/* <p>UserID: {timesheetsToUpdate.userId}</p> */}
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "timesheetTuesday" &&
                            <div className="form-control-8A w-full p-2 h-24">
                                <div className="form-control-8A">
                                    <input type="number" step="0.0001" placeholder="Enter Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    {/* <p>UserID: {timesheetsToUpdate.userId}</p> */}
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "timesheetWednesday" &&
                            <div className="form-control-8A w-full p-2 h-24">
                                <div className="form-control-8A">
                                    <input type="number" step="0.0001" placeholder="Enter Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    {/* <p>UserID: {timesheetsToUpdate.userId}</p> */}
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "timesheetThursday" &&
                            <div className="form-control-8A w-full p-2 h-24">
                                <div className="form-control-8A">
                                    <input type="number" step="0.0001" placeholder="Enter Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    {/* <p>UserID: {timesheetsToUpdate.userId}</p> */}
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "timesheetFriday" &&
                            <div className="form-control-8A w-full p-2 h-24">
                                <div className="form-control-8A">
                                    <input type="number" step="0.0001" placeholder="Enter Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    {/* <p>UserID: {timesheetsToUpdate.userId}</p> */}
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "timesheetSaturday" &&
                            <div className="form-control-8A w-full p-2 h-24">
                                <div className="form-control-8A">
                                    <input type="number" step="0.0001" placeholder="Enter Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    {/* <p>UserID: {timesheetsToUpdate.userId}</p> */}
                                    <input type="number" step="0.0001" placeholder="Enter Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    {/* <p>UserID: {timesheetsToUpdate.userId}</p> */}
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "timesheetSunday" &&
                            <div className="form-control-8A w-full p-2 h-24">
                                <div className="form-control-8A">
                                    <input type="number" step="0.0001" placeholder="Enter Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    {/* <p>UserID: {timesheetsToUpdate.userId}</p> */}
                                </div>
                            </div>
                        }

{props.fieldToUpdate === "goalNotes" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Notes: </label>
                                    <textarea className="w-full" rows="4" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "goalDetail" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Details: </label>
                                    <textarea className="w-full" rows="4" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "goalMonth1" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Month 1: </label>
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType === "Invoice Target" &&
                                        <input type='number' className="w-full" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType !== "Invoice Target" &&
                                        <textarea className="w-full" rows="4" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "goalMonth2" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Month 2: </label>
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType === "Invoice Target" &&
                                        <input type='number' className="w-full" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType !== "Invoice Target" &&
                                        <textarea className="w-full" rows="4" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                </div>
                            </div>
                        }
                        
                        {props.fieldToUpdate === "goalMonth3" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Month 3: </label>
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType === "Invoice Target" &&
                                        <input type='number' className="w-full" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType !== "Invoice Target" &&
                                        <textarea className="w-full" rows="4" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "goalMonth4" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Month 4: </label>
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType === "Invoice Target" &&
                                        <input type='number' className="w-full" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType !== "Invoice Target" &&
                                        <textarea className="w-full" rows="4" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "goalMonth5" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Month 5: </label>
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType === "Invoice Target" &&
                                        <input type='number' className="w-full" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType !== "Invoice Target" &&
                                        <textarea className="w-full" rows="4" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "goalMonth6" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Month 6: </label>
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType === "Invoice Target" &&
                                        <input type='number' className="w-full" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                    {props.arrayToShow.find(item => item.id === props.objectToUpdate).goalType !== "Invoice Target" &&
                                        <textarea className="w-full" rows="4" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    }
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "goalSummary" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Summary: </label>
                                    <textarea className="w-full" rows="4" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "goalStatus" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Status: </label>
                                    <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                        <option value="Complete">Complete</option>
                                        <option value="Live">Live</option>
                                        <option value="Postponed">Postponed</option>                                        
                                    </select>
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "startDate" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Start Date: </label>
                                    <input type="Date" placeholder="Enter a Date" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "goalType" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Type: </label>
                                    <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                        <option value="General Sustainability Knowledge">General Sustainability Knowledge</option>
                                        <option value="Invoice Target">Invoice Target</option>
                                        <option value="Non-technical Team Goal">Non-technical Team Goal</option>
                                        <option value="Soft Skills">Soft Skills</option>
                                        <option value="Training">Training</option>
                                    </select>
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "techSkillStartDate" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Start Date: </label>
                                    <input type="Date" placeholder="Enter a Date" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "techSkillAttainment" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Attainment: </label>
                                    <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                        <option value="Level 1 - Knowledge and Understanding">Level 1 - Knowledge and Understanding</option>
                                        <option value="Level 2 - Application of Knowledge and Understanding">Level 2 - Application of Knowledge and Understanding</option>
                                        <option value="Level 3 - Reasoned Advice and Depth of Technical Knowledge">Level 3 - Reasoned Advice and Depth of Technical Knowledge</option>
                                    </select>
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "techSkillGoalPerService" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Goal Per Service: </label>
                                        <input type="number" className="w-full" placeholder="Enter Data" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "rateEURGBP" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">Rate EUR/GBP: </label>
                                        <input type="number" step={0.01} className="w-full" placeholder="Enter Rate" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "footprintItemId" &&
                            <div>
                                <div className="form-control-8A">
                                    <label className="text-center">Select what data you would like to import: </label>
                                    <select className="form-control-8A w-2/3" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)}>
                                        <option value="po">Purchase Orders</option>
                                        <option value="invoice" disabled>Invoices</option>
                                    </select>
                                </div>
                                <div className="form-control-8A">
                                    <p className="">From Date</p>
                                    <input className="border-2 p-2 w-full" type="date" placeholder="Enter From Date" value={fromDate} onChange={(e) => setFromDate(e.target.value)}/>
                                    <p className="">To Date</p>
                                    <input className="border-2 p-2 w-full" type="date" placeholder="Enter To Date" value={toDate} onChange={(e) => setToDate(e.target.value)}/>
                                </div>
                            </div>
                        }

                        {props.fieldToUpdate === "siteElectricityReadingValue" &&
                            <div className="">
                                <div className="pb-5">
                                    <label className="text-center">kWh Reading: </label>
                                        <input type="number" step={0.01} className="w-full" placeholder="Enter Rate" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                </div>
                            </div>
                        }

                        {(loading || usersLoading || todaysDate === "") &&
                        
                        <input disabled={true} type="submit" value="Please Wait..." className="cursor-pointer inline-block rounded-2xl w-full text-white bg-bondi-blue" />

                        }

                        {props.fieldToUpdate === "footprintItemId" && !loading && !usersLoading && todaysDate !== "" &&
                            <input type="submit" value="Import" className="cursor-pointer inline-block rounded-2xl w-full text-white bg-bondi-blue" />
                        }
                        {props.fieldToUpdate !== "footprintItemId" && !loading && !usersLoading && todaysDate !== "" &&
                            <input type="submit" value="Save" className="cursor-pointer inline-block rounded-2xl w-full text-white bg-bondi-blue" />
                        }
                        
                        <button className=" bg-congress-blue inline-block rounded-2xl w-full text-white mt-2 bottom-0" onClick={props.onClose}>Cancel</button>

                        {props.fieldToUpdate === "associateExistingFiles" && typeof valueToUpdate === "object" &&

                            <div className="pt-3 pb-3">
                            <MultiSelect
                            options={props.selectionOptions.map((item) => {
                                return {
                                    label: item.name.slice(25, item.name.length),
                                    value: item.name.slice(25, item.name.length)
                                }
                            })}
                            value={valueToUpdate}
                            onChange={setValueToUpdate}
                            labelledBy="Select"
                            />
                            </div>

                        }

                    </form>

                    {/* <button className="bg-black inline-block rounded-2xl w-full text-white" onClick={props.onClose}>Cancel</button> */}

                </div>

            </div>
    
        </div>
    )
}

export default ModalUpdateValue